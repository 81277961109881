import React from 'react';
import "../../Pages/Dashboard/dashboard.css";
import DashboardCards from './DashboardCards';
import DashboardGraph from './DashboardGraph';
import DashboardClientStatus from './DashboardClientStatus';
import DashboardApplicantsStatus from './DashboardApplicantStatus';

const DashboardOverview = () => {
  return (
    <>
      <div className="admin-dashboard-section">
        <div>
          <DashboardCards />
          <DashboardGraph />
          <DashboardApplicantsStatus/>
          <DashboardClientStatus />
        </div>
      </div>
    </>
  );
};

export default DashboardOverview;