import React, { useRef, useState } from 'react';
import { Input, Upload, Button, Spin, Modal, Form, Collapse } from 'antd';
import Instance from "../../../Instance";
import { handleFileUpload, createUniqueS3FilenameWithDate, showErrorMessage } from '../../../globalConstant';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FaTrash } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { showSuccessMessage } from '../../../globalConstant';
import SidebarSeoUser from '../../Layout/Sidebar/Index';
import HeaderSeoUser from '../../Layout/Header/Index';
import Loader from '../../../Loader';

const { Panel } = Collapse;



const AddPost = () => {
  const quillRef = useRef();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [bGImageURL, setBGImageURL] = useState(null);
  const [seoKeywords, setSeoKeywords] = useState([]);
  const [postDescription, setPostDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [faqFields, setFaqFields] = useState([]);
  const pattern = /^(?!.*--)[a-z0-9]+(-[a-z0-9]+)*$/;
  const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));
  const handleFormDataChange = (event, fieldName) => {
    try {
      if (event.target.value < 0) return;
      let newData = { ...formData };
      newData[fieldName] = event.target.value;
      setFormData(newData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddSeoKeyword = () => {
    let newData = [...seoKeywords];
    newData.push("");
    setSeoKeywords(newData);
  };

  const seoKeywordsChange = (event, index) => {
    const updatedData = [...seoKeywords];
    updatedData[index] = event.target.value;
    setSeoKeywords(updatedData);
  };

  const handlePostImageSelect = (e) => {
    setBGImageURL(e.fileList[0]);
  };

  const handlePostContentChange = (content) => {
    setPostDescription(content);
  };

  const isFormValid = () => {
    if (!formData?.title || formData?.title?.trim() === '') {
      showErrorMessage('Post title required');
      return false;
    }
    if (!formData?.postUrl || formData?.postDescription?.trim() === '') {
      showErrorMessage('Post url required');
      return false;
    }

    if (!pattern.test(formData?.postUrl)) {
      showErrorMessage('Please enter valid post url');
      return false;
    }

    if (!formData?.seoTitle || formData?.seoTitle?.trim() === '') {
      showErrorMessage('SEO Title required');
      return false;
    }
    if (!formData?.seoDesc || formData?.seoDesc?.trim() === '') {
      showErrorMessage('SEO Description required');
      return false;
    }

    if (!seoKeywords || seoKeywords?.length === 0) {
      showErrorMessage('SEO Keywords required');
      return false;
    } else if (seoKeywords.length > 0) {
      const hasError = seoKeywords.some((item, index) => {
        if (!item || item?.trim() === '') {
          showErrorMessage(`SeoKeywords is required in ${index + 1} SeoKeywords input tag`);
          return true;
        }
        return false;
      });

      if (hasError) {
        return false;
      }
    }
    if (!postDescription || postDescription?.trim() === '') {
      showErrorMessage('Post description required');
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    try {
      if (!isFormValid()) return;
      setIsLoading(true);
      let fileName = (bGImageURL) ? createUniqueS3FilenameWithDate(bGImageURL?.name) : null;
      if (fileName) {
        let res = await handleFileUpload(bGImageURL?.originFileObj, fileName, bGImageURL?.type);
        if (!res) {
          showErrorMessage('Failed to upload bGImage');
          setIsLoading(false);
          return;
        }
      }

      let data = { ...formData, seoKeywords, postDescription };
      data.FAQs = faqFields
      data["bGImageURL"] = fileName;

      const response = await Instance.post('/addPost', data, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        },
      });

      if (response.status === 201) {
        showSuccessMessage('Post Created Successfully!');
        setFormData({});
        setBGImageURL(null);
        setSeoKeywords([]);
        setPostDescription('');
        setFaqFields([]);
      }
    } catch (error) {
      showErrorMessage(error?.response?.data?.message || 'An error occurred');
      console.error(error)
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddFaq = () => {
    setIsModalVisible(true);
  };

  const handleFaqChange = (index, event) => {
    const updatedFaqs = [...faqFields];
    updatedFaqs[index][event.target.name] = event.target.value;
    setFaqFields(updatedFaqs);
  };

  const handleAddFaqField = () => {
    setFaqFields([...faqFields, { question: '', answer: '' }]);
  };

  const handleRemoveFaqField = (index) => {
    const updatedFaqs = [...faqFields];
    updatedFaqs.splice(index, 1);
    setFaqFields(updatedFaqs);
  };

  const handleFaqOk = () => {
    try {
      for (let i = 0; i < faqFields.length; i++) {
        if (!faqFields[i].question) {
          showErrorMessage(`Question is required at index ${i + 1}`);
          return;
        }
        if (!faqFields[i].answer) {
          showErrorMessage(`Answer is required at index ${i + 1}`);
          return;
        }
      }
      setIsModalVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFaqCancel = () => {
    try {
      for (let i = 0; i < faqFields.length; i++) {
        if (!faqFields[i].question) {
          showErrorMessage(`Question is required at index ${i + 1}`);
          return;
        }
        if (!faqFields[i].answer) {
          showErrorMessage(`Answer is required at index ${i + 1}`);
          return;
        }
      }
      setIsModalVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  const [isCollapsed, setIsCollapsed] = useState(false);
    const handleCollapseToggle = (collapsedState) => {
      setIsCollapsed(collapsedState);
    };
    return (
        <>
        {isLoading && <Loader />}
            <SidebarSeoUser onCollapseToggle={handleCollapseToggle} />
            <HeaderSeoUser onCollapseToggle={handleCollapseToggle} isCollapsed={isCollapsed}/>
            <div className={`main-wrapper ${isCollapsed ? "collapsed" : ""}`}>
        <div className="add-post-timeline">
          <div className="title-timeline-add-post">
            <h3>Add Post</h3>
          </div>
        </div>

        <div className="steps-content">
          <div>
            <div className="row">
              <div className="col-lg-6">
                <Input
                  placeholder="Title *"
                  className="form-control mb-3"
                  value={formData?.title}
                  name='title'
                  onChange={(e) => handleFormDataChange(e, 'title')}
                  suffix={<span>eg. Healthcare</span>}
                />
                <div className="mb-3" style={{ width: "100%" }}>
                  <Upload
                    onChange={handlePostImageSelect}
                    beforeUpload={() => false}
                    fileList={bGImageURL ? [bGImageURL] : []}
                  >
                    <button className="create-btn" style={{ width: "fit-content" }} icon={<UploadOutlined />}>
                      Upload Image
                    </button>
                  </Upload>
                </div>
                <div className='post-url-input'>
                  <h5>www.bilkins.com/</h5>
                  <input
                    type="text"
                    placeholder='Post Url*'
                    value={formData?.postUrl || ""}
                    onChange={(e) => handleFormDataChange(e, 'postUrl')}
                  />
                </div>

                <Input
                  placeholder="SEO Title *"
                  className="form-control mb-3"
                  maxLength={60}
                  value={formData?.seoTitle}
                  name='seoTitle'
                  onChange={(e) => handleFormDataChange(e, 'seoTitle')}
                  suffix={<span>{formData?.seoTitle?.length} / 60</span>}
                />
                <Input
                  placeholder="SEO Description *"
                  className="form-control mb-3"
                  maxLength={160}
                  value={formData?.seoDesc}
                  name='seoDesc'
                  onChange={(e) => handleFormDataChange(e, 'seoDesc')}
                  suffix={<span>{formData?.seoDesc?.length} / 160</span>}
                />

                <div className="d-flex gap-2 mt-3 keywords-seo">
                  {seoKeywords.map((data, index) => (
                    <div key={index} className='d-flex align-items-center me-3 mb-2'>
                      <Input
                        className='add-benefit-input me-2'
                        type="text"
                        value={data}
                        onChange={(event) => seoKeywordsChange(event, index)}
                        placeholder="Add Seo Keyword"
                      />
                      <FaTrash style={{ color: "orangered" }} onClick={() => {
                        let newData = [...seoKeywords];
                        newData = newData.filter((item, idx) => idx !== index);
                        setSeoKeywords(newData);
                      }} />
                    </div>
                  ))}

                  <button className="create-btn d-flex gap-2 align-items-center" style={{ width: "fit-content" }} onClick={handleAddSeoKeyword}>
                    Add Seo Keyword * <PlusOutlined style={{ color: "#ce1b28" }} />
                  </button>
                </div>
              </div>

              <div className="col-lg-6">
                <div className='description-layout'>
                  <div className='description-title'>
                    <span>Description *</span>
                  </div>
                  <ReactQuill
                    ref={quillRef}
                    value={postDescription}
                    onChange={handlePostContentChange}
                    placeholder="Type..."
                    style={{ height: '15rem', width: "100%", borderBottom: "none" }}
                    modules={{
                      toolbar: {
                        container: [
                          [{ 'header': [1, 2, 3, 4, false] }],
                          [{ 'font': [] }],
                          [{ size: [] }],
                          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                          [{ 'list': 'ordered' }, { 'list': 'bullet' },
                          { 'indent': '-1' }, { 'indent': '+1' }],
                          ['link'],
                          ['color', 'background'],
                          ['clean']
                        ],
                      },
                      clipboard: {
                        matchVisual: false,
                      },
                    }}
                  />
                </div>

                <div className="add--faqs mt-3">
                  <button className="create-btn d-flex gap-2 align-items-center" style={{ width: "fit-content" }} onClick={handleAddFaq}>
                    Add FAQ
                  </button>
                </div>

                <Collapse className="mt-3">
                  {faqFields.map((faq, index) => (
                    <Panel header={faq.question} key={index}>
                      <p>{faq.answer}</p>
                    </Panel>
                  ))}
                </Collapse>
              </div>
            </div>

            <div className="next-button d-flex">
              <button className='create-btn' style={{ width: "fit-content" }} onClick={handleSave} >Save</button>
              <button className='cancel-btn' style={{ width: "fit-content" }} onClick={() => { navigate('/seo-user/posts') }} >Cancel</button>
            </div>
          </div>
        </div>

        <Modal
          title="Add FAQ"
          open={isModalVisible}
          onOk={handleFaqOk}
          onCancel={handleFaqCancel}
        >
          {faqFields.map((field, index) => (
            <div key={index}>
              <Form.Item label="Question">
                <Input
                  name="question"
                  value={field.question}
                  onChange={(e) => handleFaqChange(index, e)}
                />
              </Form.Item>
              <Form.Item label="Answer">
                <Input
                  name="answer"
                  value={field.answer}
                  onChange={(e) => handleFaqChange(index, e)}
                />
              </Form.Item>
              <button type="dashed" style={{ width: "fit-content" }} className='cancel-btn' onClick={() => handleRemoveFaqField(index)}>
                Remove
              </button>
              <hr />
            </div>
          ))}
          <button type="dashed" className='create-btn' style={{ width: "fit-content" }} onClick={handleAddFaqField}>
            Add FAQ Field
          </button>
        </Modal>
      </div>
    </>
  );
};

export default AddPost;
