import React, { useEffect, useState } from "react";
import { Form, Input, Radio, DatePicker } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import Instance from "../../../Instance";
import Loader from "../../../Loader";
import dayjs from "dayjs";
import { BUCKET_URL } from "../../../globalConstant";

export const ViewApplicants = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [licenses, setLicenses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [experiences, setExperiences] = useState([]);
  const [basicInfoData, setBasicInfoData] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [prevEmployment, setPrevEmployment] = useState([]);
  const [educationDetails, setEducationDetails] = useState([]);
  const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));

  const fetchAplicationDetails = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get(`/getApplication/${id}`, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        },
      });

      const applicationData = response?.data.application;
      setEducationDetails(applicationData?.educations || []);
      setCertifications(applicationData?.certifications || []);
      setLicenses(applicationData?.licences || []);
      setPrevEmployment(applicationData?.prevEmployements || []);
      setExperiences(applicationData?.experiences || []);
      setBasicInfoData(applicationData?.basicInfo || []);
      form.setFieldsValue({
        drugScreen: applicationData?.drugScreen ? "yes" : "no",
        backgroundCheck: applicationData?.criminalBackgroundCheck ? "yes" : "no",
        limitations: applicationData?.actionLimitaions ? "yes" : "no",
        explanation: applicationData?.limitationDescription || "",
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAplicationDetails();
  },[]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleButtonDownload = () => {
    const resumeUrl = `${BUCKET_URL}${basicInfoData?.resume}`;
    window.open(resumeUrl, "_blank");
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className="view-applicants-form">
        <Form
          form={form}
          name="personalDetails"
          layout="vertical"

        >
          <h4>Personal Details</h4>
          <div className="row mt-4">
            <div className="col-lg-6">
              <Form.Item
                label="Legal First Name"
                rules={[
                  { required: true, message: "Please input your first name!" },
                ]}
              >
                <Input disabled={true} value={basicInfoData?.firstName} />
              </Form.Item>
            </div>

            <div className="col-lg-6">
              <Form.Item label="Legal Middle Name">
                <Input disabled={true} value={basicInfoData?.middleName} />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <Form.Item label="Legal Last Name">
                <Input disabled={true} value={basicInfoData?.lastName} />
              </Form.Item>
            </div>

            {/* Date of Birth */}
            <div className="col-lg-6">
              <Form.Item label="Date of Birth">
                <DatePicker
                  style={{ width: "100%" }}
                  value={dayjs(basicInfoData?.dateOfBirth)}
                  disabled={true}
                />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            {/* Category */}
            <div className="col-lg-6">
              <Form.Item label="Category">
                <Input disabled={true} value={basicInfoData?.category} />
              </Form.Item>
            </div>

            {/* Specialty */}
            <div className="col-lg-6">
              <Form.Item label="Speciality">
                <Input disabled={true} value={basicInfoData?.speciality} />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            {/* Select Certification */}
            <div className="col-lg-6">
              <Form.Item label="Select Certification">
                <Input disabled={true} value={basicInfoData?.certifications} />
              </Form.Item>
            </div>

            {/* State Licenses */}
            <div className="col-lg-6">
              <Form.Item label="State Licenses">
                <Input disabled={true} value={basicInfoData?.stateLicenses} />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            {/* Mailing Address */}
            <div className="col-lg-6">
              <Form.Item label="Mailing Address">
                <Input disabled={true} value={basicInfoData?.mailingAddress} />
              </Form.Item>
            </div>

            {/* City */}
            <div className="col-lg-6">
              <Form.Item label="City">
                <Input disabled={true} value={basicInfoData?.city} />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            {/* State */}
            <div className="col-lg-6">
              <Form.Item label="State">
                <Input disabled={true} value={basicInfoData?.state} />
              </Form.Item>
            </div>

            {/* ZIP */}
            <div className="col-lg-6">
              <Form.Item label="ZIP">
                <Input disabled={true} value={basicInfoData?.zipCode} />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            {/* Mobile Phone */}
            <div className="col-lg-6">
              <Form.Item label="Mobile Phone">
                <Input disabled={true} value={basicInfoData?.mobileNumber} />
              </Form.Item>
            </div>

            {/* Alternative Mobile Phone */}
            <div className="col-lg-6">
              <Form.Item label="Alternative Mobile Phone">
                <Input
                  disabled={true}
                  value={basicInfoData?.alternateMobileNumber}
                />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            {/* Best Time to Call */}
            <div className="col-lg-6">
              <Form.Item label="Best time to Call">
                <Input disabled={true} value={basicInfoData?.bestTimeToCall} />
              </Form.Item>
            </div>

            {/* Email ID */}
            <div className="col-lg-6">
              <Form.Item label="Email ID">
                <Input disabled={true} value={basicInfoData?.email} />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            {/* Referred By */}
            <div className="col-lg-6">
              <Form.Item label="Referred By">
                <Input disabled={true} value={basicInfoData?.referredBy} />
              </Form.Item>
            </div>

            {/* Upload Resume */}
            <div className="col-lg-3">
              <Form.Item label="Upload Resume">
                <Input disabled={true} value={basicInfoData?.resume} />
              </Form.Item>
            </div>
            <div className="col-lg-3">
              <Form.Item label="Dowload Resume">
                <button type="primary" htmlType="submit" className="continue-btn" value={basicInfoData?.resume} onClick={handleButtonDownload} >click here </button>
              </Form.Item>
            </div>
          </div>
          {educationDetails.length > 0 && <h4>Education Details</h4>}
          {educationDetails.map((education, index) => (
            <div className="education-entry" key={index}>
              <h5 style={{ color: "var(--maroon-color)" }}>
                Education {index + 1}
              </h5>

              <div className="row">
                <div className="col-lg-6">
                  <Form.Item label="School Name">
                    <Input value={education?.schoolName} disabled={true} />
                  </Form.Item>
                </div>
                <div className="col-lg-6">
                  <Form.Item label="School Type">
                    <Input value={education?.schoolType} disabled={true} />
                  </Form.Item>
                </div>
                <div className="col-lg-6">
                  <Form.Item label="City">
                    <Input value={education?.schoolCity || ""} disabled={true} />
                  </Form.Item>
                </div>
                <div className="col-lg-6">
                  <Form.Item label="State">
                    <Input value={education?.schoolState || ""} disabled={true} />
                  </Form.Item>
                </div>
                <div className="col-lg-6">
                  <Form.Item label="Degree/Diploma">
                    <Input
                      value={education?.degreeDiploma || ""}
                      disabled={true}
                    />
                  </Form.Item>
                </div>
                <div className="col-lg-6">
                  <Form.Item label="Date of Graduation">
                    <DatePicker
                      value={dayjs(education?.graduateDate) || null}
                      format="DD-MM-YYYY"
                      style={{ width: "100%" }}
                      disabled={true}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          ))}
          {certifications.length > 0 && <h4>Certifications</h4>}
          {certifications.map((certification, index) => (
            <div className="certification-entry" key={index}>
              <h5 style={{ color: "var(--maroon-color)" }}>
                Certificate {index + 1}
              </h5>
              <div className="row">
                <div className="col-lg-6">
                  <Form.Item label="Certificate URL">
                    <Input
                      value={certification.certificateURL || ""}
                      disabled={true}
                    />
                  </Form.Item>
                </div>
                <div className="col-lg-6">
                  <Form.Item label="Expiration Date">
                    <DatePicker
                      value={dayjs(certification?.expirationDate) || null}
                      format="DD-MM-YYYY"
                      style={{ width: "100%" }}
                      disabled={true}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          ))}
          {licenses.length > 0 && <h4>Licenses</h4>}
          {licenses.map((license, index) => (
            <div className="license-entry" key={index}>
              <h5 style={{ color: "var(--maroon-color)" }}>
                Licence {index + 1}
              </h5>
              <div className="row">
                <div className="col-lg-6">
                  <Form.Item label="State">
                    <Input value={license?.state} disabled={true} />
                  </Form.Item>
                </div>
                <div className="col-lg-6">
                  <Form.Item label="Issued Date">
                    <DatePicker
                      value={dayjs(license?.issuedDate) || null}
                      format="DD-MM-YYYY"
                      style={{ width: "100%" }}
                      disabled={true}
                    />
                  </Form.Item>
                </div>
                <div className="col-lg-6">
                  <Form.Item label="Expiration Date">
                    <DatePicker
                      value={dayjs(license?.expirationDate) || null}
                      format="DD-MM-YYYY"
                      style={{ width: "100%" }}
                      disabled={true}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          ))}

          {experiences.length > 0 && <h4>Pre Employment Screening</h4>}
          {experiences.map((experience, index) => (
            <div className="" key={index}>
              <h5 style={{ color: "var(--maroon-color)" }}>
                Experience {index + 1}
              </h5>
              <div className="row">
                <div className="col-lg-6">
                  <Form.Item label="Specialty">
                    <Input value={experience?.speciality || ""} />
                  </Form.Item>
                </div>
                <div className="col-lg-6">
                  <Form.Item label="Year of Experience">
                    <Input value={experience?.yearOFExperience || ""} />
                  </Form.Item>
                </div>
              </div>
            </div>
          ))}
          <div className="row">
            <div className="col-lg-6">
              <Form.Item
                label="Are you willing to submit to drug screen?*"
                name="drugScreen"
                rules={[{ required: true }]}
              >
                <Radio.Group>
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item
                label="Are you willing to submit to criminal background check?*"
                name="backgroundCheck"
                rules={[{ required: true }]}
              >
                <Radio.Group>
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <Form.Item
                label="Do you have any limitation that would restrict you from performing essential functions?"
                name="limitations"
                rules={[{ required: true }]}
              >
                <Radio.Group>
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <Form.Item
                label="If YES, Please provide a detailed explanation."
                name="explanation"
              >
                <Input.TextArea rows={4} />
              </Form.Item>
            </div>
          </div>

          {prevEmployment.length > 0 && <h4>Past Employment</h4>}
          {prevEmployment.map((prevEmploy, index) => (
            <div className="" key={index}>
              <h5 style={{ color: "var(--maroon-color)" }}>
                Past Employment {index + 1}
              </h5>
              <div className="row">
                <div className="col-lg-6">
                  <Form.Item label="Facility">
                    <Input value={prevEmploy?.facility || ""} />
                  </Form.Item>
                </div>
                <div className="col-lg-6">
                  <Form.Item label="Department">
                    <Input value={prevEmploy?.department || ""} />
                  </Form.Item>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <Form.Item label="Supervisor's Name">
                    <Input value={prevEmploy?.supervisiorName || ""} />
                  </Form.Item>
                </div>
                <div className="col-lg-6">
                  <Form.Item label="Start Date">
                    <DatePicker
                      style={{ width: "100%" }}
                      value={dayjs(prevEmploy?.startDate) || null}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <Form.Item label="End Date*">
                    <DatePicker
                      style={{ width: "100%" }}
                      value={dayjs(prevEmploy?.endDate) || null}
                    />
                  </Form.Item>
                </div>
                <div className="col-lg-6">
                  <Form.Item label="City*">
                    <Input value={prevEmploy?.city || ""} />
                  </Form.Item>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <Form.Item label="State*">
                    <Input value={prevEmploy?.state} />
                  </Form.Item>
                </div>
                <div className="col-lg-6">
                  <Form.Item label="Hours*">
                    <Input value={prevEmploy?.hours || ""} />
                  </Form.Item>
                </div>
              </div>
            </div>
          ))}
          <div className="row">
            <div className="col-lg-24">
              <Form.Item>
                <button type="primary" htmlType="submit" className="continue-btn" onClick={() => navigate('/admin/applicants')}>
                  Back
                </button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};
