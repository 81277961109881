import React,{useState} from 'react';
import SidebarRecruiterLead from '../../Layout/Sidebar/Index';
import HeaderRecruiterLead from '../../Layout/Header/Index';
import { Applicants } from '../../Components/Applicants/Applicants';
import "./applicants.css"
const RecruiterLeadApplicantsPage = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const handleCollapseToggle = (collapsedState) => {
      setIsCollapsed(collapsedState);
    };
  
    return (
        <>
            <SidebarRecruiterLead onCollapseToggle={handleCollapseToggle}/>
            <HeaderRecruiterLead onCollapseToggle={handleCollapseToggle} isCollapsed={isCollapsed}/>
            <div className={`main-wrapper ${isCollapsed ? "collapsed" : ""}`}>
                <Applicants />
            </div>
        </>
    )
}

export default RecruiterLeadApplicantsPage;