import React, { useEffect, useState, useMemo } from "react";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import { Input, Modal, Select, Form } from "antd";
import { TbArrowsSort } from "react-icons/tb";
import { CreateTeam } from "./CreateTeamPopup";
import { useSelector, useDispatch } from "react-redux";
import { TeamDetailCard } from "./TeamCard";
import Instance from "../../../Instance";
import { addTeams } from '../../features/teamSlice';
import Loader from "../../../Loader";
import { EditTeam } from "./EditTeamPopup";
import { showErrorMessage } from "../../../globalConstant";

export const AllTeams = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const teams = useSelector(state => state.team.teams);
  const [searchText, setSearchText] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [isAscendingSort, setIsAcendingSort] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filterParameters, setFilterParameters] = useState({});
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));

  useEffect(() => {
    setDataSource(teams);
  }, [teams]);

  const teamsData = useMemo(() => {
    if (searchText?.trim() === '') return dataSource;
    let filteredTeams = dataSource?.filter((item) => `${item?.taskName}${item?.departmentDivision}`.includes(searchText));
    return filteredTeams;
  }, [dataSource, searchText]);

  const fetchTeamsData = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get('/getAllTeams', {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`
        }
      });
      if (response.status === 200) {
        let dataSource = response?.data?.teams?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt));
        dispatch(addTeams(dataSource));
      }
    } catch (error) {
      console.error(error);
      showErrorMessage('Failed to fetch teams data');
    } finally {
      setIsLoading(false);
    }
  }

  const fetchMembers = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get(`/getAllMembers`, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`
        }
      });
      if (response.status === 200) {
        setMembers(response?.data?.members);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchMembers();
    fetchTeamsData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCreateTeamClick = () => {
    setIsModalVisible(true);
  };

  const handleEditClick = (team) => {
    setIsEditModalVisible(true);
    setSelectedTeam(team);
  };

  const onEditModalClose = () => {
    setIsEditModalVisible(false);
    setSelectedTeam(null);
  };

  const handleSorting = () => {
    try {
      let sortedData;
      if (isAscendingSort) {
        sortedData = [...dataSource]?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      } else {
        sortedData = [...dataSource]?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      }
      setDataSource(sortedData);
      setIsAcendingSort(!isAscendingSort);
    } catch (error) {
      console.error(error);
    }
  }

  const handleApplyFilters = () => {
    try {
      setIsLoading(true);
      let filteredTeams = teams;
      if (filterParameters?.teamLead) {
        filteredTeams = filteredTeams?.filter((item) => item?.teamLead?._id === filterParameters?.teamLead);
      }
      setDataSource(filteredTeams);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsFilterModalVisible(false);
    }
  }

  const handleModalClose = () => {
    form.resetFields();
    setFilterParameters({});
    setIsFilterModalVisible(false);
    setDataSource(teams);
  }

  const memberOptions = members?.map((item) => ({
    label: `${item?.firstName} ${item?.lastName}`,
    value: item?._id
  }));

  return (
    <>
      {isLoading && <Loader />}
      <div className="all-recruiter-section">
        <div>
          <div className="row">
            <div className="d-flex justify-content-between align-items-center align-content-center">
              <div className="d-flex gap-4 align-items-center">
                <h2 className="">All Teams</h2>
                <Input
                  placeholder="Search..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  className="search-input-table me-2"
                  prefix={<SearchOutlined />}
                />
              </div>
              <div className="search-table-container gap-2">
                <button onClick={handleSorting}>
                  <TbArrowsSort
                    style={{ fontWeight: "bold", fontSize: "16px" }}
                  />{" "}
                  <span>Sort</span>
                </button>
                <button onClick={() => setIsFilterModalVisible(true)}>
                  <FilterOutlined
                    style={{ fontWeight: "bold", fontSize: "16px" }}
                  />{" "}
                  <span>Filter</span>
                </button>
                <div>
                  <button
                    className="admin-dashboard-btn"
                    onClick={handleCreateTeamClick}
                  >
                    + Create Team{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            {teamsData?.map((team, index) => (
              <TeamDetailCard key={index} team={team} handleEditClick={handleEditClick} />
            ))}
          </div>
          <CreateTeam
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
          />
          <EditTeam
            isModalVisible={isEditModalVisible}
            onEditModalClose={onEditModalClose}
            team={selectedTeam}
          />
        </div>
      </div>
      <Modal
        title="Filter"
        open={isFilterModalVisible}
        onCancel={() => setIsFilterModalVisible(false)}
        className="filter-modal"
        footer={null}
      >
        <div className="filter-form mt-4">
          <Form layout="vertical " className="filter-form" form={form}>
            <div className="col-md-12">
              <Form.Item
                label="Team Lead"
                name={"teamLead"}
              >
                <Select placeholder="Select Lead" options={memberOptions} onChange={(e) => setFilterParameters({ ...filterParameters, teamLead: e })} />
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className="modal-actions d-flex justify-content-between">
          <button onClick={handleModalClose} className="cancel-btn" type="button">
            Clear All
          </button>
          <button className="create-btn" onClick={handleApplyFilters} type="button">
            Apply Filters
          </button>
        </div>
      </Modal>
    </>
  );
};
