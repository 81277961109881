import React, { useState, useEffect } from 'react';
import SidebarAdmin from '../../Layout/Sidebar/Index';
import HeaderAdmin from '../../Layout/Header/Index';
import RecruiterProfile from '../../Components/Recruiter/RecruiterProfile';
import { useParams } from 'react-router-dom';
import { showErrorMessage } from '../../../globalConstant';
import Instance from '../../../Instance';

const RecruiterProfilePage = () => {
    const { id } = useParams();
    const [recruiter, setRecruiter] = useState(null);
    const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));
    const fetchRecruiterDetail = async () => {
        try {
            const response = await Instance.get(`/getRecruiterById/${id}`, {
                headers: {
                    Authorization: `Bearer ${loggedInUserInfo?.token}`
                }
            });
            if (response.status === 200) {
                setRecruiter(response?.data?.recruiter);
            }
        } catch (error) {
            console.error(error);
            showErrorMessage('Failed to fetch recruiter info');
        }
    }
    
    useEffect(() => {
        fetchRecruiterDetail();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [isCollapsed, setIsCollapsed] = useState(false);
    const handleCollapseToggle = (collapsedState) => {
      setIsCollapsed(collapsedState);
    };
    return (
        <>
            <SidebarAdmin onCollapseToggle={handleCollapseToggle}/>
            <HeaderAdmin onCollapseToggle={handleCollapseToggle} isCollapsed={isCollapsed}/>
            <div className={`main-wrapper ${isCollapsed ? "collapsed" : ""}`}>
                <RecruiterProfile details={recruiter} />
            </div>
        </>
    )
}

export default RecruiterProfilePage;