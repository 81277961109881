import React,{useState} from 'react';
import SidebarRecruiter from '../../Layout/Sidebar/Index';
import HeaderRecruiter from '../../Layout/Header/Index';
import { Applicants } from '../../Components/Applicants/Applicants';
import "./applicants.css"
const RecruiterApplicantsPage = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const handleCollapseToggle = (collapsedState) => {
      setIsCollapsed(collapsedState);
    };
    return (
        <>
            <SidebarRecruiter onCollapseToggle={handleCollapseToggle}/>
            <HeaderRecruiter onCollapseToggle={handleCollapseToggle} isCollapsed={isCollapsed}/>
            <div className={`main-wrapper ${isCollapsed ? "collapsed" : ""}`}>
                <Applicants />
            </div>
        </>
    )
}

export default RecruiterApplicantsPage;