import React, { useEffect, useMemo, useState } from "react";
import { SearchOutlined, EyeOutlined, EditOutlined } from "@ant-design/icons";
import { Input, Dropdown, Menu, Table, Button } from "antd";
import { TbArrowsSort } from "react-icons/tb";
import { IoEllipsisHorizontalSharp } from "react-icons/io5";
import { IMG_URL, showErrorMessage } from "../../../globalConstant";
import HospitalImg from '../../Assets/Image/Recriuiter/Hospital.png';
import Instance from "../../../Instance";
import Loader from "../../../Loader";
import { useNavigate } from "react-router-dom";

export const TotalClients = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [openRowId, setOpenRowId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalClients, setTotalClients] = useState([]);
  const [filterParameters, setFilterParameters] = useState({}); 
  const [isAscendingSort, setIsAcendingSort] = useState(false);
  const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));

  
  const tableData = useMemo(() => {
    if(searchText.trim() === '') return dataSource;
    else return dataSource?.filter((data) => `${data?.hospitalName}`.toLowerCase()?.includes(searchText?.toLowerCase()));
  },[dataSource,searchText]);

  const fetchTotalClients = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get(`/getAllClients`, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        }
      });
      if (response.status === 200) {
        const dataSource = response?.data?.clients?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setTotalClients(dataSource);
        setDataSource(dataSource);
      }
    } catch (error) {
      console.error(error);
      showErrorMessage('Failed to fetch clients data');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchTotalClients()
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  const columns = [
    {
      title: "Profile",
      render: (text, record) => (
        <img src={record?.hospitalImage ? `${IMG_URL}/${record?.hospitalImage}` : HospitalImg} style={{ height: "50px", width: "50px", borderRadius: "50%" }} className="me-2" />
      ),
    },
    {
      title: "Client Name",
      render: (text, record) => (
        <div>
          {record?.hospitalName}
        </div>
      )
    },
    {
      title: "Client Id",
      render: (text, record) => (
        <div>
          {record?.clientId}
        </div>
      )
    },
    {
      title: "Hospital Email",
      render: (text, record) => (
        <div>
          {record?.hospitalEmail}
        </div>
      )
    },
    {
      title: "Phone Number",
      render: (text, record) => (
        <div>
          {record?.mobileNumber}
        </div>
      )
    },
    {
      title: "Contact Person Name",
      render: (text, record) => (
        <div>
          {record?.contactPersonName}
        </div>
      )
    },
    {
      title: "Contact Person Email",
      render: (text, record) => (
        <div>
          {record?.contactPersonEmail}
        </div>
      )
    },
    {
      title: "Total Jobs",
      render: (text, record) => (
        <div>
          {record?.totalJobs || 'N/A'}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu>
              <Menu.Item key="view" onClick={() => navigate(`/admin/view-client/${record?.clientId}`)}>
                View <EyeOutlined />
              </Menu.Item>
              <Menu.Item key="edit" onClick={() => navigate(`/admin/edit-client/${record?._id}`)}>
                Edit <EditOutlined />
              </Menu.Item>
            </Menu>
          }
        >
          <Button type="text" icon={<IoEllipsisHorizontalSharp />} />
        </Dropdown>
      ),
    },
  ];

  const handleSorting = () => {
    try {
      let sortedData;
      if (isAscendingSort) {
        sortedData = [...dataSource]?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      } else {
        sortedData = [...dataSource]?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      }
      setDataSource(sortedData);
      setIsAcendingSort(!isAscendingSort);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="all-recruiter-section">
        <div>
          <div className="row">
            <div className="d-flex justify-content-between align-items-center align-content-center mb-4">
              <div className="d-flex gap-4 align-items-center">
                <h2>Total Clients</h2>
                <Input
                  placeholder="Search..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  className="search-input-table"
                  prefix={<SearchOutlined />}
                />
              </div>
              <div className="search-table-container gap-2">
                <button className="table-action-btn" onClick={handleSorting}>
                  <span>Sort</span>{" "}
                  <TbArrowsSort style={{ fontWeight: "bold", fontSize: "16px" }} />
                </button>
                {/* <button className="table-action-btn">
                  <FilterOutlined style={{ fontWeight: "bold", fontSize: "16px" }} />{" "}
                  <span>Filter</span>
                </button> */}
              </div>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            className="applied-applicants-table"
            rowKey={(row) => row._id}
          />
        </div>
      </div>
    </>
  );
};
