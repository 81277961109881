import React,{useState} from 'react';
import SidebarAdmin from '../../Layout/Sidebar/Index';
import HeaderAdmin from '../../Layout/Header/Index';
import { TotalApplicants } from '../../Components/DataBase/TotalApplicants';
import "./database.css"
const TotalApplicantsPage = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const handleCollapseToggle = (collapsedState) => {
      setIsCollapsed(collapsedState);
    };
    return (
        <>
            <SidebarAdmin onCollapseToggle={handleCollapseToggle}/>
            <HeaderAdmin onCollapseToggle={handleCollapseToggle} isCollapsed={isCollapsed}/>
            <div className={`main-wrapper ${isCollapsed ? "collapsed" : ""}`}>
                < TotalApplicants />
            </div>
        </>
    )
}

export default TotalApplicantsPage;