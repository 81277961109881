import React, { useEffect, useState } from 'react';
import "../../Pages/Recruiter/recruiter.css";
import { Form, Input, Radio, Select, DatePicker, Upload } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { showSuccessMessage, showErrorMessage } from '../../../globalConstant';
import Instance from '../../../Instance';
import { handleFileUpload, createUniqueS3FilenameWithDate } from '../../../globalConstant';
import { educationOptions, experienceOptions, specializationOptions, statusOptions } from './constant';
import { useNavigate } from 'react-router-dom';
import { IoCloudUploadOutline } from "react-icons/io5";
import { isValidPhoneNumber } from 'libphonenumber-js';
import Loader from '../../../Loader';
import { GetCountries, GetState, GetCity } from "react-country-state-city";

const modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
    ],
};

const AddRecruiter = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [teamList, setTeamList] = useState([]);
    const [formData, setFormData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [profileImage, setProfileImage] = useState(null);
    const [unAssignedJobs, setUnAssignedJobs] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));

    const fetchTeams = async () => {
        try {
            setIsLoading(true);
            const response = await Instance.get('/getAllTeams', {
                headers: {
                    Authorization: `Bearer ${loggedInUserInfo?.token}`
                }
            });
            if (response.status === 200) {
                setTeamList(response?.data?.teams);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    const fetchUnAssignedJobs = async () => {
        try {
            setIsLoading(true);
            const response = await Instance.get('/getUnAssignedJobs', {
                headers: {
                    Authorization: `Bearer ${loggedInUserInfo?.token}`
                }
            });
            if (response.status === 200) {
                setUnAssignedJobs(response?.data?.jobs);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchTeams();
        fetchUnAssignedJobs();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleFieldChange = (key, e) => {
        try {
            if (key === 'country') {
                setFormData({ ...formData, [key]: e, ['state']: null, ['city']: null });
                form.setFieldsValue({ state: null, city: null });
            } else if (key === 'state') {
                setFormData({ ...formData, [key]: e, ['city']: null });
                form.setFieldsValue({ city: null });
            } else if (key === 'mobileNumber' || key === 'associatedTeam' || key === 'educationDetails' || key === 'yearsOfExperience' || key === 'specializations' || key === 'joiningDate' || key === 'dateOfBirth' || key === 'recruiterRole' || key === 'city' || key === 'assignedJobs' || key === 'recruiterRoleDescription' || key === 'status') {
                setFormData({ ...formData, [key]: e });
            } else {
                setFormData({ ...formData, [key]: e.target.value });
            }
        } catch (error) {
            console.error(error);
        }
    }

    const isFormValid = () => {
        try {
            if (!formData?.firstName || formData?.firstName?.trim() === '') {
                showErrorMessage("FirstName is required!");
                return false;
            }
            if (!formData?.lastName || formData?.lastName?.trim() === '') {
                showErrorMessage("LastName is required!");
                return false;
            }
            if (!formData?.mobileNumber || formData?.mobileNumber?.trim() === '') {
                showErrorMessage("Mobile Number is required!");
                return false;
            } else if (!isValidPhoneNumber(formData?.mobileNumber)) {
                showErrorMessage("Invalid Mobile Number!");
                return false;
            }
            if (!formData?.email || formData?.email?.trim() === '') {
                showErrorMessage("Email is required!");
                return false;
            }
            if (!formData?.yearsOfExperience || formData?.yearsOfExperience?.trim() === '') {
                showErrorMessage("Years Of Experience is required!");
                return false;
            }
            if(typeof formData?.isLead === 'undefined' || formData?.isLead === null) {
                showErrorMessage("Role type is required!");
                return false;
            }
            if (!formData?.recruiterPosition || formData?.recruiterPosition?.trim() === '') {
                showErrorMessage("Recruiter Position is required!");
                return false;
            }
            if (!formData?.country) {
                showErrorMessage("Country is required!");
                return false;
            }
            if (!formData?.state) {
                showErrorMessage("State is required!");
                return false;
            }
            if (!formData?.city) {
                showErrorMessage("City is required!");
                return false;
            }
            if (!formData?.street || formData?.street?.trim() === '') {
                showErrorMessage("Street is required!");
                return false;
            }
            if (!formData?.zipCode || formData?.zipCode?.trim() === '') {
                showErrorMessage("Zip Code is required!");
                return false;
            }
            if (!formData?.joiningDate) {
                showErrorMessage("Joining Date is required!");
                return false;
            }
            if (!formData?.password || formData?.password?.trim() === '') {
                showErrorMessage("Password is required!");
                return false;
            }
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    const handleFormSubmit = async () => {
        try {
            if (!isFormValid()) {
                return;
            }
            setIsLoading(true);
            let reqData = { ...formData };
            let c = await GetCountries();
            reqData.country = c?.find((item) => item?.id === formData?.country)?.name;
            let s = await GetState(formData?.country);
            reqData.state = s?.find((item) => item?.id === formData?.state)?.name;
            if (profileImage) {
                const fileName = createUniqueS3FilenameWithDate(profileImage?.name);
                const file = await handleFileUpload(profileImage?.originFileObj, fileName, profileImage?.type);
                reqData.profileImage = fileName;
            }

            const response = await Instance.post('/addRecruiter', { ...reqData }, {
                headers: {
                    Authorization: `Bearer ${loggedInUserInfo?.token}`
                }
            });
            if (response.status === 201) {
                showSuccessMessage('Recruiter Added Successfully!');
                let unAssignedJob = [...unAssignedJobs].filter((job) => !reqData?.assignedJobs?.includes(job?._id));
                setUnAssignedJobs(unAssignedJob);
                form.resetFields();
                setFormData({});
                setProfileImage(null);
            }
        } catch (error) {
            console.error(error);
            showErrorMessage(error?.response?.data?.message || 'Failed to Add. Try again!');
        } finally {
            setIsLoading(false);
        }
    }

    const beforeUpload = (file) => {
        const isValidType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!isValidType) {
            showErrorMessage('You can only upload JPG/PNG files!');
        }
        const isSingleFile = file.size / 1024 / 1024 < 2;
        if (!isSingleFile) {
            showErrorMessage('Image must smaller than 2MB!');
        }
        return isValidType && isSingleFile;
    };

    const customRequest = ({ onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const teamOptions = teamList.map((team) => ({
        label: team?.teamName,
        value: team?._id
    }));

    const jobsOption = unAssignedJobs.map((job) => ({
        label: job?.title,
        value: job?._id
    }));


    useEffect(() => {
        const fetchCountries = async () => {
            let countries = await GetCountries();
            let options = countries?.map((item) => ({
                label: item?.name,
                value: item?.id,
            }));
            setCountryOptions(options);
        };
        fetchCountries();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const fetchStates = async () => {
            if (!formData?.country) {
                setStateOptions([]);
                return;
            }
            const states = await GetState(formData?.country);
            const options = states?.map((item) => ({
                label: item?.name,
                value: item?.id,
            }));
            setStateOptions(options);
        };
        fetchStates();
    }, [formData?.country]);

    useEffect(() => {
        const fetchCities = async () => {
            if (!formData?.state) {
                setCityOptions([]);
                return;
            }
            const cities = await GetCity(formData?.country, formData?.state);
            const options = cities?.map((item) => ({
                label: item?.name,
                value: item?.name,
            }));
            setCityOptions(options);
        };
        fetchCities();
    }, [formData?.state]);

    return (
        <>
            {isLoading && <Loader />}
            <div className="add-recruiter-section">
                <div>
                    <h2 className="mb-4">Add Recruiter</h2>
                    <Form layout="vertical" onFinish={handleFormSubmit} form={form}>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Item
                                    label="First Name"
                                    name="firstName"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <Input placeholder="Enter First Name" value={formData?.firstName || ""} onChange={(e) => handleFieldChange('firstName', e)} />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    label="Last Name"
                                    name="lastName"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <Input placeholder="Enter Last Name" value={formData?.lastName || ""} onChange={(e) => handleFieldChange('lastName', e)} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-6">
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[{ required: true, type: 'email', message: '' }]}
                                >
                                    <Input placeholder="Enter Email" value={formData?.email || ""} onChange={(e) => handleFieldChange('email', e)} autoComplete='off'/>
                                </Form.Item>
                            </div>
                            <div className="col-md-6 ">
                                <label htmlFor="inputPhone" className="form-label" style={{
                                    display: "flex",
                                    marginBottom: "0",
                                }}>
                                    <span style={{ color: "#ff4d4f", marginRight: "4px", marginBottom: "8px" }}>*</span> Mobile Number
                                </label>
                                <PhoneInput
                                    defaultCountry="us"
                                    value={formData?.mobileNumber || ""}
                                    onChange={(phone) => {
                                        setFormData((values) => ({
                                            ...values,
                                            ["mobileNumber"]: phone,
                                        }));
                                    }}
                                    inputStyle={{
                                        width: "100%",
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 ">
                                <Form.Item
                                    label="Date of Birth (DD/MM/YYYY)"
                                    name="dateOfBirth"
                                >
                                    <DatePicker
                                        placeholder='Select Date of Birth'
                                        format="DD/MM/YYYY"
                                        onChange={(date) => handleFieldChange('dateOfBirth', date)}
                                        className="form-control"
                                        disabledDate={(current) => current && current > new Date()}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-lg-6 ">
                                <Form.Item
                                    label="Assign Jobs"
                                    name="assignedJobs"
                                >
                                    <Select
                                        style={{ width: "100%" }}
                                        placeholder="Select Jobs"
                                        className="select-job-categories"
                                        onChange={(e) => {
                                            handleFieldChange("assignedJobs", e)
                                        }}
                                        options={jobsOption}
                                        value={formData?.assignedJobs}
                                        mode='multiple'
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 ">
                                <Form.Item
                                    label="Associated Team"
                                    name="associatedTeam"
                                >
                                    <Select placeholder="Select Team" options={teamOptions} value={formData?.associatedTeam || ""} onChange={(e) => handleFieldChange('associatedTeam', e)} />
                                </Form.Item>
                            </div>
                            <div className="col-md-6 ">
                                <Form.Item label="Role Type " name="roleType" rules={[{ required: true, message: '' }]}>
                                    <Radio.Group onChange={(e) => handleFieldChange('isLead', e)}>
                                        <Radio value={true}>Team Lead</Radio>
                                        <Radio value={false}>Recruiter</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 ">
                                <Form.Item
                                    label="Years Of Experience"
                                    name="yearsOfExperience"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <Select placeholder="Select Experience" value={formData?.yearsOfExperience || ""} options={experienceOptions} onChange={(e) => handleFieldChange('yearsOfExperience', e)} />
                                </Form.Item>
                            </div>
                            <div className="col-md-6 ">
                                <Form.Item
                                    label="Recruiter Position"
                                    name="recruiterPosition"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <Input placeholder="Enter Recruiter Position" value={formData?.recruiterPosition || ""} onChange={(e) => handleFieldChange('recruiterPosition', e)} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 ">
                                <Form.Item
                                    label="Education"
                                    name="educationDetails"
                                >
                                    <Select placeholder="Select Education" value={formData?.educationDetails || ""} options={educationOptions} onChange={(e) => handleFieldChange('educationDetails', e)} />
                                </Form.Item>
                            </div>
                            <div className="col-md-6 ">
                                <Form.Item
                                    label="Specializations"
                                    name="specializations"
                                >
                                    <Select mode='multiple' placeholder="Select Specializations" value={formData?.specializations || ""} options={specializationOptions} onChange={(e) => handleFieldChange('specializations', e)} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 ">
                                <Form.Item
                                    label="Select Joining Date"
                                    name="joiningDate"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <DatePicker
                                        placeholder='Select Joining Date'
                                        format="DD/MM/YYYY"
                                        onChange={(date) => handleFieldChange('joiningDate', date)}
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 ">
                                <Form.Item
                                    label="Country"
                                    name="country"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <Select
                                        placeholder="Select Country"
                                        className="select-job-categories"
                                        onChange={(e) => {
                                            handleFieldChange("country", e);
                                        }}
                                        options={countryOptions}
                                        value={formData?.country}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-lg-6 ">
                                <Form.Item
                                    label="State"
                                    name="state"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <Select
                                        placeholder="Select State"
                                        className="select-job-categories"
                                        onChange={(e) => {
                                            handleFieldChange("state", e);
                                        }}
                                        options={stateOptions}
                                        value={formData?.state}
                                        disabled={formData?.country ? false : true}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-lg-6 ">
                                <Form.Item
                                    label="City"
                                    name="city"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <Select
                                        style={{ width: "100%" }}
                                        placeholder="Select City"
                                        className="select-job-categories"
                                        onChange={(e) => {
                                            handleFieldChange("city", e)
                                        }}
                                        options={cityOptions}
                                        value={formData?.city}
                                        disabled={formData?.state ? false : true}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6 ">
                                <Form.Item
                                    label="Street"
                                    name="street"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <Input placeholder="Enter Street" value={formData?.street || ""} onChange={(e) => handleFieldChange('street', e)} />
                                </Form.Item>
                            </div>
                            <div className="col-md-6 ">
                                <Form.Item
                                    label="Zip Code"
                                    name="zipCode"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <Input placeholder="Enter Zip Code" value={formData?.zipCode || ""}
                                        onChange={(e) => {
                                            const numericValue = e.target.value.replace(/[^0-9]/g, '');
                                            handleFieldChange('zipCode', { target: { value: numericValue } });
                                        }}
                                        maxLength={5}
                                        onKeyPress={(e) => {
                                            if (!/^[0-9]*$/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }} />
                                </Form.Item>
                            </div>
                            <div className="col-md-6 ">
                                <Form.Item
                                    label="LinkedIn Url"
                                    name="linkedInUrl"
                                >
                                    <Input placeholder="Enter LinkedIn Url" value={formData?.linkedInUrl || ""} onChange={(e) => handleFieldChange('linkedInUrl', e)} />
                                </Form.Item>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 ">
                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <Input.Password placeholder="Enter Passowrd" value={formData?.password || ""} onChange={(e) => handleFieldChange('password', e)} autoComplete='off'/>
                                </Form.Item>
                            </div>
                            <div className="col-lg-6 ">
                                <Form.Item
                                    label="Status"
                                    name="status"
                                >
                                    <Select
                                        style={{ width: "100%" }}
                                        placeholder="Select Status"
                                        className="select-job-categories"
                                        onChange={(e) => {
                                            handleFieldChange("status", e)
                                        }}
                                        options={statusOptions}
                                        value={formData?.status}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 ">
                                <Form.Item label="Roles Description" name="recruiterRoleDescription">
                                    <ReactQuill
                                        theme="snow"
                                        value={formData?.recruiterRoleDescription || ""}
                                        onChange={(e) => handleFieldChange('recruiterRoleDescription', e)}
                                        modules={modules}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6 ">
                                <Form.Item label="Upload Profile Image">
                                    <Upload.Dragger
                                        name="profileImage"
                                        accept=".png,.jpg,.jpeg"
                                        beforeUpload={beforeUpload}
                                        fileList={profileImage ? [profileImage] : []}
                                        onChange={({ fileList }) => {
                                            if (fileList.length > 1) {
                                                showErrorMessage("You cannot add multiple images");
                                                return;
                                            }
                                            setProfileImage(fileList[0]);
                                        }}
                                        className="client-upload-dragger"
                                        action="#"
                                        customRequest={customRequest}
                                    >
                                        <button className="client-upload-drag-icon" type="button">
                                            <IoCloudUploadOutline />
                                        </button>
                                        <p className="ant-upload-text">Drop Image Here</p>
                                        <p className="ant-upload-hint">JPG/JPEG/PNG format</p>
                                    </Upload.Dragger>
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-12 text-end">
                                <button className="cancel-btn" type='button' onClick={() => navigate('/admin/all-recruiters')}>Cancel</button>
                                <button className='create-btn' type="submit">Add</button>
                            </div>
                        </div>
                    </Form>
                </div >
            </div >
        </>
    );
}

export default AddRecruiter;
