import React, { useEffect, useState } from 'react';
import { Filter, ArrowUpDown, Plus } from 'lucide-react';
import AssignedTask from './AssignedTask';
import OngoingTask from './OngoingTask';
import CompletedTask from './CompletedTask';
import CreateTaskPopup from './CreateTaskPopup';
import EditTaskPopup from './EditTaskPopup';
import Instance from '../../../Instance';
import { useSelector, useDispatch } from 'react-redux';
import { addAllTasks, deleteTask } from '../../../Admin/features/taskSlice';
import Loader from '../../../Loader';
import { showErrorMessage, showSuccessMessage } from '../../../globalConstant';
import { changeTaskStatus } from '../../../Admin/features/taskSlice';

const AllTasks = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const tasks = useSelector((state) => state.task.tasks);
  const [selectedTask, setSelectedTask] = useState(null);
  const [activeTab, setActiveTab] = useState('Assigned');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const assignedTask = tasks.filter((task) => task.taskStatus === 'CREATED');
  const ongoingTask = tasks.filter((task) => task.taskStatus === 'IN-PROGRESS');
  const completedTask = tasks.filter((task) => task.taskStatus === 'COMPLETED');
  const loggedInUserInfo = JSON.parse(localStorage.getItem('loggedInUserInfo'));

  const fetchTasks = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get('/getAllTasks', {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        },
      });

      if (response.status === 200) {
        const dataSource = response?.data?.tasks?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        dispatch(addAllTasks(dataSource));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeTaskStatus = async (taskId, taskStatus) => {
    try {
      setIsLoading(true);
      const response = await Instance.patch(`/changeTaskStatus/${taskId}`, {
        taskStatus
      }, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`
        }
      });
      if (response?.status === 200) {
        dispatch(changeTaskStatus({ _id: taskId, taskStatus }));
        showSuccessMessage('Status changed successfully');
      }
    } catch (error) {
      console.error(error);
      showErrorMessage('Failed to change status');
    } finally {
      setIsLoading(false);
    }
  }

  const handleDeleteTask = async (taskId) => {
    try {
      const response = await Instance.delete(`/deleteTask/${taskId}`, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`
        }
      });
      if (response?.status === 200) {
        dispatch(deleteTask(taskId));
        showSuccessMessage('Task deleted successfully');
      }
    } catch (error) {
      console.error(error);
      showErrorMessage('Failed to delete task');
    }
  }

  const handleSelectedTask = (task) => {
    setSelectedTask(task);
    setIsEditModalVisible(true);
  }

  const renderTasks = () => {
    switch (activeTab) {
      case 'Assigned':
        return assignedTask.map((task) => <AssignedTask key={task._id} detail={task} handleSelectedTask={handleSelectedTask} handleDeleteTask={handleDeleteTask} handleChangeTaskStatus={handleChangeTaskStatus} />);
      case 'Ongoing':
        return ongoingTask.map((task) => <OngoingTask key={task._id} detail={task} handleSelectedTask={handleSelectedTask} handleDeleteTask={handleDeleteTask} handleChangeTaskStatus={handleChangeTaskStatus} />);
      case 'Completed':
        return completedTask.map((task) => <CompletedTask key={task._id} detail={task} handleSelectedTask={handleSelectedTask} handleDeleteTask={handleDeleteTask} handleChangeTaskStatus={handleChangeTaskStatus} />);
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className='container' >
        <div className="recruiter-task-header ">
          <h1 className="recruiter-task-title">Tasks</h1>
          <div className="recruiter-button-group">
            <button className="recruiter-task-btn">
              <Filter size={14} className="recruiter-btn-icon" />
              Filter
            </button>
            <button className="recruiter-task-btn">
              <ArrowUpDown size={14} className="recruiter-btn-icon" />
              Sort by
            </button>
            <button className="recruiter-task-btn" onClick={() => setIsModalVisible(true)}>
              <Plus size={14} /> Add Task
            </button>
          </div>
        </div>
        <div className="recruiter-tab-group">
          {['Assigned', 'Ongoing', 'Completed'].map((tab) => (
            <button
              key={tab}
              className={`tab ${activeTab === tab ? 'active' : ''}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab} Task
            </button>
          ))}
        </div>
        <div className="recruiter-task-grid">{renderTasks()}</div>
        <CreateTaskPopup isVisible={isModalVisible} onClose={() => setIsModalVisible(false)} />
        <EditTaskPopup isVisible={isEditModalVisible} onClose={() => {
          setSelectedTask(null);
          setIsEditModalVisible(false);
        }} data={selectedTask} />
      </div>
    </>
  );
};

export default AllTasks;
