import { createSlice } from "@reduxjs/toolkit";

const taskSlice = createSlice({
    name: 'task',
    initialState: {
        tasks: []
    },
    reducers: {
        addAllTasks: (state, action) => {
            state.tasks = action.payload
        },
        addNewTask: (state, action) => {
            state.tasks.unshift(action.payload)
        },
        updateTask: (state, action) => {
            const index = state.tasks.findIndex((task) => task._id?.toString() === action.payload._id?.toString());
            state.tasks[index] = action.payload;
        },

        changeTaskStatus: (state, action) => {
            const index = state.tasks.findIndex((task) => task._id?.toString() === action.payload._id?.toString());
            state.tasks[index].taskStatus = action.payload.taskStatus;
        },
        deleteTask: (state, action) => {
            state.tasks = state.tasks.filter(item => item?._id?.toString() != action?.payload?.toString());
        }
    }
});

export const { addAllTasks, addNewTask, updateTask, changeTaskStatus, deleteTask } = taskSlice.actions;
export default taskSlice.reducer;