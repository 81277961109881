export const JobCategoryOptions = [{
    label: "Allied Jobs", value: "Allied Jobs"
}, {
    label: "Nursing Jobs", value: "Nursing Jobs"
}];

export const JobTypeOptions = [
    { value: "Part Time", label: "Part Time" },
    { value: "Full Time", label: "Full Time" },
    { value: 'Travel Contact', label: 'Travel Contact' },
    { value: 'Per Diem', label: 'Per Diem' },
    { value: "Internship", label: "Internship" },
    { value: "Contract", label: "Contract" }
];

export const JobStatusOptions = [
    { value: "Open", label: "Open" },
    { value: "Paused", label: "Paused" },
    { value: "Closed", label: "Closed" }
];

export const ShiftTypeOptions = [
    { value: "Day", label: "Day Shift" },
    { value: "Mid", label: "Mid Shift" },
    { value: "Evening", label: "Evening Shift" },
    { value: "Night", label: "Night Shift" },
    { value: "Rotating", label: "Rotating" }
];

export const ShiftHoursOptions = [
    { value: "5x8", label: "5x8" },
    { value: "4x10", label: "4x10" },
    { value: "4x12", label: "4x12" },
    { value: "3x12", label: "3x12" }
];

export const TimeZoneOptions = [
    { label: "Hawaii–Aleutian Standard Time", value: "Hawaii-Aleutian Standard Time" },
    { label: "Alaska Standard Time", value: "Alaska Standard Time" },
    { label: "Mountain Standard Time", value: "Mountain Standard Time" },
    { label: "Eastern Standard Time", value: "Eastern Standard Time" },
    { label: "Central Standard Time", value: "Central Standard Time" },
    { label: "Pacific Standard Time", value: "Pacific Standard Time" },
];

export const ExperienceLevelOptions = [
    { label: "Entry Level", value: "Entry Level" },
    { label: "Mid-Level", value: "Mid-Level" },
    { label: "Senior Level", value: "Senior Level" }
]

export function getRelativeTime(dateTime) {
    const createdDate = new Date(dateTime);
    const now = new Date();
    const diff = now - createdDate;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
  
    if (months > 0) {
      return `${months} month${months > 1 ? 's' : ''} ago`;
    } else if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    }
  }