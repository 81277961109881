import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../layout.css";
import { FiUsers, FiSettings, FiHelpCircle, FiMenu, FiX, } from "react-icons/fi";
import { FaRegFilePowerpoint} from "react-icons/fa6";
import logo from "../../Assets/Logo/logo.png";
import { IoMenu } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";

function SidebarSeoUser({onCollapseToggle}) {
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const toggleCollapse = () => {
    const newCollapsedState = !isCollapsed;
    setIsCollapsed(newCollapsedState);
    onCollapseToggle(newCollapsedState); // Notify parent component
  };

  const toggleSidebar = () => {
    console.log("Toggle Sidebar Clicked");
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };



  return (
    <>
       <div className="toggle-btn" onClick={toggleSidebar}>
        <FiMenu size={26} />
      </div>
      <div
  className={`sidebar ${isSidebarOpen ? "open" : ""} ${
    isCollapsed ? "collapsed" : ""
  }`}
>
  <aside
    className={`sidebar-content ${isSidebarOpen ? "open" : ""} ${
      isCollapsed ? "collapsed" : ""
    }`}
  >
    {isSidebarOpen && (
      <div className="overlay overlay-open" onClick={closeSidebar}></div>
    )}

    <div className="sidebar-header d-flex justify-content-between align-items-center">
      {!isCollapsed && (
        <img
          style={{ cursor: "pointer" }}
          src={logo}
          alt="BILKINS"
          className="sidebar-logo"
        />
      )}
      <button
        className="collapse-btn d-none d-lg-block"
        onClick={toggleCollapse}
      >
        {isCollapsed ? <IoMenu /> : <IoMdClose />}
      </button>
      <div className=" d-lg-none" onClick={closeSidebar}>
        <IoMdClose size={26} />
      </div>
    </div>
          <nav className="sidebar-nav">
            <ul>
              <li>
                <Link to="/seo-user/posts" className={`nav-link ${location.pathname === "/seo-user/posts" ? "active-nav-links" : ""}`}>
                  <FaRegFilePowerpoint className="sidebar-icon" />  {!isCollapsed && "Posts"}
                </Link>
              </li>
              <li>
                <Link to="/seo-user/general-applicants" className={`nav-link ${location.pathname === "/seo-user/general-applicants" ? "active-nav-links" : ""}`}>
                  <FiUsers className="sidebar-icon" />  {!isCollapsed && "General Applicants"}
                  </Link>
              </li>
              <li>
                <Link to="/seo-user/settings" className={`nav-link ${location.pathname === "/seo-user/settings" ? "active-nav-links" : ""}`}>
                  <FiSettings className="sidebar-icon" />  {!isCollapsed && "Settings"}
                </Link>
              </li>
              {/* <li>
                <Link to="/seo-user/help" className={`nav-link ${location.pathname === "/seo-user/help" ? "active-nav-links" : ""}`}>
                  <FiHelpCircle className="sidebar-icon" /> Help And Support
                </Link>
              </li> */}
            </ul>
          </nav>
        </aside>
      </div>
    </>
  );
}

export default SidebarSeoUser;
