import React,{useState} from 'react';
import SidebarRecruiter from '../../Layout/Sidebar/Index';
import HeaderRecruiter from '../../Layout/Header/Index';
import { MyTeam } from '../../Components/Teams/MyTeam';
import "./teams.css"
const MyTeamPage = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const handleCollapseToggle = (collapsedState) => {
      setIsCollapsed(collapsedState);
    };
    return (
        <>
            <SidebarRecruiter onCollapseToggle={handleCollapseToggle}/>
            <HeaderRecruiter onCollapseToggle={handleCollapseToggle} isCollapsed={isCollapsed}/>
            <div className={`main-wrapper ${isCollapsed ? "collapsed" : ""}`}>
                <MyTeam />
            </div>
        </>
    )
}

export default MyTeamPage;