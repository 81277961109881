import React, { useEffect, useState } from 'react'
import { FaBus, FaRegBuilding, FaRegHourglass, FaStar } from 'react-icons/fa'
import { CiCalendarDate, CiLocationOn } from "react-icons/ci";
import { MdOutlineAccessTime } from 'react-icons/md';
import { FiLayers } from 'react-icons/fi';
import { IoBagRemoveOutline } from 'react-icons/io5';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate, useParams } from "react-router-dom";
import Instance from '../../../Instance';
import { formatDate, showErrorMessage } from '../../../globalConstant';
import Loader from '../../../Loader';
import { GiFruitBowl } from 'react-icons/gi';
import { TbCategory } from 'react-icons/tb';
import { BsBagDash } from 'react-icons/bs';

export const JobDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [jobDetail, setJobDetail] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));

    const fetchJobDetail = async () => {
        try {
            setIsLoading(true);
            const response = await Instance.get(`/getJobDetails/${id}`, {
                headers: {
                    Authorization: `Bearer ${loggedInUserInfo?.token}`,
                },
            });
            setJobDetail(response?.data.job);
        } catch (error) {
            console.error(error);
            showErrorMessage('Failed to fetch job details. Try again!');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchJobDetail();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {isLoading && <Loader />}
            <div className='admin-view-job-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='view-job-info-card'>
                                <h2>{jobDetail?.jobDetails?.title}</h2>
                                <p>{jobDetail?.clientDetail?.hospitalName}
                                    &nbsp; <FaStar className='view-job-star-icon' /> 3.2 <span className='ongoing-vertical-line'></span> &nbsp; 1000 Reviews
                                </p>
                                <p>{jobDetail?.jobDetails?.shiftHours}, {jobDetail?.jobDetails?.shiftType} Shift &nbsp; <span>${jobDetail?.jobDetails?.weeklyEarnings}</span>/week </p>
                                <div className='d-flex gap-2'>
                                <p><CiLocationOn className='view-job-icon-red' /> {jobDetail?.jobDetails?.cities?.join(', ')}{jobDetail?.jobDetails?.cities.length>0 && ', '}{jobDetail?.jobDetails?.stateCode},{jobDetail?.jobDetails?.pinCode}</p>
                                <p><MdOutlineAccessTime className='view-job-icon-red'/> &nbsp;Time Zones: {jobDetail?.jobDetails?.timeZones?.join(', ')}</p>
                                <p><FaRegHourglass className='view-job-icon-red'/> Job Status: {jobDetail?.jobDetails?.jobStatus} </p>
                                </div>
                                <p>Duration: {jobDetail?.jobDetails?.duration || 'N/A'}</p>
                                
                                <div className='d-flex gap-4 mt-4'>
                                    <div>
                                        <CiCalendarDate className='view-job-icon-red' />
                                        <p>Job Posted</p>
                                        <span>{formatDate(jobDetail?.jobDetails?.jobPostedDate)}</span>
                                    </div>
                                    <div>
                                        <MdOutlineAccessTime className='view-job-icon-red' />
                                        <p>Job Start Date</p>
                                        <span>{jobDetail?.jobDetails?.jobStartDate?formatDate(jobDetail?.jobDetails?.jobStartDate):'N/A'}</span>
                                    </div>
                                    <div>
                                        <BsBagDash className='view-job-icon-red' />
                                        <p>Vacancies</p>
                                        <span>{jobDetail?.jobDetails?.vacancies}</span>
                                    </div>
                                    <div>
                                        <FiLayers className='view-job-icon-red' />
                                        <p>Experience</p>
                                        <span>{jobDetail?.jobDetails?.experience} years</span>
                                    </div>
                                    <div>
                                        <IoBagRemoveOutline className='view-job-icon-red' />
                                        <p>Job Type</p>
                                        <span>{jobDetail?.jobDetails?.jobType.join(', ')}</span>
                                    </div>
                                    
                                    <div>
                                        <FaRegBuilding  className='view-job-icon-red' />
                                        <p>Housing pay</p>
                                        <span>{jobDetail?.jobDetails?.housingPay || 'N/A'}</span>
                                    </div>
                                    <div>
                                        <GiFruitBowl className='view-job-icon-red' />
                                        <p>Meals pay</p>
                                        <span>{jobDetail?.jobDetails?.mealsPay || 'N/A'}</span>
                                    </div>
                                    <div>
                                        <FaBus  className='view-job-icon-red' />
                                        <p>Travel Per Diems</p>
                                        <span>{jobDetail?.jobDetails?.travelPerDiems || 'N/A'}</span>
                                    </div>
                                    <div>
                                        <TbCategory  className='view-job-icon-red' />
                                        <p>Job Category</p>
                                        <span>{jobDetail?.jobDetails?.jobCategory}</span>
                                    </div>
                                </div>

                            </div>
                            <div className='view-job-info-card mt-4'>
                            <h3>Assigned Recruiter</h3>
                            <p>{jobDetail?.jobDetails?.assignedRecruiter?`${jobDetail?.recruiterDetail?.firstName}${jobDetail?.recruiterDetail?.lastName}`:'N/A'}</p>
                            </div>
                            <div className='view-job-info-card mt-4'>
                                <h3>Application Status</h3>
                                <div className='d-flex gap-2 mb-2'>
                                <p>Draft: {jobDetail?.jobDetails?.applicantCounts.Draft}</p>
                                    <p>Applied: {jobDetail?.jobDetails?.applicantCounts.Applied}</p>
                                    <p>Review: {jobDetail?.jobDetails?.applicantCounts.Review}</p>
                                    <p>Approved: {jobDetail?.jobDetails?.applicantCounts.Approved}</p>
                                    <p>Submitted: {jobDetail?.jobDetails?.applicantCounts.Submitted}</p>
                                    <p>Interview: {jobDetail?.jobDetails?.applicantCounts.Interview}</p>
                                    <p>Schedule: {jobDetail?.jobDetails?.applicantCounts.Schedule}</p>
                                    <p>Offer: {jobDetail?.jobDetails?.applicantCounts.Offer}</p>
                                    <p>Hired: {jobDetail?.jobDetails?.applicantCounts.Hired}</p>
                                    <p>Closed: {jobDetail?.jobDetails?.applicantCounts.Closed}</p>
                                </div>
                                <h3>Job Description</h3>
                                <div className='recruiter-lead-job-description' dangerouslySetInnerHTML={{ __html: jobDetail?.jobDetails?.jobDesc}}></div>

                                <h3 className='mt-2'>Benefits</h3>
                                <ul>
                                    {jobDetail?.jobDetails?.jobBenefits?.length === 0 && <li>No benefits available</li>}
                                   {jobDetail?.jobDetails?.jobBenefits?.map((benefit,index) => (
                                        <li key={index}>{benefit}</li>
                                   ))}
                                </ul>

                                <h3>Immunization</h3>
                                <ul>
                                    {jobDetail?.jobDetails?.immunization?.length === 0 && <li>No immunization available</li>}
                                    {jobDetail?.jobDetails?.immunization?.map((immunization,index) => (
                                        <li key={index}>{immunization}</li>
                                    ))}
                                </ul>

                                <h3>Certification</h3>
                                <ul>
                                    {jobDetail?.jobDetails?.certification?.length === 0 && <li>No certification available</li>}
                                    {jobDetail?.jobDetails?.certification?.map((certification,index) => (
                                        <li key={index}>{certification}</li>
                                    ))}
                                </ul>

                            </div>

                        </div>

                    </div>
                    <button
                        type="button"
                        className="create-btn mt-4"
                        onClick={() => navigate(-1)}
                    >
                        <IoIosArrowBack />
                        &nbsp; Back
                    </button>

                </div>

            </div>
        </>
    )
}
