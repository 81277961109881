import React, { useState } from "react";
import { Dropdown, Menu, Popconfirm } from "antd";
import { MdEdit, MdDeleteOutline } from "react-icons/md";
import { IoEllipsisHorizontalSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { deleteTeam } from "../../features/teamSlice";
import { useDispatch } from "react-redux";
import Instance from "../../../Instance";
import { showErrorMessage, showSuccessMessage } from "../../../globalConstant";
import Loader from "../../../Loader";

export const TeamDetailCard = ({ team, handleEditClick }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));

    const handleDeleteTeam = async () => {
        try {
            setIsLoading(true);
            const response = await Instance.delete(`/deleteTeamById/${team?._id}`, {
                headers: {
                    Authorization: `Bearer ${loggedInUserInfo?.token}`,
                },
            });
            if (response?.status === 200) {
                dispatch(deleteTeam(team?._id));
                showSuccessMessage("Team deleted successfully");
            }
        } catch (error) {
            console.error(error);
            showErrorMessage("Failed to delete team");
        } finally {
            setIsLoading(false);
        }
    };

    const menu = (
        <Menu>
            <Menu.Item key="edit" className="horizontal-action-dropdown" onClick={() => handleEditClick(team)}>
                <MdEdit /> Edit
            </Menu.Item>
            <Menu.Item key="delete" className="horizontal-action-dropdown">
                <Popconfirm
                    title="Are you sure you want to delete?"
                    onConfirm={handleDeleteTeam}
                    okText="Yes"
                    cancelText="No"
                    okButtonProps={{
                        className: "popconfirm-yes-button",
                    }}
                    cancelButtonProps={{
                        className: "popconfirm-no-button",
                    }}
                >
                    <div>
                        <MdDeleteOutline /> Delete
                    </div>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            {isLoading && <Loader />}
            <div className="col-lg-4">
                <div className="team-member-card">
                    <div className="d-flex justify-content-end mb-3">
                        <Dropdown overlay={menu} trigger={["click"]}>
                            <IoEllipsisHorizontalSharp
                                size={20}
                                style={{ cursor: "pointer" }}
                            />
                        </Dropdown>
                    </div>
                    <div className="team-member-card-details">
                        <div className="d-flex align-center">
                            <p>Team Name</p>
                            <span className="colon">:</span>
                            <span>&nbsp; {team?.teamName}</span>
                        </div>

                        <div className="d-flex align-center">
                            <p>Department</p>
                            <span className="colon">:</span>
                            <span>&nbsp; {team?.departmentDivision}</span>
                        </div>

                        <div className="d-flex align-center">
                            <p>Description</p>
                            <span className="colon">:</span>
                            <span>
                                &nbsp; {team?.description
                                    ? team.description.length > 20
                                        ? `${team.description.slice(0, 20)}...`
                                        : team.description
                                    : "N/A"}
                            </span>
                        </div>

                        <div className="d-flex align-center">
                            <p>Team Lead</p>
                            <span className="colon">:</span>
                            <span>
                                &nbsp;{" "} {`${team?.teamLead?.firstName} ${team?.teamLead?.lastName}`}
                            </span>
                        </div>

                        <div className="d-flex align-center nowrap">
                            <p>Team Members</p>
                            <span className="colon">:</span>
                            <span>
                                &nbsp; {team?.teamMembers?.length > 0
                                    ? `${team?.teamMembers[0]?.memberId?.firstName} ${team?.teamMembers[0]?.memberId?.lastName}...`
                                    : "N/A"}
                            </span>
                        </div>
                    </div>
                    <div>
                        <button
                            className="team-member-view-btn"
                            onClick={() => navigate(`/admin/team-detail/${team?._id}`)}
                        >
                            View Details
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
