import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import Instance from "./Instance";

export const IMG_URL = `https://bilkinsbucket.s3.eu-north-1.amazonaws.com/`;
export const FRONTEND_BASE_URL = `https://www.bilkins.com/`;

export const showSuccessMessage = (message) => {
  Swal.fire({
    icon: "success",
    title: "Success",
    text: message,
    confirmButtonText: "Ok",
    iconColor: "var(--success-color)",
    confirmButtonColor: "var(--maroon-color)",
  });
};

export const showErrorMessage = (message) => {
  Swal.fire({
    icon: "error",
    title: "Error",
    text: message,
    confirmButtonText: "Ok",
    iconColor: "var(--danger-color)",
    confirmButtonColor: "var(--maroon-color)",
  });
};

export const handleFileUpload = async (file, fileName, type) => {
  try {
    const response = await Instance.get("/getUploadUrl", {
      params: {
        fileName: fileName,
        contentType: type,
      },
    });
    const presignedUrl = response?.data?.url;

    const response1 = await Instance.put(presignedUrl, file, {
      headers: {
        "Content-Type": type,
      },
    });

    return true;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const createUniqueS3FilenameWithDate = (filename) => {
  try {
    const date = new Date().toISOString().slice(0, 10).replace(/-/g, "");
    const uniqueId = uuidv4();
    const ext = filename.split(".").pop();
    const baseName = filename.replace(`.${ext}`, "");
    const validBaseName = baseName.replace(/[^a-zA-Z0-9-_]/g, "_");
    const uniqueFilename = `${validBaseName}-${date}-${uniqueId}.${ext}`;
    return uniqueFilename;    
  } catch (error) {
    throw new Error(error);
  }
};

export const formatDate = (date) => {
  if (!date) return "N/A";
  return new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

export const BUCKET_URL = "https://bilkinsbucket.s3.eu-north-1.amazonaws.com/"
