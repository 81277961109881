import React,{useState} from 'react';
import DashboardOverview from '../../Components/Dashboard/DashboardOverview';
import SidebarAdmin from '../../Layout/Sidebar/Index';
import HeaderAdmin from '../../Layout/Header/Index';

const AdminDashboard = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const handleCollapseToggle = (collapsedState) => {
      setIsCollapsed(collapsedState);
    };
    return (
        <>
            <SidebarAdmin onCollapseToggle={handleCollapseToggle}/>
            <HeaderAdmin onCollapseToggle={handleCollapseToggle} isCollapsed={isCollapsed}/>
            <div className={`main-wrapper ${isCollapsed ? "collapsed" : ""}`}>
                <DashboardOverview />
            </div>
        </>
    )
}

export default AdminDashboard;