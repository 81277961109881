import React from "react";
import "./tickets.css"
import TicketsList from "./TicketsList";

const Tickets = () => {

  return (
    <>
      <div className="main-title-all">
        <span>Tickets</span>
      </div>
      <TicketsList/>
    </>
  );
};

export default Tickets;
