import { createSlice } from "@reduxjs/toolkit";
import { GrSystem } from "react-icons/gr";

const teamSlice = createSlice({
    name: 'team',
    initialState: {
        teams: [],
        detailTeam: null,
    },
    reducers: {
        addTeams: (state, action) => {
            state.teams = action.payload;
        },
        addNewTeam: (state, action) => {
            state.teams.push(action.payload);
        },
        addDetailTeam: (state, action) => {
            state.detailTeam = action.payload;
        },
        updateTeam: (state, action) => {
            const index = state.teams.findIndex(item => item?._id?.toString() === action?.payload?._id?.toString());
            if (index != -1) {
                state.teams[index] = action.payload;
            }
        },
        removeMemberFromTeam: (state, action) => {
            if (state.detailTeam?.teamMembers) {
                state.detailTeam.teamMembers = state.detailTeam.teamMembers.filter(
                    item => item?.memberId?._id?.toString() !== action.payload.toString()
                );
            }
        },
        deleteTeam: (state, action) => {
            state.teams = state.teams.filter(item => item?._id?.toString() != action?.payload?.toString());
        }
    }
});

export const { addTeams, addNewTeam, addDetailTeam, updateTeam, deleteTeam, removeMemberFromTeam } = teamSlice.actions;
export default teamSlice.reducer;