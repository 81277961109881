import React from 'react';
import { Users, FileText, Briefcase } from 'lucide-react';
import { IoIosCloseCircleOutline } from "react-icons/io";

const ApplicantCards = ({ data }) => {
    const applied = data?.filter((item) => item?.status === 'Applied');
    const approved = data?.filter((item) => item?.status === 'Approved');
    const hired = data?.filter((item) => item?.status === 'Hired');
    const closed = data?.filter((item) => item?.status === 'Closed');

    const cardData = [
        { icon: Users, title: 'Total Applied', value: applied?.length },
        { icon: FileText, title: 'Total Approved', value: approved?.length },
        { icon: Briefcase, title: 'Total Hired', value: hired?.length },
        { icon: IoIosCloseCircleOutline, title: 'Closed', value: closed?.length },
    ];
    return (
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 mb-4">
            {cardData?.map(({ icon: Icon, title, value }, index) => (
                <div key={index} className="col">
                    <div className="admin-dashboard-card">
                        <div className="card-body d-flex align-items-center">
                            <div className="icon-wrapper bg-danger bg-opacity-10 p-3 rounded-circle me-3">
                                <Icon className="text-danger" size={24} />
                            </div>
                            <div>
                                <h6 className="card-subtitle mb-1">{title}</h6>
                                <h2 className="card-title mb-0">{value}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ApplicantCards;
