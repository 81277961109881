import React, { useState } from 'react';
import { Search, Send, Mic } from 'lucide-react';
// import "../../Pages/Teams/team.css";
import { FaUser } from 'react-icons/fa';

const TeamChats = () => {
    const allChats = [
        { name: 'Jennie Sherlock', message: 'Hey there is available', time: '4:30 PM', avatar: <FaUser />, unread: 0, group: false },
        { name: 'Ronald Tucker', message: 'Wow, that’s great...', time: '3:00 PM', avatar: <FaUser />, unread: 4, group: false },
        { name: 'Team Alpha', message: 'Meeting at 5 PM', time: '2:00 PM', avatar: <FaUser />, unread: 2, group: true },
        { name: 'Project Beta', message: 'New updates available', time: '1:00 PM', avatar: <FaUser />, unread: 0, group: true },
        { name: 'Rishabh Kumrawat', message: 'Wow, that’s great...', time: '3:00 PM', avatar: <FaUser />, unread: 4, group: false },
    ];
    
    const [activeTab, setActiveTab] = useState('All');
    const [selectedChat, setSelectedChat] = useState(null);
    
    const filteredChats = allChats.filter(chat => {
        if (activeTab === 'Unread') return chat.unread > 0;
        if (activeTab === 'Groups') return chat.group;
        if (activeTab === 'Contact') return !chat.group;
        return true;
    });
    
    const demoChat = [
        { sender: selectedChat?.name || '', message: 'Good morning', time: '11:35 AM', avatar: <FaUser /> },
        { sender: selectedChat?.name || '', message: 'Hi there, how are you?', time: '11:35 AM', avatar: <FaUser /> },
        { sender: 'Krishna Iam', message: 'I am down for whatever!', time: '11:36 AM', isSelf: true },
        { sender: 'Krishna Iam', message: 'Good morning', time: '11:36 AM', isSelf: true },
        { sender: 'Krishna Iam', message: 'I’ll be there in a few minutes, please wait!', time: '11:36 AM', isSelf: true },
        { sender: selectedChat?.name || '', message: 'Waiting for your reply...', time: '11:45 AM', avatar: <FaUser /> },
    ];

    return (
        <div className="recruiter-lead-team-chat-container">
            <div className="recruiter-lead-chat-sidebar">
                <div className="recruiter-lead-user-profile">
                    <div className='d-flex align-items-center'>
                        <FaUser className="recruiter-lead-avatar" />
                        <div className="recruiter-lead-profile-info">
                            <h2>Krishna Iam</h2>
                            <p className="recruiter-lead-status">Available</p>
                        </div>
                    </div>
                    <button className='recruiter-lead-ellipse-btn'>⋮</button>
                </div>
                <div className="recruiter-lead-search-bar">
                    <input type="text" placeholder="Search..." />
                    <Search className="w-5 h-5" />
                </div>
                <div className="recruiter-lead-chat-filters">
                    <button className={activeTab === 'All' ? 'active' : ''} onClick={() => setActiveTab('All')}>All</button>
                    <button className={activeTab === 'Unread' ? 'active' : ''} onClick={() => setActiveTab('Unread')}>Unread</button>
                    <button className={activeTab === 'Groups' ? 'active' : ''} onClick={() => setActiveTab('Groups')}>Groups</button>
                    <button className={activeTab === 'Contact' ? 'active' : ''} onClick={() => setActiveTab('Contact')}>Contact</button>
                </div>
                <div className='recruiter-lead-recent-chats'>
                    <h3>Recent Chats</h3>
                    <div className="recruiter-lead-recent-chats-section">
                        {filteredChats.map((chat, index) => (
                            <div
                                key={index}
                                className={`recruiter-lead-chat-item ${selectedChat === chat ? 'recruiter-lead-active-chat' : ''}`}
                                onClick={() => setSelectedChat(chat)}
                            >
                                <FaUser className="recruiter-lead-avatar" />
                                <div className="recruiter-lead-chat-info">
                                    <h4>{chat.name}</h4>
                                    <p>{chat.message}</p>
                                </div>
                                <div className="recruiter-lead-chat-meta">
                                    <p>{chat.time}</p>
                                    {chat.unread > 0 && <span className="recruiter-lead-unread-count">{chat.unread}</span>}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="recruiter-lead-main-chat">
                {selectedChat ? (
                    <>
                        <div className="recruiter-lead-chat-header">
                            <div className="d-flex align-items-center">
                                <FaUser className="recruiter-lead-avatar" />
                                <div className='recruiter-lead-profile-info'>
                                    <h2>{selectedChat.name}</h2>
                                    <p className="recruiter-lead-status">Available</p>
                                </div>
                            </div>
                            <div className="recruiter-lead-chat-actions">
                                <Search className="w-5 h-5" />
                                <button className='recruiter-lead-ellipse-btn'>⋮</button>
                            </div>
                        </div>
                        <div className="recruiter-lead-chat-messages">
                            <div className="recruiter-lead-date-separator">
                                <div className="recruiter-lead-line-horizontal"></div>
                                <button>Today</button>
                                <div className="recruiter-lead-line-horizontal"></div>
                            </div>
                            {demoChat.map((message, index) => (
                                <div
                                    key={index}
                                    className={`recruiter-lead-message ${message.isSelf ? 'self' : 'received'}`}
                                >
                                    <p>{message.message}</p>
                                    <span className="time">{message.time}</span>
                                </div>
                            ))}
                        </div>
                    </>
                ) : (
                    <p className="recruiter-lead-no-chat-selected">Select a chat to view messages</p>
                )}
                <div className="recruiter-lead-message-input">
                    <input type="text" placeholder="Message" />
                    <button><Mic className="w-5 h-5" /></button>
                    <button><Send className="w-5 h-5" /></button>
                </div>
            </div>
        </div>
    );
};

export default TeamChats;
