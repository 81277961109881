import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "../RecruiterLayout.css";
import logo from "../../Assets/Logo/logo.png";
import { FiGrid, FiSettings, FiHelpCircle, FiMenu, FiX, FiChevronDown, FiChevronRight, FiLogOut } from "react-icons/fi";
import { FaBookOpenReader, FaCircleUser, FaDatabase, FaIdCardClip, FaTeamspeak } from "react-icons/fa6";
import { FaTasks } from "react-icons/fa";
import Swal from "sweetalert2";
import logoutImg from "../../Assets/Logo/logo.png";
import { IoMenu } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";

function SidebarRecruiterLead({onCollapseToggle}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [expandedMenu, setExpandedMenu] = useState(null);
  const toggleCollapse = () => {
    const newCollapsedState = !isCollapsed;
    setIsCollapsed(newCollapsedState);
    onCollapseToggle(newCollapsedState); 
  };

  const toggleSidebar = () => {
    console.log("Toggle Sidebar Clicked");
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const toggleMenu = (menu) => {
    if (isCollapsed) {
      setIsCollapsed(false);
      onCollapseToggle(false);
    }
    setExpandedMenu(expandedMenu === menu ? null : menu);
  };

  useEffect(() => {
    const pathToMenuMap = {
      '/recruiter-lead/create-job': 'jobs',
      '/recruiter-lead/ongoing-jobs': 'jobs',
      '/recruiter-lead/closed-jobs': 'jobs',
      '/recruiter-lead/my-jobs': 'jobs',
      '/recruiter-lead/top-clients': 'clients',
      '/recruiter-lead/total-jobs': 'database',
      '/recruiter-lead/total-applicants': 'database',
      '/recruiter-lead/total-clients': 'database',
      '/recruiter-lead/team-chat': 'teams',
      '/recruiter-lead/my-team': 'teams',
      '/tasks': 'tasks',
    };

    const currentPath = location.pathname;
    const activeMenu = pathToMenuMap[currentPath];

    setExpandedMenu(activeMenu);
  }, [location.pathname]);

 

  const handlelogout = () => {
    Swal.fire({
      imageUrl: logoutImg,
      imageWidth: "160px",
      title:
        'Are you sure You want to <span style="color: var(--maroon-color);">Logout?</span>',
      showCancelButton: true,
      confirmButtonColor: "#555",
      cancelButtonColor: "#ce1b28",
      confirmButtonText: "Yes, logout me!",
      customClass: {
        title: "swal-title",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("loggedInUserInfo");
        navigate("/");
      }
    });
  };

  return (
    <>
      <div className="toggle-btn" onClick={toggleSidebar}>
        <FiMenu size={26} />
      </div>
      <div
  className={`sidebar ${isSidebarOpen ? "open" : ""} ${
    isCollapsed ? "collapsed" : ""
  }`}
>
  <aside
    className={`sidebar-content ${isSidebarOpen ? "open" : ""} ${
      isCollapsed ? "collapsed" : ""
    }`}
  >
    {/* Sidebar Overlay for mobile view */}
    {isSidebarOpen && (
      <div className="overlay overlay-open" onClick={closeSidebar}></div>
    )}

    <div className="sidebar-header d-flex justify-content-between align-items-center">
      {!isCollapsed && (
        <img
          style={{ cursor: "pointer" }}
          src={logo}
          alt="BILKINS"
          className="sidebar-logo"
        />
      )}
      <button
        className="collapse-btn d-none d-lg-block"
        onClick={toggleCollapse}
      >
        {isCollapsed ? <IoMenu /> : <IoMdClose />}
      </button>
      <div className=" d-lg-none" onClick={closeSidebar}>
        <IoMdClose size={26} />
      </div>
    </div>
          <nav className="sidebar-nav">
            <ul>
              <li>
                <Link to="/recruiter-lead/dashboard" className={`nav-link ${location.pathname === "/recruiter-lead/dashboard" ? "active-nav-links" : ""}`}>
                  <FiGrid className="sidebar-icon" /> {!isCollapsed && "Dashboard"}
                </Link>
              </li>

              <li>
                <div className={`nav-link ${expandedMenu === 'jobs' ? 'active-nav-links' : ''}`} onClick={() => toggleMenu('jobs')}>
                  <span className="d-flex align-items-center justify-content-between">
                    <span className="d-flex align-items-center">
                      <FaBookOpenReader className="sidebar-icon" />
                      <span> {!isCollapsed && "Jobs"}</span>
                    </span>
                    {expandedMenu === 'jobs' ? <FiChevronDown className="dropdown-icon" /> : <FiChevronRight className="dropdown-icon" />}
                  </span>
                </div>
                <ul className={`sub-menu ${expandedMenu === 'jobs' ? 'active' : ''}`}>
                  <li className="sub-nav-list"><Link to="/recruiter-lead/create-job" className="sub-nav-link">Add New Job</Link></li>
                  <li className="sub-nav-list"><Link to="/recruiter-lead/ongoing-jobs" className="sub-nav-link">Ongoing Jobs</Link></li>
                  <li className="sub-nav-list"><Link to="/recruiter-lead/paused-jobs" className="sub-nav-link">Paused Jobs</Link></li>
                  <li className="sub-nav-list"><Link to="/recruiter-lead/closed-jobs" className="sub-nav-link">Closed Jobs</Link></li>
                  <li className="sub-nav-list"><Link to="/recruiter-lead/my-jobs" className="sub-nav-link">My Jobs</Link></li>
                </ul>
              </li>

              <li>
                <Link to="/recruiter-lead/applicants" className={`nav-link ${location.pathname === "/recruiter-lead/applicants" ? "active-nav-links" : ""}`}>
                  <FaCircleUser className="sidebar-icon" /> {!isCollapsed && "Applicants"}
                </Link>
              </li>

              <li>
                <div className={`nav-link ${expandedMenu === 'clients' ? 'active-nav-links' : ''}`} onClick={() => toggleMenu('clients')}>
                  <span className="d-flex align-items-center justify-content-between">
                    <span className="d-flex align-items-center">
                      <FaIdCardClip className="sidebar-icon" />
                      <span>{!isCollapsed && "Clients"}</span>
                    </span>
                    {expandedMenu === 'clients' ? <FiChevronDown className="dropdown-icon" /> : <FiChevronRight className="dropdown-icon" />}
                  </span>
                </div>
                <ul className={`sub-menu ${expandedMenu === 'clients' ? 'active' : ''}`}>
                  <li className="sub-nav-list"><Link to="/recruiter-lead/top-clients" className="sub-nav-link">Top Client</Link></li>
                </ul>
              </li>

              <li>
                <div className={`nav-link ${expandedMenu === 'database' ? 'active-nav-links' : ''}`} onClick={() => toggleMenu('database')}>
                  <span className="d-flex align-items-center justify-content-between">
                    <span className="d-flex align-items-center">
                      <FaDatabase className="sidebar-icon" />
                      <span> {!isCollapsed && "Database"}</span>
                    </span>
                    {expandedMenu === 'database' ? <FiChevronDown className="dropdown-icon" /> : <FiChevronRight className="dropdown-icon" />}
                  </span>
                </div>
                <ul className={`sub-menu ${expandedMenu === 'database' ? 'active' : ''}`}>
                  <li className="sub-nav-list"><Link to="/recruiter-lead/total-jobs" className="sub-nav-link">Total Jobs</Link></li>
                  <li className="sub-nav-list"><Link to="/recruiter-lead/total-applicants" className="sub-nav-link">Total Applicants</Link></li>
                  <li className="sub-nav-list"><Link to="/recruiter-lead/total-clients" className="sub-nav-link">Total Clients</Link></li>
                </ul>
              </li>

              <li>
                <Link to="/recruiter-lead/tasks" className={`nav-link ${location.pathname === "/recruiter/tasks" ? "active-nav-links" : ""}`}>
                  <FaTasks className="sidebar-icon" /> {!isCollapsed && "Tasks"}
                </Link>
              </li>

              <li>
                <div className={`nav-link ${expandedMenu === 'teams' ? 'active-nav-links' : ''}`} onClick={() => toggleMenu('teams')}>
                  <span className="d-flex align-items-center justify-content-between">
                    <span className="d-flex align-items-center">
                      <FaTeamspeak className="sidebar-icon" />
                      <span> {!isCollapsed && "Teams"}</span>
                    </span>
                    {expandedMenu === 'teams' ? <FiChevronDown className="dropdown-icon" /> : <FiChevronRight className="dropdown-icon" />}
                  </span>
                </div>
                <ul className={`sub-menu ${expandedMenu === 'teams' ? 'active' : ''}`}>
                  <li className="sub-nav-list"><Link to="/recruiter-lead/my-team" className="sub-nav-link">My Team</Link></li>
                  {/* <li className="sub-nav-list"><Link to="/recruiter-lead/team-chat" className="sub-nav-link">Chat</Link></li> */}
                </ul>
              </li>

              <li>
                <Link to="/recruiter-lead/settings" className={`nav-link ${location.pathname === "/recruiter-lead/settings" ? "active-nav-links" : ""}`}>
                  <FiSettings className="sidebar-icon" />   {!isCollapsed && "Settings"}
                </Link>
              </li>
              <li>
                <Link to="/recruiter-lead/help-support" className={`nav-link ${location.pathname === "/recruiter-lead/help-support" ? "active-nav-links" : ""}`}>
                  <FiHelpCircle className="sidebar-icon" /> {!isCollapsed && "Help & Support"}
                </Link>
              </li>
              {/* <div className="line-dashed"></div> */}
              {/* <li>
                <Link to="#" className="nav-link" onClick={handlelogout}>
                  <FiLogOut className="sidebar-icon" /> Logout
                </Link>
              </li> */}
            </ul>
          </nav>
        </aside>
      </div>
    </>
  );
}

export default SidebarRecruiterLead;
