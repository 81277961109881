
import React, { useState } from 'react';
import { MoreHorizontal, Star } from 'lucide-react';
import { formatDate } from '../../../globalConstant';
import { Dropdown, Menu, Popconfirm } from "antd";
import { MdEdit, MdDeleteOutline } from "react-icons/md";
import { RiExchange2Line } from "react-icons/ri";

const CompletedTask = ({ detail, handleSelectedTask, handleDeleteTask, handleChangeTaskStatus }) => {
  const confirmChangeStatus = async (status) => {
    try {
      let statusVal = null;
      switch (status) {
        case "In-Progress":
          statusVal = "IN-PROGRESS";
          break;
        case "Pending":
          statusVal = "CREATED";
          break;
        case "Complete":
          statusVal = "COMPLETED";
          break;
        default:
          break;
      }
      handleChangeTaskStatus(detail._id, statusVal);
    } catch (error) {
      console.error(error);
    }
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="edit"
        className="horizontal-action-dropdown"
        onClick={() => handleSelectedTask(detail)}
      >
        <MdEdit /> Edit
      </Menu.Item>
      <Menu.SubMenu
        key="changeStatus"
        className="horizontal-action-dropdown"
        title={
          <span>
            <RiExchange2Line /> Change Status
          </span>
        }
      >
        {["Pending", "In-Progress", "Complete"].map((status) => (
          <Menu.Item key={status}>
            <Popconfirm
              title={`Are you sure you want to change the status to "${status}"?`}
              onConfirm={() => confirmChangeStatus(status)}
              okText="Yes"
              cancelText="No"
            >
              <span style={{ cursor: "pointer" }}>{status}</span>
            </Popconfirm>
          </Menu.Item>
        ))}
      </Menu.SubMenu>
      <Menu.Item key="delete" className="horizontal-action-dropdown">
        <Popconfirm
          title="Are you sure you want to delete?"
          onConfirm={() => handleDeleteTask(detail?._id)}
          okText="Yes"
          cancelText="No"
          okButtonProps={{
            className: "popconfirm-yes-button",
          }}
          cancelButtonProps={{
            className: "popconfirm-no-button",
          }}
        >
          <div>
            <MdDeleteOutline /> Delete
          </div>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="completed-task-card">
        <div className="task-header">
          <span className="task-id">Task-Id : {detail?.taskId}</span>
          <Dropdown overlay={menu} trigger={["click"]}>
            <MoreHorizontal size={20} style={{ cursor: "pointer" }} />
          </Dropdown>
        </div>
        <div className="task-detail">
          <span className="task-label">Task : </span>
          <span>{detail?.taskName}</span>
        </div>
        <div className="task-detail project-name">
          <span className="task-label">Project : </span>
          <span>{detail?.projectName}</span>
          <Star className="star-icon" size={16} />
        </div>
        <div className="task-detail">
          <span className="task-label">Users : </span>
          {detail?.members?.map(member => `${member?.memberId?.firstName} ${member?.memberId?.lastName}`).join(', ')}
        </div>
        <div className="task-detail">
          <span className="task-label">Client : </span>
          <span className="not-assigned">{detail?.associatedClient?.hospitalName || 'N/A'}</span>
        </div>
        <div className="task-detail">
          <span className="task-label">Starts At : </span>
          <span>{formatDate(detail?.startDate)}</span>
        </div>
        <div className="task-detail">
          <span className="task-label">Ends At : </span>
          <span>{formatDate(detail?.endDate)}</span>
        </div>
        <div className="task-detail">
          <span className="task-label">Status : </span>
          <span className={"status-completed"}>Completed</span>
        </div>
      </div>
    </>
  )
}





export default CompletedTask;
