import React,{useState} from 'react';
import "../../../Admin/Pages/Jobs/jobs.css"
import SidebarRecruiter from '../../Layout/Sidebar/Index';
import HeaderRecruiter from '../../Layout/Header/Index';
import { CLosedJobsList } from '../../Components/Jobs/ClosedJobsList';
import "./recruiterJobs.css"
const RecruiterClosedJobsListPage = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const handleCollapseToggle = (collapsedState) => {
      setIsCollapsed(collapsedState);
    };
    return (
        <>
            <SidebarRecruiter onCollapseToggle={handleCollapseToggle}/>
            <HeaderRecruiter onCollapseToggle={handleCollapseToggle} isCollapsed={isCollapsed}/>
            <div className={`main-wrapper ${isCollapsed ? "collapsed" : ""}`}>
                <CLosedJobsList />
            </div>
        </>
    )
}

export default RecruiterClosedJobsListPage;