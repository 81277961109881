import React, { useEffect, useState, useMemo } from 'react';
import "../../Pages/Recruiter/recruiter.css";
import { Form, Input, Radio, Select, DatePicker, Upload } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { showSuccessMessage, showErrorMessage } from '../../../globalConstant';
import Instance from '../../../Instance';
import { educationOptions, experienceOptions, shiftTypeOptions, specializationOptions, statusOptions } from './constant';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Loader from '../../../Loader';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { GetCountries, GetState, GetCity } from "react-country-state-city";

const modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
    ],
};

const EditRecruiter = () => {
    const { id } = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [teamList, setTeamList] = useState([]);
    const [formData, setFormData] = useState(null);
    const [recruiterInfo, setRecruiterInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [unAssignedJobs, setUnAssignedJobs] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));

    const fetchRecruiterDetail = async () => {
        try {
            setIsLoading(true);
            const response = await Instance.get(`/getRecruiterById/${id}`, {
                headers: {
                    Authorization: `Bearer ${loggedInUserInfo?.token}`
                }
            });
            if (response.status === 200) {
                setRecruiterInfo(JSON.parse(JSON.stringify(response?.data?.recruiter)));
                let data = JSON.parse(JSON.stringify(response?.data?.recruiter));
                data.assignedJobs = data.assignedJobs.map(job => job._id);
                let c = await GetCountries();
                data.country = c?.find((item) => item?.name === data?.country)?.id;
                let s = await GetState(data?.country);
                data.state = s?.find((item) => item?.name === data?.state)?.id;
                setFormData(data);
                form?.setFieldsValue({
                    ...data,
                    joiningDate: dayjs(data?.joiningDate),
                    dateOfBirth: data?.dateOfBirth ? dayjs(data?.dateOfBirth) : null,
                    roleType: data?.isLead,
                    country: data?.country,
                    state: data?.state,
                    city: data?.city
                });
            }
        } catch (error) {
            console.error(error);
            showErrorMessage('Failed to fetch recruiter info');
        } finally {
            setIsLoading(false);
        }
    }

    const fetchUnAssignedJobs = async () => {
        try {
            setIsLoading(true);
            const response = await Instance.get('/getUnAssignedJobs', {
                headers: {
                    Authorization: `Bearer ${loggedInUserInfo?.token}`
                }
            });
            if (response.status === 200) {
                setUnAssignedJobs(response?.data?.jobs);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    const fetchTeams = async () => {
        try {
            setIsLoading(true);
            const response = await Instance.get('/getAllTeams', {
                headers: {
                    Authorization: `Bearer ${loggedInUserInfo?.token}`
                }
            });
            if (response.status === 200) {
                setTeamList(response?.data?.teams);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchTeams();
        fetchUnAssignedJobs();
        fetchRecruiterDetail();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleFieldChange = (key, e) => {
        try {
            if (key === 'country') {
                setFormData({ ...formData, [key]: e, ['state']: null, ['city']: null });
                form.setFieldsValue({ state: null, city: null });
            } else if (key === 'state') {
                setFormData({ ...formData, [key]: e, ['city']: null });
                form.setFieldsValue({ city: null });
            } else if (key === 'mobileNumber' || key === 'associatedTeam' || key === 'educationDetails' || key === 'yearsOfExperience' || key === 'specializations' || key === 'preferredShifts' || key === 'joiningDate' || key === 'dateOfBirth' || key === 'recruiterRole' || key === 'city' || key === 'assignedJobs' || key === 'recruiterRoleDescription' || key === 'status') {
                setFormData({ ...formData, [key]: e });
            } else {
                setFormData({ ...formData, [key]: e.target.value });
            }
        } catch (error) {
            console.error(error);
        }
    }

    const isFormValid = () => {
        try {
            if (!formData?.firstName || formData?.firstName?.trim() === '') {
                showErrorMessage("FirstName is required!");
                return false;
            }
            if (!formData?.lastName || formData?.lastName?.trim() === '') {
                showErrorMessage("LastName is required!");
                return false;
            }
            if (!formData?.mobileNumber || formData?.mobileNumber?.trim() === '') {
                showErrorMessage("Mobile Number is required!");
                return false;
            } else if (!isValidPhoneNumber(formData?.mobileNumber)) {
                showErrorMessage("Invalid Mobile Number!");
                return false;
            }
            if (!formData?.email || formData?.email?.trim() === '') {
                showErrorMessage("Email is required!");
                return false;
            }
            if (!formData?.yearsOfExperience || formData?.yearsOfExperience?.trim() === '') {
                showErrorMessage("Years Of Experience is required!");
                return false;
            }
            if(typeof formData?.isLead === 'undefined' || formData?.isLead === null) {
                showErrorMessage("Role type is required!");
                return false;
            }
            if (!formData?.recruiterPosition || formData?.recruiterPosition?.trim() === '') {
                showErrorMessage("Recruiter Position is required!");
                return false;
            }
            if (!formData?.country) {
                showErrorMessage("Country is required!");
                return false;
            }
            if (!formData?.state) {
                showErrorMessage("State is required!");
                return false;
            }
            if (!formData?.city) {
                showErrorMessage("City is required!");
                return false;
            }
            if (!formData?.street || formData?.street?.trim() === '') {
                showErrorMessage("Street is required!");
                return false;
            }
            if (!formData?.zipCode || formData?.zipCode?.trim() === '') {
                showErrorMessage("Zip Code is required!");
                return false;
            }
            if (!formData?.joiningDate) {
                showErrorMessage("Joining Date is required!");
                return false;
            }
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    const handleSubmit = async () => {
        try {
            if (!isFormValid()) {
                return;
            }
            setIsLoading(true);
            let reqData = { ...formData };
            let c = await GetCountries();
            reqData.country = c?.find((item) => item?.id === formData?.country)?.name;
            let s = await GetState(formData?.country);
            reqData.state = s?.find((item) => item?.id === formData?.state)?.name;
            const response = await Instance.put(`/editRecruiterById/${id}`, { ...reqData }, {
                headers: {
                    Authorization: `Bearer ${loggedInUserInfo?.token}`
                }
            });
            if (response.status === 200) {
                showSuccessMessage('Recruiter Updated Successfully!');
                navigate('/admin/all-recruiters');
            }
        } catch (error) {
            console.error(error);
            showErrorMessage(error?.response?.data?.message || 'Failed to Edit. Try again!');
        } finally {
            setIsLoading(false);
        }
    }

    const teamOptions = teamList.map((team) => ({
        label: team?.teamName,
        value: team?._id
    }));

    const jobsOption = [...unAssignedJobs, ...recruiterInfo?.assignedJobs || []].map((job) => ({
        label: job?.title,
        value: job?._id
    }));

    useEffect(() => {
        const fetchCountries = async () => {
            let countries = await GetCountries();
            let options = countries?.map((item) => ({
                label: item?.name,
                value: item?.id,
            }));
            setCountryOptions(options);
        };

        fetchCountries();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const fetchStates = async () => {
            if (!formData?.country) {
                setStateOptions([]);
                return;
            }
            const states = await GetState(formData?.country);
            const options = states?.map((item) => ({
                label: item?.name,
                value: item?.id,
            }));
            setStateOptions(options);
        };
        fetchStates();
    }, [formData?.country]);

    useEffect(() => {
        const fetchCities = async () => {
            if (!formData?.state) {
                setCityOptions([]);
                return;
            }
            const cities = await GetCity(formData?.country, formData?.state);
            const options = cities?.map((item) => ({
                label: item?.name,
                value: item?.name,
            }));
            setCityOptions(options);
        };
        fetchCities();
    }, [formData?.state]);

    return (
        <>
            {isLoading && <Loader />}
            <div className="add-recruiter-section">
                <div className="container">
                    <h2 className="mb-4">Edit Recruiter</h2>
                    <Form layout="vertical" onFinish={handleSubmit} form={form}>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <Form.Item
                                    label="First Name"
                                    name="firstName"
                                    rules={[{ required: true, message: 'Please enter first name!' }]}
                                >
                                    <Input placeholder="Enter First Name" value={formData?.firstName || ""} onChange={(e) => handleFieldChange('firstName', e)} />
                                </Form.Item>
                            </div>
                            <div className="col-md-6 mb-3">
                                <Form.Item
                                    label="Last Name"
                                    name="lastName"
                                    rules={[{ required: true, message: 'Please enter last name!' }]}
                                >
                                    <Input placeholder="Enter Last Name" value={formData?.lastName || ""} onChange={(e) => handleFieldChange('lastName', e)} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-6 mb-3">
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[{ required: true, type: 'email', message: 'Please enter valid email!' }]}
                                >
                                    <Input placeholder="Enter Email" value={formData?.email || ""} onChange={(e) => handleFieldChange('email', e)} disabled />
                                </Form.Item>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="inputPhone" className="form-label" style={{
                                    display: "flex",
                                    marginBottom: "0",
                                }}>
                                    <span style={{ color: "#ff4d4f", marginRight: "4px", marginBottom: "8px" }}>*</span> Mobile Number
                                </label>
                                <PhoneInput
                                    defaultCountry="us"
                                    value={formData?.mobileNumber || ""}
                                    onChange={(phone) => {
                                        setFormData((values) => ({
                                            ...values,
                                            ["mobileNumber"]: phone,
                                        }));
                                    }}
                                    inputStyle={{
                                        width: "100%",
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 ">
                                <Form.Item
                                    label="Date of Birth (DD/MM/YYYY)"
                                    name="dateOfBirth"
                                >
                                    <DatePicker
                                        placeholder='Select Date of Birth'
                                        format="DD/MM/YYYY"
                                        onChange={(date) => handleFieldChange('dateOfBirth', date)}
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-lg-6 ">
                                <Form.Item
                                    label="Assign Jobs"
                                    name="assignedJobs"
                                >
                                    <Select
                                        style={{ width: "100%" }}
                                        placeholder="Select Jobs"
                                        className="select-job-categories"
                                        onChange={(e) => {
                                            handleFieldChange("assignedJobs", e)
                                        }}
                                        options={jobsOption}
                                        value={formData?.assignedJobs}
                                        mode='multiple'
                                    />
                                </Form.Item>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <Form.Item
                                    label="Associated Team"
                                    name="associatedTeam"
                                >
                                    <Select placeholder="Select Team" options={teamOptions} value={formData?.associatedTeam || ""} onChange={(e) => {
                                        handleFieldChange('associatedTeam', e);
                                    }} disabled={recruiterInfo?.assignedJobs?.length > 0} />
                                </Form.Item>
                            </div>
                            <div className="col-md-6 mb-3">
                                <Form.Item label="Role Type " name="roleType" rules={[{ required: true }]}>
                                    <Radio.Group onChange={(e) => handleFieldChange('isLead', e)}>
                                        <Radio value={true}>Team Lead</Radio>
                                        <Radio value={false}>Recruiter</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <Form.Item
                                    label="Years Of Experience"
                                    name="yearsOfExperience"
                                    rules={[{ required: true, message: 'Please select the experience level!' }]}
                                >
                                    <Select placeholder="Select Experience" value={formData?.yearsOfExperience || ""} options={experienceOptions} onChange={(e) => handleFieldChange('yearsOfExperience', e)} />
                                </Form.Item>
                            </div>
                            <div className="col-md-6 mb-3">
                                <Form.Item
                                    label="Recruiter Position"
                                    name="recruiterPosition"
                                    rules={[{ required: true, message: 'Please select recruiter position!' }]}
                                >
                                    <Input placeholder="Enter Recruiter Position" value={formData?.recruiterPosition || ""} onChange={(e) => handleFieldChange('recruiterPosition', e)} />
                                </Form.Item>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <Form.Item
                                    label="Education"
                                    name="educationDetails"
                                >
                                    <Select placeholder="Select Education" value={formData?.educationDetails || ""} options={educationOptions} onChange={(e) => handleFieldChange('educationDetails', e)} />
                                </Form.Item>
                            </div>
                            <div className="col-md-6 mb-3">
                                <Form.Item
                                    label="Specializations"
                                    name="specializations"
                                >
                                    <Select mode='multiple' placeholder="Select Specializations" value={formData?.specializations || ""} options={specializationOptions} onChange={(e) => handleFieldChange('specializations', e)} />
                                </Form.Item>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <Form.Item
                                    label="Preferred Shifts" name="preferredShifts"
                                >
                                    <Select mode="multiple" value={formData?.preferredShifts || ""} placeholder="Select Shift Type" options={shiftTypeOptions} onChange={(e) => handleFieldChange('preferredShifts', e)} />
                                </Form.Item>
                            </div>
                            <div className="col-md-6 mb-3">
                                <Form.Item
                                    label="Select Joining Date"
                                    name="joiningDate"
                                    rules={[{ required: true, message: 'Please select a joining date!' }]}
                                >
                                    <DatePicker
                                        format="DD/MM/YYYY"
                                        onChange={(date) => handleFieldChange('joiningDate', date)}
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 ">
                                <Form.Item
                                    label="Country"
                                    name="country"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <Select
                                        placeholder="Select Country"
                                        className="select-job-categories"
                                        onChange={(e) => {
                                            handleFieldChange("country", e);
                                        }}
                                        options={countryOptions}
                                        value={formData?.country}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <Form.Item
                                    label="State"
                                    name="state"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <Select
                                        placeholder="Select State"
                                        className="select-job-categories"
                                        onChange={(e) => {
                                            handleFieldChange("state", e);
                                        }}
                                        options={stateOptions}
                                        value={formData?.state}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <Form.Item
                                    label="City"
                                    name="city"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <Select
                                        style={{ width: "100%" }}
                                        placeholder="Select City"
                                        className="select-job-categories"
                                        onChange={(e) => {
                                            handleFieldChange("city", e)
                                        }}
                                        options={cityOptions}
                                        value={formData?.city}
                                        disabled={formData?.state ? false : true}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6 mb-3">
                                <Form.Item
                                    label="Street"
                                    name="street"
                                    rules={[{ required: true, message: 'Please enter street!' }]}
                                >
                                    <Input placeholder="Enter Street" value={formData?.street || ""} onChange={(e) => handleFieldChange('street', e)} />
                                </Form.Item>
                            </div>
                            <div className="col-md-6 mb-3">
                                <Form.Item
                                    label="Zip Code"
                                    name="zipCode"
                                    rules={[{ required: true, message: 'Please enter zip code!' }]}
                                >
                                    <Input placeholder="Enter Zip Code" value={formData?.zipCode || ""} onChange={(e) => handleFieldChange('zipCode', e)} />
                                </Form.Item>
                            </div>
                            <div className="col-md-6 mb-3">
                                <Form.Item
                                    label="LinkedIn Url"
                                    name="linkedInUrl"
                                >
                                    <Input placeholder="Enter LinkedIn Url" value={formData?.linkedInUrl || ""} onChange={(e) => handleFieldChange('linkedInUrl', e)} />
                                </Form.Item>
                            </div>
                            <div className="col-lg-6 ">
                                <Form.Item
                                    label="Status"
                                    name="status"
                                >
                                    <Select
                                        style={{ width: "100%" }}
                                        placeholder="Select Status"
                                        className="select-job-categories"
                                        onChange={(e) => {
                                            handleFieldChange("status", e)
                                        }}
                                        options={statusOptions}
                                        value={formData?.status}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 mb-3">
                                <Form.Item label="Roles Description" name="recruiterRoleDescription">
                                    <ReactQuill
                                        theme="snow"
                                        value={formData?.recruiterRoleDescription || ""}
                                        onChange={(e) => handleFieldChange('recruiterRoleDescription', e)}
                                        modules={modules}
                                    />
                                </Form.Item>
                            </div>

                        </div>

                        <div className="row mt-4">
                            <div className="col-md-12 text-end">
                                <button className="cancel-btn" type='button' onClick={() => navigate('/admin/all-recruiters')}>Cancel</button>
                                <button className='create-btn' type="submit">Update</button>
                            </div>
                        </div>
                    </Form>
                </div >
            </div >
        </>
    );
}

export default EditRecruiter;
