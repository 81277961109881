import React,{useState} from 'react';
import "../../../Admin/Pages/Jobs/jobs.css"
import SidebarRecruiterLead from '../../Layout/Sidebar/Index';
import HeaderRecruiterLead from '../../Layout/Header/Index';
import "./recruiterJobs.css"
import { JobDetail } from '../../Components/Jobs/JobDetail';

const RecruiterLeadJobDetailPage = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const handleCollapseToggle = (collapsedState) => {
      setIsCollapsed(collapsedState);
    };
  
    return (
        <>
              <SidebarRecruiterLead onCollapseToggle={handleCollapseToggle}/>
              <HeaderRecruiterLead onCollapseToggle={handleCollapseToggle} isCollapsed={isCollapsed}/>
            <div className={`main-wrapper ${isCollapsed ? "collapsed" : ""}`}>
                <JobDetail />
            </div>
        </>
    )
}

export default RecruiterLeadJobDetailPage ;