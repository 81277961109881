export const educationOptions = [
    { label: "Bachelor's", value: "Bachelor's" },
    { label: "Master's", value: "Master's" },
    { label: "Phd", value: "Phd" },
    { label: "Doctorate", value: "Doctorate" }
];

export const experienceOptions = [
    { label: "Fresher", value: "Fresher" },
    { label: "1-2 years", value: "1-2 years" },
    { label: "2-3 years", value: "2-3 years" },
    { label: "3-4 years", value: "3-4 years" },
    { label: "4+ years", value: "4+ years" }
];

export const shiftTypeOptions = [
    { label: "Day Shift", value: "Day" },
    { label: "Mid Shift", value: "Mid" },
    { label: "Evening Shift", value: "Evening" },
    { label: "Night Shift", value: "Night" },
    { label: 'Rotating Shift', value: 'Rotating' }
];

export const specializationOptions = [
    { label: "IT", value: "IT" },
    { label: "Business", value: "Business" },
    { label: "Engineering", value: "Engineering" },
    { label: "Science", value: "Science" },
    { label: "Arts", value: "Arts" }
];

export const preferredLocationsOptions = [
    { label: "Alaska", value: "Alaska" },
    { label: "California", value: "California" },
    { label: "Hawaii", value: "Hawaii" },
    { label: "New York", value: "New York" },
    { label: "Texas", value: "Texas" },
    { label: 'Albama', value: 'Albama' },
    { label: 'Arizona', value: 'Arizona' },
];

export const preferredJobCategoriesOptions = [
    { label: "IT", value: "IT" },
    { label: "Business", value: "Business" },
    { label: "Engineering", value: "Engineering" },
    { label: "Science", value: "Science" },
    { label: "Arts", value: "Arts" },
    { label: "Other", value: "Other" }
];

export const statusOptions = [
    { label: "Active", value: "ACTIVE" },
    { label: "Inactive", value: "INACTIVE" },
    { label: "Terminated", value: "TERMINATED" },
    { label: "Resigned", value: "RESIGNED" }
]