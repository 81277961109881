import { createSlice } from "@reduxjs/toolkit";

const postSlice = createSlice({
  name: "postsSlice",
  initialState: {
    posts: [],
  },
  reducers: {
    addAllPosts: (state, action) => {
      state.posts = action.payload;
    },
    deletePosts: (state, action) => {
      state.posts = state.posts.filter(
        (item) => item._id.toString() !== action.payload?.toString()
      );
    },
    editPosts: (state, action) => {
      let index = state.posts.findIndex(
        (item) => item._id?.toString() === action.payload._id?.toString()
      );
      if (index !== -1) state.posts[index] = action.payload;
    },
  },
});

export const { addAllPosts, deletePosts, editPosts } = postSlice.actions;

export default postSlice.reducer;
