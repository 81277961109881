import React,{useState} from 'react';
import SidebarAdmin from '../../Layout/Sidebar/Index';
import HeaderAdmin from '../../Layout/Header/Index';
import "./jobs.css"
import { OngoingJobsList } from '../../Components/Jobs/OngoingJobsList';
const OnGoingJobsPage = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const handleCollapseToggle = (collapsedState) => {
      setIsCollapsed(collapsedState);
    };
    return (
        <>
            <SidebarAdmin onCollapseToggle={handleCollapseToggle}/>
            <HeaderAdmin onCollapseToggle={handleCollapseToggle} isCollapsed={isCollapsed}/>
            <div className={`main-wrapper ${isCollapsed ? "collapsed" : ""}`}>
                <OngoingJobsList />
            </div>
        </>
    )
}

export default OnGoingJobsPage;