import React, { useState, useEffect } from "react";
import { Faqs } from "./Faqs";
import { RaiseATicket } from "./RaiseATicket";
import { FaPlus } from "react-icons/fa";
import { Modal, Form, Input, Select } from "antd";
import Instance from "../../../Instance";
import Loader from "../../../Loader";
import { showErrorMessage, showSuccessMessage } from "../../../globalConstant";
import { addNewTicket } from "../../../Admin/features/ticketSlice";
import { useDispatch } from "react-redux";

export const HelpAndSupport = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [admins, setAdmins] = useState([]);
  const [recruiters, setRecruiters] = useState([]);
  const [recruiterLeads, setRecruiterLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [formData, setFormData] = useState({});
  const [activeTab, setActiveTab] = useState("FAQ");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));

  const fetchAdminsData = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get("/getAllAdmins", {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        }
      });
      if (response.status === 200) {
        setAdmins(response?.data?.admins);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const fetchRecruitersData = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get("/getAllRecruiters", {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        }
      });
      if (response.status === 200) {
        let recruiter = response?.data?.recruiters?.filter((recruiter) => recruiter?.isLead === false);
        let recruiterLead = response?.data?.recruiters?.filter((recruiter) => recruiter?.isLead === true);
        setRecruiters(recruiter);
        setRecruiterLeads(recruiterLead);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchAdminsData();
    fetchRecruitersData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderTabs = () => {
    switch (activeTab) {
      case "FAQ":
        return <Faqs />;
      case "Raise Ticket":
        return <RaiseATicket />;
      default:
        return <Faqs />;
    }
  };

  const handleFormSubmit = async () => {
    try {
      if (!formData?.raisedFor || formData?.raisedFor?.trim() === '') {
        showErrorMessage("User is required!");
        return;
      }
      if (!formData?.raisedForRole || formData?.raisedForRole?.trim() === '') {
        showErrorMessage("User role is required!");
        return;
      }
      if (!formData?.title || formData?.title?.trim() === '') {
        showErrorMessage("Title is required!");
        return;
      }
      if (!formData?.priority || formData?.priority?.trim() === '') {
        showErrorMessage("Priority is required!");
        return;
      }
      if (!formData?.description || formData?.description?.trim() === '') {
        showErrorMessage("Description is required!");
        return;
      }

      setIsLoading(true);
      const response = await Instance.post("/createNewTicket", { ...formData }, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`
        }
      });

      if (response.status === 201) {
        setIsModalVisible(false);
        setFormData({});
        form.resetFields();
        dispatch(addNewTicket(response?.data?.newTicket));
        showSuccessMessage("Ticket raised successfully!");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const adminsOption = admins?.map((admin) => ({
    label: `${admin?.firstName} ${admin?.lastName}`,
    value: admin?._id
  }));

  const recruitersOption = recruiters?.map((recruiter) => ({
    label: `${recruiter?.firstName} ${recruiter?.lastName}`,
    value: recruiter?._id
  }));

  const recruiterLeadsOption = recruiterLeads?.map((recruiterLead) => ({
    label: `${recruiterLead?.firstName} ${recruiterLead?.lastName}`,
    value: recruiterLead?._id
  }));

  return (
    <>
      {isLoading && <Loader />}

      <div className="container">
        <div className="d-flex justify-content-between align-items-center mt-4">
          <div className="tab-group">
            {["FAQ", "Raise Ticket"].map((tab) => (
              <button
                key={tab}
                className={`tabs ${activeTab === tab ? "active" : ""}`}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </button>
            ))}
          </div>

          {activeTab === "Raise Ticket" && (
            <button className="create-btn" onClick={() => setIsModalVisible(true)}>
              <FaPlus /> {"  "}
              Create New Ticket
            </button>
          )}
        </div>

        <div className="mt-4">{renderTabs()}</div>
        <Modal
          title="Tickets"
          open={isModalVisible}
          onCancel={() => {
            setIsModalVisible(false);
            setFormData({});
            form.resetFields();
          }}
          footer={null}
        >
          <Form layout="vertical" onFinish={handleFormSubmit} className="mt-4" form={form}>
            <div className="col-lg-12 mb-2">
              <Form.Item name="role" label="Role">
                <Select placeholder="Select Role" options={[{ label: "Admin", value: "Admin" }, { label: "Recruiter", value: "Recruiter" }, { label: "Recruiter Lead", value: "Recruiter Lead" }]} onChange={(e) => {
                  setUserRole(e);
                  setFormData({ ...formData, raisedFor: "", raisedForRole: "" });
                  form.setFieldValue("user", null);
                }} />
              </Form.Item>
            </div>
            <div className="col-lg-12 mb-2">
              <Form.Item name="user" label="User">
                <Select disabled={userRole?.trim() === ''} placeholder="Select User" options={userRole === "Admin" ? adminsOption : userRole === "Recruiter" ? recruitersOption : recruiterLeadsOption} onChange={(e) => setFormData({ ...formData, raisedFor: e, raisedForRole: userRole === "Admin" ? "Admin" : "Recruiter" })} />
              </Form.Item>
            </div>
            <div className="col-lg-12 mb-2">
              <Form.Item label="Title" name="title">
                <Input placeholder="Enter Title" onChange={(e) => setFormData({ ...formData, title: e.target.value })} />
              </Form.Item>
            </div>
            <div className="col-lg-12 mb-2">
              <Form.Item name="priority" label="Priority">
                <Select placeholder="Select: Priority" options={[{ label: "Low", value: "Low" }, { label: "Medium", value: "Medium" }, { label: "High", value: "High" }]} onChange={(e) => setFormData({ ...formData, priority: e })} />
              </Form.Item>
            </div>
            <div className="col-lg-12 mb-2">
              <Form.Item label="Description" name="description">
                <Input.TextArea rows={4} placeholder="Write here..." onChange={(e) => setFormData({ ...formData, description: e.target.value })} />
              </Form.Item>
            </div>

            <div className="col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                type="button"
                onClick={() => {
                  setIsModalVisible(false);
                  setFormData({});
                  form.resetFields();
                }}
                className="cancel-btn"
                style={{ marginRight: "20px" }}
              >
                Cancel
              </button>
              <button className="create-btn" type="submit">
                Create
              </button>
            </div>
          </Form>
        </Modal>
      </div>
    </>
  );
};
