import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaBriefcase, FaEnvelope, FaLocationPin, FaPhone } from "react-icons/fa6";
import { IMG_URL } from "../../../globalConstant";
import DefaultUser from '../../Assets/Image/Recriuiter/DefaultUser.png';

const RecruiterCard = (detail) => {
    const navigate = useNavigate();
    return (
        <div className="col-lg-4">
            <div className="all-recruiter-card">
                <div className="all-recruiter-image">
                    <img src={detail?.profileImage ? `${IMG_URL}/${detail?.profileImage}` : DefaultUser} alt={"img"} />
                </div>
                <div className="all-recruiter-card-description">
                    <div className="d-flex justify-content-between mb-2">
                        <h4>{`${detail?.firstName} ${detail?.lastName}`}</h4>
                        <h5>
                            <FaBriefcase className="icon-red" /> {detail?.isLead ? "Lead" : "Recruiter"}
                        </h5>
                    </div>
                    <p>
                        <FaEnvelope className="icon-red" /> {detail?.email}
                    </p>
                    <p>
                        <FaPhone className="icon-red" /> {detail?.mobileNumber}
                    </p>
                    <p>
                        <FaLocationPin className="icon-red" /> {`${detail?.street}, ${detail?.city}, ${detail?.state}, ${detail?.zipCode}`}
                    </p>
                    <div className="d-flex justify-content-between mt-2">
                        <button className="profile-btn" onClick={() => navigate(`/admin/recruiter-profile/${detail?._id}`)}>Profile</button>
                        <button className="contact-btn" onClick={() => navigate(`/admin/edit-recruiter-profile/${detail?._id}`)}>Edit</button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default RecruiterCard;