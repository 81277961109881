import { configureStore } from "@reduxjs/toolkit";
import taskSlice from "./taskSlice";
import teamSlice from "./teamSlice";
import ticketSlice from "./ticketSlice";
import profileslice from "./profileSlice";
import applicationSlice from "./applicationSlice";
import faqSlice from "./faqSlice";
import referalClaimRequestSlice from "./referalClaimRequestSlice";
import notificationsSlice from "./notificationSlice";
import postsLice from "./postsSlice";
import generalApplicantsSlice from "./generalApplicantsSlice";

export default configureStore({
    reducer: {
        faqs: faqSlice,
        task: taskSlice,
        team: teamSlice,
        ticket: ticketSlice,
        profile: profileslice,
        application: applicationSlice,
        referalClaimRequests: referalClaimRequestSlice,
        notifications: notificationsSlice,
        posts: postsLice,
        generalApplications: generalApplicantsSlice
    },
});