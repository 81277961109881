import React,{useState} from 'react';
import SidebarRecruiter from '../../Layout/Sidebar/Index';
import HeaderRecruiter from '../../Layout/Header/Index';
import { TopClients } from '../../Components/Clients/TopClients';
const RecruiterTopClientsPage = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const handleCollapseToggle = (collapsedState) => {
      setIsCollapsed(collapsedState);
    };
    return (
        <>
            <SidebarRecruiter onCollapseToggle={handleCollapseToggle}/>
            <HeaderRecruiter onCollapseToggle={handleCollapseToggle} isCollapsed={isCollapsed}/>
            <div className={`main-wrapper ${isCollapsed ? "collapsed" : ""}`}>
                <TopClients />
            </div>
        </>
    )
}

export default RecruiterTopClientsPage;