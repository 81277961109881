import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../../Loader";
import Instance from "../../../Instance";
import { addDetailTeam } from "../../../Admin/features/teamSlice";
import { IMG_URL, showErrorMessage } from "../../../globalConstant";
import DefaultUser from '../../../Admin/Assets/Image/Recriuiter/DefaultUser.png';
import { IoIosArrowBack } from "react-icons/io";

export const MyTeam = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const teamData = useSelector((state) => state.team.detailTeam);
  const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));

  const fetchTeamDetail = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get(`/getRecruiterTeam/${loggedInUserInfo?.userId}`, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        },
      });
      if (response.status === 200) {
        dispatch(addDetailTeam(response?.data?.team));
      }
    } catch (error) {
      console.error(error);
      showErrorMessage('Failed to fetch team details');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTeamDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      title: "Members Name",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <img src={record?.memberId?.profileImage ? `${IMG_URL}/${record?.memberId?.profileImage}` : DefaultUser} alt='profile' width={30} height={30} style={{ borderRadius: "50%" }} />
          &nbsp;{`${record?.memberId?.firstName} ${record?.memberId?.lastName}`}
        </div>
      ),
    },
    {
      title: "Assigned Job Ids",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          {record?.memberId?.assignedJobs?.length === 0 && "N/A"}
          {record?.memberId?.assignedJobs?.map((item) => (
            <div className="job-id" key={item?._id}>
              {item?.jobId}
            </div>
          ))}
        </div>
      )
    },
    {
      title: "Total Assigned Jobs",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          {record?.memberId?.assignedJobs?.length}
        </div>
      ),
    }
  ];

  return (
    <>
      {isLoading && <Loader />}
      <div className="">
        <div className="container">
          <div className="row">
            <div className="d-flex justify-content-between align-items-center align-content-center">
              <div className="d-flex gap-4 align-items-center">
                <h2 className="">Team Details</h2>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="recruiter-team-details-card">
                <div className="recruiter-team-member-card-details">
                  <div className=" d-flex ">
                    <p>Team Name</p>
                    <span>: &nbsp; &nbsp;{teamData?.teamName}</span>
                  </div>
                  <div className=" d-flex ">
                    <p>Department</p>
                    <span>: &nbsp; &nbsp;{teamData?.departmentDivision}</span>
                  </div>
                  <div className=" d-flex  ">
                    <p>Descripton</p>
                    <span>: &nbsp; &nbsp;{teamData?.description || "N/A"}</span>
                  </div>
                  <div className=" d-flex  ">
                    <p>Team Lead</p>
                    <span>: &nbsp; &nbsp;{`${teamData?.teamLead?.firstName} ${teamData?.teamLead?.lastName}`}</span>
                  </div>
                  <div className=" d-flex  ">
                    <p>Total Members</p>
                    <span>: &nbsp; &nbsp;{teamData?.teamMembers?.length}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={teamData?.teamMembers}
            pagination={false}
            className="recruiter-applied-applicants-table mt-4"
            rowKey={(row) => row?._id}
          />
        </div>
        <button className="recruiter-create-btn mt-4" onClick={() => navigate(-1)}><IoIosArrowBack />
          Back</button>
      </div>
    </>
  );
};
