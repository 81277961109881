import React, { useState, useEffect, useMemo, useRef } from "react";
import { Input, Modal, Select, Dropdown, Menu, Table, Button, Tag, Form, DatePicker } from "antd";
import { TbArrowsSort } from "react-icons/tb";
import { IoEllipsisHorizontalSharp } from "react-icons/io5";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import { showErrorMessage, formatDate } from "../../../globalConstant";
import Instance from "../../../Instance";
import Loader from "../../../Loader";
import { useNavigate } from "react-router-dom";
const applicationStatus = [
  { label: "Draft", value: "Draft" },
  { label: "Applied", value: "Applied" },
  { label: "Review", value: "Review" },
  { label: "Submitted", value: "Submitted" },
  { label: "Approved", value: "Approved" },
  { label: "Schedule", value: "Schedule" },
  { label: "Offer", value: "Offer" },
  { label: "Onboard", value: "Onboard" },
  { label: "Hired", value: "Hired" },
  { label: "Closed", value: "Closed" }
];



export const TotalApplicants = () => {
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [recruitersList, setRecruitersList] = useState([]);
  const [applications, setApplications] = useState([]);
  const [isAscendingSort, setIsAcendingSort] = useState(false);
  const [filterParameters, setFilterParameters] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));
  const navigate=useNavigate();
  const statusRef = useRef(null);
  const appliedDateRef = useRef(null);
  const recruiterRef = useRef(null);

  const scrollToField = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const fetchApplicantsData = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get(`/getAllApplications`, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        }
      });
      if (response.status === 200) {
        let dataSource = response?.data?.applications?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setApplications(dataSource);
        setDataSource(dataSource);
      }
    } catch (error) {
      console.error(error);
      showErrorMessage('Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRecruiterData = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get("/getAllRecruiters", {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        },
      });
      if (response?.status === 200) {
        setRecruitersList(response?.data?.recruiters);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchRecruiterData();
    fetchApplicantsData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const tableData = useMemo(() => {
    if (searchText?.trim() === '') {
      return dataSource;
    } else {
      return dataSource?.filter((item) => {
        return `${item?.basicInfo?.firstName?.toLowerCase()}${item?.basicInfo?.lastName?.toLowerCase()}${item?.basicInfo?.email?.toLowerCase()}`?.includes(searchText?.toLowerCase());
      })
    }
  }, [dataSource, searchText]);

  const columns = [
    {
      title: "Applicant Name",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          {record?.basicInfo?.firstName} {record?.basicInfo?.lastName}
        </div>
      ),
    },
    {
      title: "Email",
      render: (text, record) => (
        <div>{record?.basicInfo?.email}</div>
      )
    },
    {
      title: "Mobile Number",
      render: (text, record) => (
        <div>{record?.basicInfo?.mobileNumber}</div>
      )
    },
    {
      title: "Applied Date",
      render: (text, record) => (
        <div>{formatDate(record?.createdAt)}</div>
      )
    },
    {
      title: "Job Id",
      render: (text, record) => (
        <div>{record?.manualJobId || 'N/A'}</div>
      )
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <Tag className="applicant-status-tag">
          {record?.status}
        </Tag>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        const actionMenu = (
          <Menu>
            <Menu.Item
              key="view"
              onClick={() => navigate(`/admin/view-applicants/${record?._id}`)}
            >
              View Details
            </Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={actionMenu} trigger={['click']}>
            <Button type="text" icon={<IoEllipsisHorizontalSharp />} />
          </Dropdown>
        );
      },
    }
  ];

  const handleSorting = () => {
    try {
      let sortedData;
      if (isAscendingSort) {
        sortedData = [...dataSource]?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      } else {
        sortedData = [...dataSource]?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      }
      setDataSource(sortedData);
      setIsAcendingSort(!isAscendingSort);
    } catch (error) {
      console.error(error);
    }
  };

  const handleApplyFilters = () => {
    try {
      setIsLoading(true);
      let filterData = applications;
      if (filterParameters.status && filterParameters.status.length > 0) {
        filterData = filterData?.filter((item) => filterParameters?.status?.includes(item?.status));
      }
      if (filterParameters.recruiter && filterParameters.recruiter.length > 0) {
        let reqJobIds = [];
        filterParameters?.recruiter?.forEach((recruiter) => {
          let jobsId = JSON.parse(recruiter).assignedJobs?.map(item => item.jobId);
          reqJobIds = [...reqJobIds, ...jobsId];
        });
        filterData = filterData?.filter((item) => reqJobIds?.includes(item?.manualJobId));
      }
      if (filterParameters?.startDate) {
        let date = new Date(filterParameters?.startDate);
        filterData = filterData?.filter(item => {
          let curDate = new Date(item?.createdAt);
          if (curDate.getFullYear() > date.getFullYear()) return true;
          else if (curDate.getFullYear() === date.getFullYear()) {
            if (curDate.getMonth() > date.getMonth()) return true;
            else if (curDate.getMonth() === date.getMonth()) {
              if (curDate.getDate() >= date.getDate()) return true;
            }
          }
          return false;
        });
      }
      if (filterParameters?.endDate) {
        let date = new Date(filterParameters?.endDate);
        filterData = filterData?.filter(item => {
          let curDate = new Date(item?.createdAt);
          if (curDate.getFullYear() < date.getFullYear()) return true;
          else if (curDate.getFullYear() === date.getFullYear()) {
            if (curDate.getMonth() < date.getMonth()) return true;
            else if (curDate.getMonth() === date.getMonth()) {
              if (curDate.getDate() <= date.getDate()) return true;
            }
          }
          return false;
        });
      }
      setDataSource(filterData);
    } catch (error) {
      console.error(error);
    } finally {
      setIsModalVisible(false);
      setIsLoading(false);
    }
  };

  const handleModalClose = () => {
    form.resetFields();
    setFilterParameters({});
    setIsModalVisible(false);
    setDataSource(applications);
  }

  const recruiterOptions = recruitersList?.map((recruiter) => ({
    label: `${recruiter?.firstName} ${recruiter?.lastName}`,
    value: JSON.stringify(recruiter)
  }));

  return (
    <>
      {isLoading && <Loader />}
      <div className="all-recruiter-section">
        <div>
          <div className="row">
            <div className="d-flex justify-content-between align-items-center align-content-center mb-4">
              <div className="d-flex gap-4 align-items-center">
                <h2>Total Applicants</h2>
                <Input
                  placeholder="Search..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  className="search-input-table"
                  prefix={<SearchOutlined />}
                />
              </div>
              <div className="search-table-container gap-2">
                <button className="table-action-btn" onClick={handleSorting}>
                  <span>Sort</span>{" "}
                  <TbArrowsSort style={{ fontWeight: "bold", fontSize: "16px" }} />
                </button>
                <button className="table-action-btn" onClick={() => setIsModalVisible(true)}>
                  <FilterOutlined style={{ fontWeight: "bold", fontSize: "16px" }} />{" "}
                  <span>Filter</span>
                </button>
              </div>
            </div>
          </div>

          <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            className="applied-applicants-table"
            rowKey={(row) => row?._id}
          />
        </div>
      </div>
      <Modal
        title="Filter"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        className="filter-modal"
        footer={null}
      >
        <div className="filter-tabs">
          <Button onClick={() => scrollToField(statusRef)}>
            Status
          </Button>
          <Button onClick={() => scrollToField(appliedDateRef)}>
            Applied Date
          </Button>
          <Button onClick={() => scrollToField(recruiterRef)}>
            Recruiter
          </Button>
        </div>
        <div className="filter-form mt-4">
          <Form layout="vertical " className="filter-form" form={form}>
            <div className="col-md-12" ref={statusRef}>
              <Form.Item
                label="Status"
                name={"status"}
              >
                <Select placeholder="Select Status" options={applicationStatus} onChange={(e) => setFilterParameters({ ...filterParameters, status: e })} mode="multiple" />
              </Form.Item>
            </div>
            <div className="row col-md-12" ref={appliedDateRef}>
              <div className="col-lg-6">
                <Form.Item
                  label="From"
                  name={"startDate"}
                >
                  <DatePicker
                    placeholder="Select Date"
                    format="DD/MM/YYYY"
                    className="form-control"
                    onChange={(e) => setFilterParameters({ ...filterParameters, startDate: e })}
                  />
                </Form.Item>
              </div>
              <div className="col-lg-6">
                <Form.Item
                  label="To"
                  name={"endDate"}
                >
                  <DatePicker
                    placeholder="Select Date"
                    format="DD/MM/YYYY"
                    className="form-control"
                    onChange={(e) => setFilterParameters({ ...filterParameters, endDate: e })}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="col-md-12" ref={recruiterRef}>
              <Form.Item
                label="Recruiter"
                name={"recruiter"}
              >
                <Select placeholder="Select Recruiter" options={recruiterOptions} onChange={(e) => setFilterParameters({ ...filterParameters, recruiter: e })} mode="multiple" />
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className="modal-actions d-flex justify-content-between">
          <button onClick={handleModalClose} className="cancel-btn" type="button">
            Clear All
          </button>
          <button className="create-btn" onClick={handleApplyFilters} type="button">
            Apply Filters
          </button>
        </div>
      </Modal>
    </>
  );
};
