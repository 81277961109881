import React,{useState} from 'react';
import SidebarRecruiterLead from '../../Layout/Sidebar/Index';
import HeaderRecruiterLead from '../../Layout/Header/Index';
import Notification from '../../Components/Notification/Notifications';
const RecruiterLeadNotificationPage = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const handleCollapseToggle = (collapsedState) => {
      setIsCollapsed(collapsedState);
    };
  
    return (
        <>
              <SidebarRecruiterLead onCollapseToggle={handleCollapseToggle}/>
              <HeaderRecruiterLead onCollapseToggle={handleCollapseToggle} isCollapsed={isCollapsed}/>
            <div className={`main-wrapper ${isCollapsed ? "collapsed" : ""}`}>
                <Notification/>
            </div>
        </>
    )
}

export default RecruiterLeadNotificationPage;