import React, { useEffect, useState } from "react";
import { Modal, Table, Form, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../Loader";
import Instance from "../../../Instance";
import { addDetailTeam, removeMemberFromTeam } from "../../../Admin/features/teamSlice";
import { IMG_URL, showSuccessMessage, showErrorMessage } from "../../../globalConstant";
import DefaultUser from '../../../Admin/Assets/Image/Recriuiter/DefaultUser.png';
import { IoIosArrowBack } from "react-icons/io";

export const MyTeam = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [members, setMembers] = useState([]);
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const teamData = useSelector((state) => state.team.detailTeam);
  const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));

  const fetchTeamDetail = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get(`/getRecruiterTeam/${loggedInUserInfo?.userId}`, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        },
      });
      if (response.status === 200) {
        dispatch(addDetailTeam(response?.data?.team));
      }
    } catch (error) {
      console.error(error);
      showErrorMessage('Failed to fetch team details');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMembers = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get(`/getAllMembers`, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`
        }
      });
      if (response.status === 200) {
        let data = response?.data?.members?.filter((member) => member?.assignedJobs?.length === 0);
        setMembers(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMembers();
    fetchTeamDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      title: "Members Name",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <img src={record?.memberId?.profileImage ? `${IMG_URL}/${record?.memberId?.profileImage}` : DefaultUser} alt='profile' width={30} height={30} style={{ borderRadius: "50%" }} />
          &nbsp;{`${record?.memberId?.firstName} ${record?.memberId?.lastName}`}
        </div>
      ),
    },
    {
      title: "Assigned Job Ids",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          {record?.memberId?.assignedJobs?.length === 0 && "N/A"}
          {record?.memberId?.assignedJobs?.map((item) => (
            <div className="job-id" key={item?._id}>
              {item?.jobId}
            </div>
          ))}
        </div>
      )
    },
    {
      title: "Total Assigned Jobs",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          {record?.memberId?.assignedJobs?.length}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <button className="recruiter-lead-team-details-action-button" onClick={() => {
          if (record?.memberId?.assignedJobs?.length !== 0) {
            showErrorMessage('Cannot remove member with assigned jobs. Please remove assigned jobs first');
            return;
          }
          handleRemoveMemberFromTeam(record?.memberId?._id);
        }}>
          Remove
        </button>
      ),
    },
  ];

  const handleFormSubmit = async () => {
    try {
      setIsLoading(true);
      for (let i = 0; i < formData?.members?.length; i++) {
        let member = JSON.parse(formData?.members[i]);
        formData.members[i] = {
          memberId: member?._id,
          role: member?.role === 'ADMIN' ? 'Admin' : 'Recruiter'
        }
      }
      const response = await Instance.post(`/addMembersToTeam/${teamData?._id}`, formData, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`
        }
      });
      if (response.status === 200) {
        dispatch(addDetailTeam(response?.data?.updatedTeam));
        showSuccessMessage('Members added successfully');
      }
    } catch (error) {
      showErrorMessage('Failed to add members');
    } finally {
      setIsLoading(false);
      handleClose();
    }
  }

  const handleRemoveMemberFromTeam = async (memberId) => {
    try {
      setIsLoading(true);
      const response = await Instance.put(`/removeMemberFromTeam/${teamData?._id}/${memberId}`, {},{
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`
        }
      });
      if (response.status === 200) {
        dispatch(removeMemberFromTeam(memberId));
        showSuccessMessage('Member removed successfully');
      }
    } catch (error) {
      showErrorMessage('Failed to remove member');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleClose = () => {
    setFormData({});
    form.resetFields();
    setIsModalVisible(false);
  }

  const memberOptions = members?.map((item) => ({
    label: `${item?.firstName} ${item?.lastName}`,
    value: JSON.stringify(item)
  }));

  return (
    <>
      {isLoading && <Loader />}
      <div className="">
        <div className="container">
          <div className="row">
            <div className="d-flex justify-content-between align-items-center align-content-center">
              <div className="d-flex gap-4 align-items-center">
                <h2 className="">Team Details</h2>
              </div>
              <div className="recruiter-lead-search-table-container gap-2">
                <div>
                  <button className="recruiter-lead-dashboard-btn" onClick={() => setIsModalVisible(true)}>
                    + Add Member
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="recruiter-lead-team-details-card">
                <div className="recruiter-lead-team-member-card-details">
                  <div className=" d-flex ">
                    <p>Team Name</p>
                    <span>: &nbsp; &nbsp;{teamData?.teamName}</span>
                  </div>
                  <div className=" d-flex ">
                    <p>Department</p>
                    <span>: &nbsp; &nbsp;{teamData?.departmentDivision}</span>
                  </div>
                  <div className=" d-flex  ">
                    <p>Descripton</p>
                    <span>: &nbsp; &nbsp;{teamData?.description || "N/A"}</span>
                  </div>
                  <div className=" d-flex  ">
                    <p>Team Lead</p>
                    <span>: &nbsp; &nbsp;{`${teamData?.teamLead?.firstName} ${teamData?.teamLead?.lastName}`}</span>
                  </div>
                  <div className=" d-flex  ">
                    <p>Total Members</p>
                    <span>: &nbsp; &nbsp;{teamData?.teamMembers?.length}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={teamData?.teamMembers}
            pagination={false}
            className="recruiter-lead-applied-applicants-table mt-4"
            rowKey = "_id"
          />
        </div>
        <button className="recruiter-lead-create-btn mt-4" onClick={() => navigate(-1)}><IoIosArrowBack />
        Back</button>
      </div>
      <Modal
        title="Add Members"
        open={isModalVisible}
        onCancel={handleClose}
        footer={null}
      >
        <Form layout="vertical" form={form} className="mt-4" onFinish={handleFormSubmit}>
          <div className="row">
            <div className="col-lg-12">
              <Form.Item name="members" label="Team Members" rules={[{ required: true, message: "Please select team members" }]}>
                <Select placeholder="Select Team Members" options={memberOptions} onChange={(e) => setFormData({ ...formData, members: e })} mode="multiple" />
              </Form.Item>
            </div>
          </div>
          <div className="form-actions" style={{ display: "flex", justifyContent: "flex-end" }} >
            <button type="button" className="recruiter-lead-cancel-btn" style={{ marginRight: "20px" }} onClick={handleClose}>
              Cancel
            </button>
            <button className="recruiter-lead-create-btn" type="submit">
              Add +
            </button>
          </div>
        </Form>
      </Modal>
    </>
  );
};
