import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Input } from "antd";
import { useSelector, useDispatch } from "react-redux";
import Instance from "../../../Instance";
import Loader from "../../../Loader";
import { formatDate, showErrorMessage, showSuccessMessage } from "../../../globalConstant";
import { setClaimRequests, approveClaimRequest, rejectClaimRequest } from "../../features/referalClaimRequestSlice";
import { Dropdown, Menu } from 'antd';
import { IoEllipsisHorizontalSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import ViewReferralModal from "./ViewReferral";
const ReferralProgram = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [adminComment, setAdminComment] = useState('');
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [isApprove, setIsApprove] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));
    const claimRequests = useSelector((state) => state?.referalClaimRequests?.claimRequests);
    const [isDetailModalVisible, setIsDetailModalVisible] = useState(false); 
    const navigate = useNavigate();
    const menu = (record) => (
        <Menu>
            <Menu.Item key="view"  onClick={() => handleView(record)}>
                View
            </Menu.Item>
            {record?.status === 'PENDING' && (
                <>
                    <Menu.Item key="approve">
                        <button
                            className="btn btn-success btn-sm w-100"
                            onClick={() => {
                                setSelectedRequest(record);
                                setIsModalVisible(true);
                                setIsApprove(true);
                            }}
                        >
                            Approve
                        </button>
                    </Menu.Item>
                    <Menu.Item key="reject">
                        <button
                            className="btn btn-danger btn-sm w-100"
                            onClick={() => {
                                setSelectedRequest(record);
                                setIsModalVisible(true);
                            }}
                        >
                            Reject
                        </button>
                    </Menu.Item>
                </>
            )}
        </Menu>
    );
    const handleView = () => {
        setIsDetailModalVisible(true);
    };

    const handleCloseViewModal = () => {
        setIsDetailModalVisible(false);
    };

    const fetchClaimRequests = async () => {
        try {
            setIsLoading(true);
            const response = await Instance.get('/getAllClaimRequests', {
                headers: {
                    'Authorization': `Bearer ${loggedInUserInfo?.token}`
                }
            });

            if (response.status === 200) {
                console.log(response.data.claimRequests);
                dispatch(setClaimRequests(response?.data?.claimRequests));
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchClaimRequests();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const columns = [
        {
            title: 'S.No',
            render: (text, record, index) => (
                <div className="d-flex gap-2">
                    {index + 1}
                </div>
            ),
        },
        {
            title: 'User Name',
            render: (text, record) => (
                <div>
                    {record?.userId?.firstName} {record?.userId?.lastName}
                </div>
            ),
        },
        {
            title: 'Amount',
            render: (text, record) => (
                <>
                    <div style={{ color: '#888' }}>$ {record.amount}</div>
                </>
            ),
        },
        {
            title: 'Status',
            render: (text, record) => (
                <div>{record?.status === 'PENDING' ? 'Pending' : record?.status === 'APPROVED' ? 'Approved' : 'Rejected'}</div>
            ),
        },
        {
            title: 'Details Submission',
            render: (text, record) => (
                <div>{record?.detailsSubmission}</div>
            ),
        },
        {
            title: 'Date',
            render: (text, record) => (
                <div>{formatDate(record?.createdAt)}</div>
            ),
        },
        {
            title: 'Action',
            render: (text, record) => (
                <Dropdown overlay={menu(record)} trigger={['click']}>
                    <IoEllipsisHorizontalSharp size={20} style={{ cursor: 'pointer' }} />
                </Dropdown>
            ),
        }
        
    ];

    const approveClaimRequestFun = async () => {
        try {
            if (!selectedRequest) return;
            setIsLoading(true);
            const response = await Instance.put(`/approveClaimRequest/${selectedRequest?._id}`, { adminComment }, {
                headers: {
                    'Authorization': `Bearer ${loggedInUserInfo?.token}`
                }
            });
            if (response.status === 200) {
                dispatch(approveClaimRequest(selectedRequest?._id));
                showSuccessMessage('Claim request approved successfully');
                handleModalCancel();
            }
        } catch (error) {
            console.error(error);
            showErrorMessage(error?.response?.data?.message || 'Failed to Approve. Try again!');
        } finally {
            setIsLoading(false);
        }
    }

    const rejectClaimRequestFun = async () => {
        try {
            if (!selectedRequest) return;
            setIsLoading(true);
            const response = await Instance.put(`/rejectClaimRequest/${selectedRequest?._id}`, { adminComment }, {
                headers: {
                    'Authorization': `Bearer ${loggedInUserInfo?.token}`
                }
            });
            if (response.status === 200) {
                dispatch(rejectClaimRequest(selectedRequest?._id));
                showSuccessMessage('Claim request rejected successfully');
                handleModalCancel();
            }
        } catch (error) {
            console.error(error);
            showErrorMessage(error?.response?.data?.message || 'Failed to Reject. Try again!');
        } finally {
            setIsLoading(false);
        }
    }

    const handleModalCancel = () => {
        setIsModalVisible(false);
        setAdminComment('');
        setSelectedRequest(null);
        setIsApprove(false);
        form.resetFields();
    }

    return (<>
        {isLoading && <Loader />}
        <div>
            <h3>Bonus Claim Requests</h3>
            <Table columns={columns} dataSource={claimRequests} />
        </div>
        <Modal visible={isModalVisible} onCancel={handleModalCancel} title={isApprove ? "Approve Claim Request" : "Reject Claim Request"} footer={null}>
            <Form onFinish={isApprove ? approveClaimRequestFun : rejectClaimRequestFun} layout="vertical" form={form}>
                <Form.Item label="Admin Comment" name={"adminComment"} rules={[{ required: true }]}>
                    <Input.TextArea value={adminComment} onChange={(e) => setAdminComment(e.target.value)} />
                </Form.Item>
                <Button htmlType="submit">
                    Submit
                </Button>
                <Button onClick={handleModalCancel}>
                    Cancel
                </Button>
            </Form>
        </Modal>
        <ViewReferralModal 
                visible={isDetailModalVisible} 
                onCancel={handleCloseViewModal} 
                
            />
    </>)
}

export default ReferralProgram;