import React, { useEffect, useMemo, useState } from "react";
import image1 from "../../Assets/Image/Recriuiter/create-job-1.png";
import image2 from "../../Assets/Image/Recriuiter/create-job-2.png";
import { Radio, Input, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Instance from "../../../Instance";
import { formatDate } from "../../../globalConstant";
import Loader from "../../../Loader";

export const CreateJob = () => {
  const navigate = useNavigate();
  const [jobs, setJobs] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [jobAdditionType, setJobAdditionType] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState(null);
  const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));

  const columns = [
    {
      title: 'Title',
      width: "35%",
      render: (text, record) => (
        <div>{record?.title}</div>
      )
    },
    {
      title: 'Category',
      width: "20%",
      render: (text, record) => (
        <div>{record?.jobCategory}</div>
      )
    },
    {
      title: 'Posted Date',
      width: "20%",
      render: (text, record) => (
        <div>{formatDate(record?.jobPostedDate)}</div>
      )
    },
    {
      title: 'Location',
      width: "25%",
      render: (text, record) => (
        <div>{record?.cities?.join(', ')}{record?.cities?.length > 0 && ", "}{record?.stateCode}</div>
      )
    }
  ];

  const fetchJobDetail = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get("/getAllJobs", {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        }
      });
      if (response.status === 200) {
        let dataSource = response?.data?.jobs;
        dataSource = dataSource?.sort((a, b) => new Date(b.jobPostedDate) - new Date(a.jobPostedDate));
        setJobs(dataSource);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchJobDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const dataSource = useMemo(() => {
    if (!searchText?.trim()) return jobs;
    return jobs?.filter((item) => `${item?.jobCategory} ${item?.title}`.toLowerCase()?.includes(searchText?.toLowerCase()));
  }, [searchText, jobs]);

  return (
    <>
      {isLoading && <Loader />}
      <div className="create-job-section">
        <div className="container">
          <div className="row create-job-banner">
            <div className="col-lg-3">
              <img src={image1} className="create-job-image" alt="" />
            </div>
            <div className="col-lg-6 create-job-heading">
              <h3>Create a Job Post</h3>
              <div className="create-job-underline"></div>
              <p>
                Seeking dedicated and compassionate nurses to join our dynamic
                healthcare team!
              </p>
            </div>
            <div className="col-lg-3">
              <img src={image2} className="create-job-image" alt="" />
            </div>
          </div>
          <div className="row create-job-radio-div mt-4">
            <h4>How would you like to post your job? *</h4>
            <Radio.Group value={jobAdditionType}>
              <div
                className={`create-job-radio-options mt-3 ${jobAdditionType === 1 ? "selected" : ""
                  }`}
                onClick={() => setJobAdditionType(1)}
              >
                <Radio value={1}>
                  <span>
                    Use a previous job as a template, copy a past job post and
                    edit as needed.
                  </span>
                </Radio>
              </div>
              <div
                className={`create-job-radio-options mt-3 ${jobAdditionType === 3 ? "selected" : ""
                  }`}
                onClick={() => setJobAdditionType(3)}
              >
                <Radio value={3}>
                  <span>
                    Start with a new job post. Use our job posting tool to create your
                    job.
                  </span>
                </Radio>
              </div>
            </Radio.Group>
          </div>
          {jobAdditionType === 1 && <>
            <div className="row mt-4">
              <Input
                style={{ padding: "14px" }}
                placeholder="Search Job Title or Category"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                prefix={<SearchOutlined />}
              />
            </div>
            <div className="row mt-4 create-jobs-list">
              <Table
                className="create-all-jobs-table"
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                rowKey={(row) => row?._id}
                rowSelection={{
                  type: 'radio',
                  selectedRowKeys,
                  onChange: (selectedRowKeys) => {
                    setSelectedRowKeys(selectedRowKeys)
                  },
                }}
              />
            </div>
          </>}
          <div className="d-flex justify-content-end mt-4">
            <button
              className="continue-btn"
              disabled={jobAdditionType === 1 && !selectedRowKeys}
              style={{ cursor: jobAdditionType === 1 && !selectedRowKeys ? "not-allowed" : "pointer" }}
              onClick={() => {
                switch (jobAdditionType) {
                  case 1:
                    navigate("/admin/create-template-job", {
                      state: { jobId: selectedRowKeys[0] },
                    });
                    break;
                  case 2:
                    break;
                  case 3:
                    navigate("/admin/create-new-job");
                    break;
                  default:
                    break;
                }
              }}
            >
              Continue &nbsp;
              <IoIosArrowForward />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
