import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../layout.css";
import logo from "../../Assets/Logo/logo.png";
import {
  FiGrid,
  FiUsers,
  FiSettings,
  FiHelpCircle,
  FiMenu,
  FiX,
  FiChevronDown,
  FiChevronRight,
  FiLogOut,
} from "react-icons/fi";
import {
  FaBookOpenReader,
  FaCircleUser,
  FaDatabase,
  FaHandHolding,
  FaIdCardClip,
  FaRegPenToSquare,
  FaTeamspeak,
} from "react-icons/fa6";
import { FaTasks } from "react-icons/fa";
import Swal from "sweetalert2";
import logoutImg from "../../Assets/Logo/logo.png";
import { IoMdClose } from "react-icons/io";
import { IoMenu } from "react-icons/io5";

function SidebarAdmin({ onCollapseToggle }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [expandedMenu, setExpandedMenu] = useState(null);
  const toggleCollapse = () => {
    const newCollapsedState = !isCollapsed;
    setIsCollapsed(newCollapsedState);
    onCollapseToggle(newCollapsedState); // Notify parent component
  };

  const toggleSidebar = () => {
    console.log("Toggle Sidebar Clicked");
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const toggleMenu = (menu) => {
    // If the sidebar is collapsed, open it first
    if (isCollapsed) {
      setIsCollapsed(false);
      onCollapseToggle(false); // Notify parent component if needed
    }
    // Toggle the expanded menu
    setExpandedMenu(expandedMenu === menu ? null : menu);
  };
  
  useEffect(() => {
    const pathToMenuMap = {
      "/admin/add-recruiter": "recruiters",
      "/admin/all-recruiters": "recruiters",
      "/admin/create-job": "jobs",
      "/admin/ongoing-jobs": "jobs",
      "/admin/closed-jobs": "jobs",
      "/admin/my-jobs": "jobs",
      "/admin/add-new-client": "clients",
      "/admin/top-clients": "clients",
      "/admin/total-jobs": "database",
      "/admin/total-applicants": "database",
      "/admin/total-clients": "database",
      "/admin/all-teams": "teams",
      "/team-chat": "teams",
      "/admin/tasks": "tasks",
      "/team-reports": "reports",
      "/user-reports": "reports",
      "/admin/settings": "settings",
    };

    const currentPath = location.pathname;
    const activeMenu = pathToMenuMap[currentPath];

    setExpandedMenu(activeMenu);
  }, [location.pathname]);

  const handlelogout = () => {
    Swal.fire({
      imageUrl: logoutImg,
      imageWidth: "160px",
      title:
        'Are you sure You want to <span style="color: var(--maroon-color);">Logout?</span>',
      showCancelButton: true,
      confirmButtonColor: "#555",
      cancelButtonColor: "#ce1b28",
      confirmButtonText: "Yes, logout me!",
      customClass: {
        title: "swal-title",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("loggedInUserInfo");
        navigate("/");
      }
    });
  };

  return (
    <>
      <div className="toggle-btn" onClick={toggleSidebar}>
        <FiMenu size={26} />
      </div>
      <div
  className={`sidebar ${isSidebarOpen ? "open" : ""} ${
    isCollapsed ? "collapsed" : ""
  }`}
>
  <aside
    className={`sidebar-content ${isSidebarOpen ? "open" : ""} ${
      isCollapsed ? "collapsed" : ""
    }`}
  >
    {/* Sidebar Overlay for mobile view */}
    {isSidebarOpen && (
      <div className="overlay overlay-open" onClick={closeSidebar}></div>
    )}

    <div className="sidebar-header d-flex justify-content-between align-items-center">
      {!isCollapsed && (
        <img
          style={{ cursor: "pointer" }}
          src={logo}
          alt="BILKINS"
          className="sidebar-logo"
        />
      )}
      <button
        className="collapse-btn d-none d-lg-block"
        onClick={toggleCollapse}
      >
        {isCollapsed ? <IoMenu /> : <IoMdClose />}
      </button>
      <div className=" d-lg-none" onClick={closeSidebar}>
        <IoMdClose size={26} />
      </div>
    </div>
          <nav className="sidebar-nav">
            <ul>
              <li>
                <Link
                  to="/admin/dashboard"
                  className={`nav-link ${
                    location.pathname === "/admin/dashboard"
                      ? "active-nav-links"
                      : ""
                  }`}
                >
                  <FiGrid className="sidebar-icon" />
                  {!isCollapsed && "Dashboard"}
                </Link>
              </li>
              <li>
                <div
                  className={`nav-link ${
                    expandedMenu === "recruiters" ? "active-nav-links" : ""
                  }`}
                  onClick={() => toggleMenu("recruiters")}
                >
                  <span className="d-flex align-items-center justify-content-between">
                    <span className="d-flex align-items-center">
                      <FiUsers className="sidebar-icon" />
                      <span> {!isCollapsed && "Recruiters"}</span>
                    </span>
                    {expandedMenu === "recruiters" ? (
                      <FiChevronDown className="dropdown-icon" />
                    ) : (
                      <FiChevronRight className="dropdown-icon" />
                    )}
                  </span>
                </div>
                <ul
                  className={`sub-menu ${
                    expandedMenu === "recruiters" ? "active" : ""
                  }`}
                >
                  <li className="sub-nav-list">
                    <Link to="/admin/add-recruiter" className="sub-nav-link">
                      Add Recruiter
                    </Link>
                  </li>
                  <li className="sub-nav-list">
                    <Link to="/admin/all-recruiters" className="sub-nav-link">
                      All Recruiter
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <div
                  className={`nav-link ${
                    expandedMenu === "jobs" ? "active-nav-links" : ""
                  }`}
                  onClick={() => toggleMenu("jobs")}
                >
                  <span className="d-flex align-items-center justify-content-between">
                    <span className="d-flex align-items-center">
                      <FaBookOpenReader className="sidebar-icon" />
                      <span> {!isCollapsed && "Jobs"}</span>
                    </span>
                    {expandedMenu === "jobs" ? (
                      <FiChevronDown className="dropdown-icon" />
                    ) : (
                      <FiChevronRight className="dropdown-icon" />
                    )}
                  </span>
                </div>
                <ul
                  className={`sub-menu ${
                    expandedMenu === "jobs" ? "active" : ""
                  }`}
                >
                  <li className="sub-nav-list">
                    <Link to="/admin/create-job" className="sub-nav-link">
                      Add New Job
                    </Link>
                  </li>
                  <li className="sub-nav-list">
                    <Link to="/admin/ongoing-jobs" className="sub-nav-link">
                      Ongoing Jobs
                    </Link>
                  </li>
                  <li className="sub-nav-list">
                    <Link to="/admin/paused-jobs" className="sub-nav-link">
                      Paused Jobs
                    </Link>
                  </li>
                  <li className="sub-nav-list">
                    <Link to="/admin/closed-jobs" className="sub-nav-link">
                      Closed Jobs
                    </Link>
                  </li>
                  <li className="sub-nav-list">
                    <Link to="/admin/my-jobs" className="sub-nav-link">
                      My Jobs
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link
                  to="/admin/applicants"
                  className={`nav-link ${
                    location.pathname === "/admin/applicants"
                      ? "active-nav-links"
                      : ""
                  }`}
                >
                  <FaCircleUser className="sidebar-icon" />{" "}
                  {!isCollapsed && "Applicants"}
                </Link>
              </li>

              <li>
                <div
                  className={`nav-link ${
                    expandedMenu === "clients" ? "active-nav-links" : ""
                  }`}
                  onClick={() => toggleMenu("clients")}
                >
                  <span className="d-flex align-items-center justify-content-between">
                    <span className="d-flex align-items-center">
                      <FaIdCardClip className="sidebar-icon" />
                      <span>{!isCollapsed && "Clients"}</span>
                    </span>
                    {expandedMenu === "clients" ? (
                      <FiChevronDown className="dropdown-icon" />
                    ) : (
                      <FiChevronRight className="dropdown-icon" />
                    )}
                  </span>
                </div>
                <ul
                  className={`sub-menu ${
                    expandedMenu === "clients" ? "active" : ""
                  }`}
                >
                  <li className="sub-nav-list">
                    <Link to="/admin/add-new-client" className="sub-nav-link">
                      Add New Client
                    </Link>
                  </li>
                  <li className="sub-nav-list">
                    <Link to="/admin/top-clients" className="sub-nav-link">
                      Top Client
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <div
                  className={`nav-link ${
                    expandedMenu === "database" ? "active-nav-links" : ""
                  }`}
                  onClick={() => toggleMenu("database")}
                >
                  <span className="d-flex align-items-center justify-content-between">
                    <span className="d-flex align-items-center">
                      <FaDatabase className="sidebar-icon" />
                      <span> {!isCollapsed && "Database"}</span>
                    </span>
                    {expandedMenu === "database" ? (
                      <FiChevronDown className="dropdown-icon" />
                    ) : (
                      <FiChevronRight className="dropdown-icon" />
                    )}
                  </span>
                </div>
                <ul
                  className={`sub-menu ${
                    expandedMenu === "database" ? "active" : ""
                  }`}
                >
                  <li className="sub-nav-list">
                    <Link to="/admin/total-jobs" className="sub-nav-link">
                      Total Jobs
                    </Link>
                  </li>
                  <li className="sub-nav-list">
                    <Link to="/admin/total-applicants" className="sub-nav-link">
                      Total Applicants
                    </Link>
                  </li>
                  <li className="sub-nav-list">
                    <Link to="/admin/total-clients" className="sub-nav-link">
                      Total Clients
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link
                  to="/admin/tasks"
                  className={`nav-link ${
                    location.pathname === "/admin/tasks"
                      ? "active-nav-links"
                      : ""
                  }`}
                >
                  <FaTasks className="sidebar-icon" /> {!isCollapsed && "Tasks"}
                </Link>
              </li>

              <li>
                <div
                  className={`nav-link ${
                    expandedMenu === "reports" ? "active-nav-links" : ""
                  }`}
                  onClick={() => toggleMenu("reports")}
                >
                  <span className="d-flex align-items-center justify-content-between">
                    <span className="d-flex align-items-center">
                      <FaRegPenToSquare className="sidebar-icon" />
                      <span> {!isCollapsed && "Reports"}</span>
                    </span>
                    {expandedMenu === "reports" ? (
                      <FiChevronDown className="dropdown-icon" />
                    ) : (
                      <FiChevronRight className="dropdown-icon" />
                    )}
                  </span>
                </div>
                <ul
                  className={`sub-menu ${
                    expandedMenu === "reports" ? "active" : ""
                  }`}
                >
                  <li className="sub-nav-list">
                    <Link to="/team-reports" className="sub-nav-link">
                      Team Reports
                    </Link>
                  </li>
                  <li className="sub-nav-list">
                    <Link to="/user-reports" className="sub-nav-link">
                      User Reports
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <div
                  className={`nav-link ${
                    expandedMenu === "teams" ? "active-nav-links" : ""
                  }`}
                  onClick={() => toggleMenu("teams")}
                >
                  <span className="d-flex align-items-center justify-content-between">
                    <span className="d-flex align-items-center">
                      <FaTeamspeak className="sidebar-icon" />
                      <span> {!isCollapsed && "Teams"}</span>
                    </span>
                    {expandedMenu === "teams" ? (
                      <FiChevronDown className="dropdown-icon" />
                    ) : (
                      <FiChevronRight className="dropdown-icon" />
                    )}
                  </span>
                </div>
                <ul
                  className={`sub-menu ${
                    expandedMenu === "teams" ? "active" : ""
                  }`}
                >
                  <li className="sub-nav-list">
                    <Link to="/admin/all-teams" className="sub-nav-link">
                      All Teams
                    </Link>
                  </li>
                  {/*<li className="sub-nav-list"><Link to="/team-chat" className="sub-nav-link">Chat</Link></li>*/}
                </ul>
              </li>

              <li>
                <Link
                  to="/admin/settings"
                  className={`nav-link ${
                    location.pathname === "/admin/settings"
                      ? "active-nav-links"
                      : ""
                  }`}
                >
                  <FiSettings className="sidebar-icon" />{" "}
                  {!isCollapsed && "Settings"}
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/referal-program"
                  className={`nav-link ${
                    location.pathname === "/admin/referal-program"
                      ? "active-nav-links"
                      : ""
                  }`}
                >
                  <FaHandHolding className="sidebar-icon" />{" "}
                  {!isCollapsed && "Referal Program"}
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/help-support"
                  className={`nav-link ${
                    location.pathname === "/admin/help-support"
                      ? "active-nav-links"
                      : ""
                  }`}
                >
                  <FiHelpCircle className="sidebar-icon" />{" "}
                  {!isCollapsed && "Help & Support"}
                </Link>
              </li>
              {/* <div className="line-dashed"></div> */}
              {/* <li>
                <Link to="#" className="nav-link" onClick={handlelogout}>
                  <FiLogOut className="sidebar-icon" /> Logout
                </Link>
              </li> */}
            </ul>
          </nav>
        </aside>
      </div>
    </>
  );
}

export default SidebarAdmin;
