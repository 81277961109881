export const JobCategoryOptions = [{
    label: "Allied Jobs", value: "Allied Jobs"
}, {
    label: "Nursing Jobs", value: "Nursing Jobs"
}];

export const ShiftTypeOptions = [
    { value: "Day", label: "Day Shift" },
    { value: "Mid", label: "Mid Shift" },
    { value: "Evening", label: "Evening Shift" },
    { value: "Night", label: "Night Shift" },
    { value: "Rotating", label: "Rotating" }
];