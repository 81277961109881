import React,{useState} from "react";
import GeneralApplicantsList from "../../Components/GeneralApplicants/GeneralApplicantsList.jsx";
import SidebarSeoUser from "../../Layout/Sidebar/Index.jsx";
import HeaderSeoUser from "../../Layout/Header/Index.jsx";

const GeneralApplicantPage = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const handleCollapseToggle = (collapsedState) => {
      setIsCollapsed(collapsedState);
    };
    return (
        <>
            <SidebarSeoUser onCollapseToggle={handleCollapseToggle} />
            <HeaderSeoUser onCollapseToggle={handleCollapseToggle} isCollapsed={isCollapsed}/>
            <div className={`main-wrapper ${isCollapsed ? "collapsed" : ""}`}>
                <GeneralApplicantsList />
            </div>
        </>
    )
}

export default GeneralApplicantPage;