import React, { useState, useEffect } from 'react';
import "./auth.css";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import healthcareImage1 from '../../Assets/Image/Auth/auth-1.svg';
import healthcareImage2 from '../../Assets/Image/Auth/auth-2.svg';
import healthcareImage3 from '../../Assets/Image/Auth/auth-3.svg';
import healthcareImage4 from '../../Assets/Image/Auth/auth-4.svg';
import logo from "../../Assets/Logo/logo.png";
import { Link, useNavigate } from 'react-router-dom';
import Instance from "../../../Instance";
import { showErrorMessage, showSuccessMessage } from '../../../globalConstant';

const images = [healthcareImage1, healthcareImage2, healthcareImage3, healthcareImage4];

const Login = () => {
    const navigate = useNavigate();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const loggedInUserInfo = JSON.parse(localStorage.getItem('loggedInUserInfo'));
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const isLogin = async () => {
        try {
            const response = await Instance.get("/validateToken", {
                headers: {
                    Authorization: `Bearer ${loggedInUserInfo?.token}`
                }
            });
            if (response?.status === 200) {
                if (response?.data?.role === 'ADMIN')
                    navigate('/admin/dashboard');
                else if (response?.data?.role === 'RECRUITER')
                    navigate('/recruiter/dashboard');
                else if (response?.data?.role === 'RECRUITERLEAD')
                    navigate('/recruiter-lead/dashboard');
                else if (response?.data?.role === 'SEOUSER')
                    navigate('/seo-user/posts');
            }
        } catch (error) {
            console.error(error);
            localStorage.removeItem('loggedInUserInfo');
        }
    };

    useEffect(() => {
        isLogin();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const HandleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!formData.email || formData?.email?.trim() === '') {
                showErrorMessage("Email is required!");
                return;
            }

            if (!formData.password || formData?.password?.trim() === '') {
                showErrorMessage("Password is required!");
                return;
            }

            setIsLoading(true);
            const response = await Instance.post('/cms-login', formData);
            if (response?.status === 200) {
                const data = JSON.stringify(response?.data?.responseData);
                localStorage.setItem('loggedInUserInfo', data);
                showSuccessMessage('Login Successful!');
                if (response?.data?.responseData?.role === 'ADMIN') {
                    navigate('/admin/dashboard');
                } else if (response?.data?.responseData?.role === 'RECRUITER') {
                    navigate('/recruiter/dashboard');
                } else if (response?.data?.responseData?.role === 'RECRUITERLEAD') {
                    navigate('/recruiter-lead/dashboard');
                } else if (response?.data?.responseData?.role === 'SEOUSER') {
                    navigate('/seo-user/posts');
                }
            }
        } catch (error) {
            showErrorMessage(error?.response?.data?.message || 'Failed to Login. Try again!');
        } finally {
            setIsLoading(false);
        }
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const toggleRememberMe = () => {
        setRememberMe(!rememberMe);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5000);

        return () => clearInterval(intervalId);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="login-container">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="login-left-panel">
                            <div className="login-left-panel-card">
                                <img src={images[currentImageIndex]} alt="Healthcare professionals" />
                            </div>

                            <div className="login-pagination">
                                {images.map((_, index) => (
                                    <span
                                        key={index}
                                        className={index === currentImageIndex ? 'active' : ''}
                                    ></span>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 justify-content-center">
                        <div className="login-right-panel">
                            <center>
                                <div className="logo">
                                    <img src={logo} alt="logo" />
                                </div>
                                <h2>Welcome Back</h2>
                                <p>Please Login to your account</p>
                            </center>
                            <form onSubmit={HandleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" id="email" placeholder="Enter your email ID" onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <div className="password-input-wrapper">
                                        <input
                                            type={passwordVisible ? "text" : "password"}
                                            id="password"
                                            placeholder="Enter your Password"
                                            autoComplete='off'
                                            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                                        />
                                        <span onClick={togglePasswordVisibility} className="password-toggle-icon">
                                            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                                        </span>
                                    </div>
                                </div>
                                <div className="form-options">
                                    <div className='toggle-container'>
                                        <label className="toggle-switch">
                                            <input
                                                type="checkbox"
                                                checked={rememberMe}
                                                onChange={toggleRememberMe}
                                            />
                                            <span className="slider"></span>
                                        </label>
                                        <p>
                                            Remember me
                                        </p>
                                    </div>
                                    <Link to="/forgot-password" className="forget-password">Forget Password?</Link>
                                </div>
                                <button type="submit" className="login-button">
                                    {isLoading ? (
                                        <div className="spinner"></div>
                                    ) : (
                                        'Login'
                                    )}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
