import { createSlice } from "@reduxjs/toolkit";

const generalApplicationSlice = createSlice({
  name: "generalApplicationSlice",
  initialState: {
    applications: [],
  },
  reducers: {
    addAllGeneralApplications: (state, action) => {
      state.applications = action.payload;
    },
    editGeneralApplication: (state, action) => {
      const idx = state.applications.find(
        (data) => data._id?.toString() === action?.payload?._id?.toString()
      );
      if (idx === -1) {
        return;
      }
      state.applications[idx] = action.payload;
    },
    deleteGeneralApplication: (state, action) => {
      state.applications = state.applications.filter(
        (data) => data._id?.toString() !== action.payload?.toString()
      );
    },
  },
});

export const { addAllGeneralApplications, editGeneralApplication, deleteGeneralApplication } =
generalApplicationSlice.actions;

export default generalApplicationSlice.reducer;
