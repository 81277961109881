import React, { useState, useEffect, useMemo } from 'react'
import { AllTickets } from './AllTickets';
import { OpenTickets } from './OpenTickets';
import { InprogressTickets } from './InprogressTickets';
import { ClosedTickets } from './ClosedTickets';
import { Input } from 'antd';
import { SearchOutlined } from "@ant-design/icons";
import Loader from '../../../Loader';
import Instance from '../../../Instance';
import { addTickets } from '../../../Admin/features/ticketSlice';
import { useDispatch, useSelector } from 'react-redux';

export const RaiseATicket = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('All Tickets');
  const tickets = useSelector((state) => state.ticket?.tickets);
  const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));
  const dataSource = useMemo(() => {
    if (searchText?.trim() === "") return tickets;
    else
      return tickets?.filter((ticket) => {
        return `${ticket?.title?.toLowerCase()}`.includes(searchText?.toLowerCase());
      })
  });

  const fetchAllTickets = async () => {
    try {
      setIsLoading(true);
      const response1 = await Instance.get(`/getTicketsByUser/${loggedInUserInfo?.userId}`, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        },
      });
      const response2 = await Instance.get(`/getTicketsForUser/${loggedInUserInfo?.userId}`, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        }
      });
      if (response1.status === 200 && response2.status === 200) {
        let dataSource = [...response1?.data?.tickets, ...response2?.data.tickets]?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt));
        dispatch(addTickets(dataSource));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllTickets();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderTabs = () => {
    switch (activeTab) {
      case 'All Tickets':
        return <AllTickets ticketsData={dataSource} />;
      case 'New':
        return <OpenTickets ticketsData={dataSource} />;
      case 'OnGoing':
        return <InprogressTickets ticketsData={dataSource} />;
      case 'Resolved':
        return <ClosedTickets ticketsData={dataSource} />;
      default:
        return <AllTickets ticketsData={dataSource} />;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div>
        <div className='d-flex justify-content-between'>
          <div >
            <Input
              placeholder="Search for a ticket"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className="recruiter-search-input-table me-2"
              prefix={<SearchOutlined />}
            />
          </div>
        </div>
        <div className="recruiter-ticket-tab-group mt-4">
          {['All Tickets', 'New', 'OnGoing', 'Resolved'].map((tab) => (
            <button
              key={tab}
              className={`recruiter-ticket-tabs ${activeTab === tab ? 'active' : ''}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
        <div className="">{renderTabs()}</div>
      </div>
    </>
  );

}
