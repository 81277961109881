import React from "react";
import image1 from "../../../Admin/Assets/Image/Recriuiter/Review-job.png";
import image2 from "../../../Admin/Assets/Image/Recriuiter/review-job-2.png";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { Tag } from "antd";
import { formatDate } from "../../../globalConstant";
const ReviewTemplateJob = ({ formData, handleSetConfirm, handleFormSubmit }) => {

  const renderCategory = (title, keywords) => (
    <div style={{ marginBottom: '16px' }}>
      <h4>{title}</h4>
      {keywords?.length === 0 && <p>N/A</p>}
      {keywords?.map((keyword, index) => (
        <Tag
          key={index}
          className="recruiter-review-job-tags"
        >
          {keyword}
        </Tag>
      ))}
    </div>
  );

  return (
    <>
      <div className="row recruiter-create-job-banner mt-2">
        <div className="col-lg-3">
          <img src={image1} className="recruiter-create-job-image" alt="" />
        </div>
        <div className="col-lg-6 recruiter-create-job-heading">
          <h3>Review</h3>
          <div className="recruiter-create-job-underline"></div>
          <p>
            Join our compassionate healthcare team as a dedicated nurse,
            making a difference in patient care every day
          </p>
        </div>

        <div className="col-lg-3">
          <img src={image2} className="recruiter-create-job-image" alt="" />
        </div>
      </div>
      <h2 className="recruiter-review-job-details-heading">Details</h2>
      <div className="row recruiters-personal-information mt-3">
        <div className="row mt-2">
          <div className="col-lg-12">
            <div>
              <h4>Job title</h4>
              <p>{formData?.title}</p>
              <div className="recruiters-text-underline"></div>
            </div>
          </div>

          <div className="col-lg-12 mt-4">
            <div>
              <h4>Job Category</h4>
              <p>{formData?.jobCategory}</p>
              <div className="recruiters-text-underline"></div>
            </div>
          </div>

          <div className="col-lg-12 mt-4">
            {renderCategory("Tags/Keywords", formData?.tagsKeyword, "tags")}
          </div>
        </div>
      </div>
      <div className="row recruiters-personal-information mt-3">
        <h5>Salary</h5>
        <div className="row mt-2">
          <div className="col-lg-4">
            <div>
              <h4>Weekly Earnings</h4>
              <p>${formData?.weeklyEarnings}</p>
              <div className="recruiters-text-underline"></div>
            </div>
            <div className="mt-4">
              <h4>Housing pay | day</h4>
              <p>{formData?.housingPay && '$'}{formData?.housingPay || 'N/A'}</p>
              <div className="recruiters-text-underline"></div>
            </div>
          </div>
          <div className="col-lg-4">
            <div>
              <h4>Travel per Diems | Week</h4>
              <p>{formData?.travelPerDiems && '$'}{formData?.travelPerDiems || 'N/A'}</p>
              <div className="recruiters-text-underline"></div>
            </div>
          </div>
          <div className="col-lg-4">
            <div>
              <h4>Meals pay | day</h4>
              <p>{formData?.mealsPay && '$'}{formData?.mealsPay || 'N/A'}</p>
              <div className="recruiters-text-underline"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="row recruiters-personal-information mt-3">
        <h5>Advance Info</h5>
        <div className="row mt-2">
          <div className="col-lg-4">
            <div>
              <h4>Minimum Experience in year </h4>
              <p>{formData?.experience} year</p>
              <div className="recruiters-text-underline"></div>
            </div>
            <div className="mt-4">
              <h4>Job posted Date</h4>
              <p>{formatDate(formData?.jobPostedDate)}</p>
              <div className="recruiters-text-underline"></div>
            </div>
            <div className="mt-4">
              <h4>Shift Hours</h4>
              <p>{formData?.shiftHours}</p>
              <div className="recruiters-text-underline"></div>
            </div>
          </div>
          <div className="col-lg-4">
            <div>
              <h4>Job type</h4>
              <p>{formData?.jobType?.join(", ")}</p>
              <div className="recruiters-text-underline"></div>
            </div>
            <div className="mt-4">
              <h4>Job Start Date</h4>
              <p>{formData?.jobStartDate ? formatDate(formData?.jobStartDate) : 'N/A'}</p>
              <div className="recruiters-text-underline"></div>
            </div>
            <div className="mt-4">
              <h4>Job Status</h4>
              <p>{formData?.jobStatus}</p>
              <div className="recruiters-text-underline"></div>
            </div>
          </div>
          <div className="col-lg-4">
            <div>
              <h4>No of positions</h4>
              <p>{formData?.vacancies}</p>
              <div className="recruiters-text-underline"></div>
            </div>
            <div className="mt-4">
              <h4>Shift type</h4>
              <p>Day Shift </p>
              <div className="recruiters-text-underline"></div>
            </div>
            <div className="mt-4">
              <h4>Duration In Weeks</h4>
              <p>{formData?.duration || 'N/A'}</p>
              <div className="recruiters-text-underline"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="row recruiters-personal-information mt-3">
        <h5>Location</h5>
        <div className="row mt-2">
          <div className="col-lg-4">
            <div>
              <h4>State</h4>
              <p>{formData?.state}</p>
              <div className="recruiters-text-underline"></div>
            </div>
            <div className="mt-4">
              <h4>Time Zones</h4>
              <p>{formData?.timeZones?.join(", ")}</p>
              <div className="recruiters-text-underline"></div>
            </div>
          </div>
          <div className="col-lg-4">
            <div>
              <h4>Cities</h4>
              <p>{formData?.cities?.join(", ")}</p>
              <div className="recruiters-text-underline"></div>
            </div>
          </div>
          <div className="col-lg-4">
            <div>
              <h4>ZIP Code</h4>
              <p>{formData?.pinCode}</p>
              <div className="recruiters-text-underline"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="row recruiters-personal-information mt-3">
        <div className="row mt-2">

          {renderCategory("Job Benefits", formData?.jobBenefits)}

          {renderCategory("Licenses", formData?.license)}

          {renderCategory("Certifications", formData?.certification)}

          {renderCategory("Immunizations", formData?.immunization)}

          <div style={{ marginTop: "20px" }}>
            <h4>Job Description</h4>
            <div dangerouslySetInnerHTML={{ __html: formData?.jobDesc }}></div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-4">
        <button
          type="button"
          className="recruiter-cancel-btn"
          onClick={() => handleSetConfirm(false)}
        >
          <IoIosArrowBack />
          &nbsp; Back
        </button>

        <button className="recruiter-create-btn" onClick={handleFormSubmit}>
          Save
          <IoIosArrowForward />
        </button>
      </div>
    </>
  );
};

export default ReviewTemplateJob;
