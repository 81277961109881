import React ,{useState}from "react";
import SidebarSeoUser from "../../Layout/Sidebar/Index.jsx";
import HeaderSeoUser from "../../Layout/Header/Index.jsx";
import PostList from "../../Components/Post/PostList.jsx";

const PostPage = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const handleCollapseToggle = (collapsedState) => {
      setIsCollapsed(collapsedState);
    };
    return (
        <>
            <SidebarSeoUser onCollapseToggle={handleCollapseToggle} />
            <HeaderSeoUser onCollapseToggle={handleCollapseToggle} isCollapsed={isCollapsed}/>
            <div className={`main-wrapper ${isCollapsed ? "collapsed" : ""}`}>
                <PostList />
            </div>
        </>
    )
}

export default PostPage;