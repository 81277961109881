import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import SidebarSeoUser from "../../Layout/Sidebar/Index.jsx";
import HeaderSeoUser from "../../Layout/Header/Index.jsx";
import Tickets from "../../Components/HelpAndSupport/Tickets";

const HelpPortalPage = () => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const [isCollapsed, setIsCollapsed] = useState(false);
    const handleCollapseToggle = (collapsedState) => {
      setIsCollapsed(collapsedState);
    };
    return (
        <>
            <SidebarSeoUser onCollapseToggle={handleCollapseToggle} />
            <HeaderSeoUser onCollapseToggle={handleCollapseToggle} isCollapsed={isCollapsed}/>
            <div className={`main-wrapper ${isCollapsed ? "collapsed" : ""}`}>
        {loading ? (
          <div className="pre-loader">
            <Spin size="large" className="custom-loader" />
          </div>
        ) : (
          <Tickets />
        )}
      </div>
    </>
  );
};

export default HelpPortalPage;
