import { createSlice } from '@reduxjs/toolkit';

const applicationSlice = createSlice({
    name: 'application',
    initialState: {
        applications: []
    },
    reducers: {
        addApplications: (state, action) => {
            state.applications = action.payload;
        },
        changeStatus: (state, action) => {
            const index = state.applications.findIndex((application) => application?._id?.toString() === action.payload._id?.toString());
            if (index !== -1) state.applications[index].status = action.payload.status;
        }
    }
});

export const { addApplications, changeStatus } = applicationSlice.actions;
export default applicationSlice.reducer;