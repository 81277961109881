import { createSlice } from "@reduxjs/toolkit";

const ticketSlice = createSlice({
    name: 'ticket',
    initialState: {
        tickets: [],
        detailTicket: null
    },
    reducers: {
        addTickets: (state, action) => {
            state.tickets = action.payload;
        },
        addNewTicket: (state, action) => {
            state.tickets.unshift(action.payload);
        },
        addDetailTicket: (state, action) => {
            state.detailTicket = action.payload
        },
        addReplyToTicket: (state, action) => {
            state.detailTicket.replies.push(action.payload);
        },
        changeTicketStatus: (state, action) => {
            state.detailTicket.status = action.payload.status
        },
        deleteReplyFromTicket: (state, action) => {
            state.detailTicket.replies = state.detailTicket.replies.filter(item => item?._id?.toString() != action?.payload?.toString());
        }
    }
});
export default ticketSlice.reducer;
export const { addNewTicket, addTickets, addDetailTicket, addReplyToTicket, changeTicketStatus, deleteReplyFromTicket } = ticketSlice.actions;