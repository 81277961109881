import React, { useEffect, useState } from "react";
import "./generalApplicants.css";
import { Table, Input, DatePicker, Spin } from "antd";
import {
  SearchOutlined,
  FilterOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import Instance from "../../../Instance";
import { useSelector, useDispatch } from "react-redux";
import { addAllGeneralApplications, deleteGeneralApplication } from "../../../Admin/features/generalApplicantsSlice";
import { showSuccessMessage, showErrorMessage, FRONTEND_BASE_URL } from "../../../globalConstant";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { IMG_URL } from "../../../globalConstant";

const GeneralApplicantsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [fileterParameters, setFilterParameters] = useState({});
  const generalApplicantsData = useSelector((state) => state?.generalApplications?.applications);
  const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));
  
  const fetchAllGeneralApplications = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get('/getAllGeneralApplicant', {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        },
      });
      if (response.status === 200 && response?.data?.generalApplicants) {
        let sortedData = response?.data?.generalApplicants?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setAllData(sortedData);
        dispatch(addAllGeneralApplications(sortedData));
      }
    } catch (error) {
      showErrorMessage(error?.response?.data?.message || 'An error occured');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchAllGeneralApplications();
  }, []);

  const columns = [
    {
      title: "Applicant Name",
      sorter: (a, b) =>
        a?.firstName.localeCompare(b?.firstName),
      render: (text, item) => (
        <div>
          {`${item?.firstName} ${item?.lastName || ""}`}
        </div>
      ),
    },
    {
      title: "Email",
      sorter: (a, b) => a?.email.localeCompare(b?.email),
      render: (text, item) => <div>{`${item?.email}`}</div>,
    },
    {
      title: "Resume",
      render: (text, item) => (
        <div>
          {(item?.resume) ? <a href={`${IMG_URL}/${item?.resume}`} target="_blank" rel="noreferrer">View</a> : "N/A"}
        </div>
      ),
    },
    {
      title: "Post URL",
      sorter: (a, b) => {
        return 1;
      },
      render: (text, item) => (
        <a target="_blank" rel="noreferrer" href={`${FRONTEND_BASE_URL}${item?.postUrl}`} style={{ color: 'blue' }}>

          {`${FRONTEND_BASE_URL}${item?.postUrl}`}
        </a>
      ),
    },
    {
      title: "Submission Date",
      sorter: (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt),
      render: (text, item) => (
        <div>
          {new Date(item?.createdAt).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })}
        </div>
      ),
    },
    {
      title: "Detail",
      dataIndex: "detail",
      key: "detail",
      render: (text, record) => (
        <div
          className="view--button"
          onClick={() => {
            navigate(`/view-general-applicants/${record._id}`);
          }}
        >
          View
        </div>
      ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      render: (_, record) => (
        <DeleteOutlined
          key={`delete-${record._id}`}
          className="delete-button-table"
          onClick={() => {
            Swal.fire({
              title: "Are you sure",
              text: "You want to Delete?",
              showCancelButton: true,
              confirmButtonColor: "#555",
              cancelButtonColor: "#ce1b28",
              confirmButtonText: "Yes, Delete!",
            }).then((result) => {
              if (result.isConfirmed) {
                handleDelete(record._id);
              }
            });
          }}
        />
      ),
    },
  ];

  const handleDelete = async (id) => {
    try {
      setIsLoading(true);
      const response = await Instance.delete(`/deleteGeneralApplicant/${id}`, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        },
      });
      if (response.status === 200) {
        dispatch(deleteGeneralApplication(id));
        let curData = allData?.filter((item) => item?._id?.toString() !== id?.toString());
        setAllData(curData);
        showSuccessMessage('Application Deleted Successfully!');
      }
    } catch (error) {
      showErrorMessage(error?.response?.data?.message || 'An error occured');
    } finally {
      setIsLoading(false);
    }
  }

  const handleFilter = () => {
    let filteredData = [...allData];
    if (fileterParameters?.date) {
      filteredData = filteredData?.filter((item) => {
        let filterDate = new Date(fileterParameters?.date);
        let itemDate = new Date(item?.createdAt);
        return filterDate?.getDate() === itemDate?.getDate() && filterDate?.getMonth() === itemDate?.getMonth() && filterDate?.getFullYear() === itemDate?.getFullYear();
      });
    }

    if (fileterParameters?.selectedRows && fileterParameters?.selectedRows.length !== 0) {
      filteredData = filteredData?.filter((item) => {
        let exist = fileterParameters?.selectedRows.findIndex((d) => d?.toString() === item?._id?.toString());
        if (exist !== -1) return 1;
        return 0;
      });
    }
    dispatch(addAllGeneralApplications(filteredData));
  };

  useEffect(() => {
    if (fileterParameters?.searchText === '') {
      dispatch(addAllGeneralApplications(allData));
    };
    let filteredData = allData?.filter(item => JSON.stringify(item).toLowerCase().includes(fileterParameters?.searchText?.toLowerCase()));
    dispatch(addAllGeneralApplications(filteredData));
  }, [fileterParameters?.searchText]);


  return (
    <>
      {isLoading && <div className="keyword-loader">
        <Spin size="large" className="custom-loader" />
      </div>
      }
      <div className="main-title-all">
        <span>General Applicants</span>
      </div>

      <div className="user-list-section">
        <div className="filter-row">
          <div className="select-options">
            <DatePicker
              onChange={(e) => {
                let newData = { ...fileterParameters };
                newData['date'] = e;
                setFilterParameters(newData);
              }}
              className="custom-date-picker mx-2"
              format={"MM/DD/YYYY"}
            />
          </div>
          <div className="search-table-container">
            <Input
              placeholder="Search..."
              value={fileterParameters?.searchText}
              onChange={(e) => {
                let newData = { ...fileterParameters };
                newData['searchText'] = e.target.value;
                setFilterParameters(newData);
              }}
              className="search-input-table me-2"
              prefix={<SearchOutlined />}
            />
            <button onClick={handleFilter}>
              <FilterOutlined /> <span>Filter</span>
            </button>
          </div>
        </div>

        <div className="table-list">
          <Table
            columns={columns}
            dataSource={generalApplicantsData}
            rowKey={(data) => data?._id}
            rowSelection={{
              type: 'checkbox',
              onChange: (selectedRowKeys, selectedRows) => {
                setFilterParameters(prevData => ({
                  ...prevData,
                  ['selectedRows']: selectedRowKeys
                }));
              },
            }}
          />
        </div>
      </div>
    </>
  )
}

export default GeneralApplicantsList;