import React from 'react'
import image1 from "../../Assets/Image/Recriuiter/Review-job.png"
import image2 from "../../Assets/Image/Recriuiter/review-job-2.png"
import { CheckCircleFilled } from "@ant-design/icons";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";
import Instance from "../../../Instance";
import { showErrorMessage, showSuccessMessage } from "../../../globalConstant";
import { Input, Select, DatePicker, Form } from "antd";
import { FaTrash } from "react-icons/fa";
import { PlusOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { JobStatusOptions, JobTypeOptions, ShiftHoursOptions, ShiftTypeOptions, TimeZoneOptions, JobCategoryOptions } from "./constant";
import dayjs from 'dayjs';
import Loader from '../../../Loader';
import ReviewTemplateJob from './ReviewTemplateJob';
import { GetState, GetCity } from "react-country-state-city";

export const CreateTemplateJob = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const jobId = location?.state?.jobId;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [jobDetail, setJobDetail] = useState({});
  const [tagsKeyword, setTagsKeyword] = useState([]);
  const [jobBenefits, setJobBenefits] = useState([]);
  const [license, setLicense] = useState([]);
  const [certification, setCertification] = useState([]);
  const [immunization, setImmunization] = useState([]);
  const [clients, setClients] = useState([]);
  const [recruitersList, setRecruitersList] = useState([]);
  const [isConfirm, setIsConfirm] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));

  useEffect(() => {
    if (!jobId) {
      navigate("/create-job");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchJobDetail = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get(`/getJobById/${jobId}`, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        },
      });

      if (response.status === 200) {
        let data = JSON.parse(JSON.stringify(response?.data?.job));
        setFormData(data);
        setJobDetail(response?.data?.job);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchClientsDetail = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get("/getAllClients", {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        },
      });

      if (response.status === 200) {
        setClients(response?.data?.clients);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRecruiterData = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get("/getAllRecruiters", {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        },
      });
      if (response?.status === 200) {
        setRecruitersList(response?.data?.recruiters);
      }
    } catch (error) {
      console.error(error);
      showErrorMessage(error?.response?.data?.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchJobDetail();
    fetchClientsDetail();
    fetchRecruiterData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!jobDetail) return;
    setTagsKeyword(jobDetail?.tagsKeyword);
    setJobBenefits(jobDetail?.jobBenefits);
    setImmunization(jobDetail?.immunization);
    setCertification(jobDetail?.certification);
    setLicense(jobDetail?.license);
    form.setFieldsValue({
      ...jobDetail,
      jobPostedDate: dayjs(jobDetail?.jobPostedDate),
      jobStartDate: (jobDetail?.jobStartDate) ? dayjs(jobDetail?.jobStartDate) : null,
    });
    // eslint-disable-next-line
  }, [jobDetail]);

  const handleAddTagsKeyword = () => {
    setTagsKeyword([...tagsKeyword, ""]);
  };

  const handleTagsKeywordChange = (e, index) => {
    const newTags = [...tagsKeyword];
    newTags[index] = e.target.value;
    setTagsKeyword(newTags);
  };

  const handleRemoveTagsKeyword = (index) => {
    const newTags = tagsKeyword.filter((_, idx) => idx !== index);
    setTagsKeyword(newTags);
  };

  const handleAddTags = (type) => {
    switch (type) {
      case "jobBenefits":
        setJobBenefits([...jobBenefits, ""]);
        break;
      case "license":
        setLicense([...license, ""]);
        break;
      case "certification":
        setCertification([...certification, ""]);
        break;
      case "immunization":
        setImmunization([...immunization, ""]);
        break;
      default:
        break;
    }
  };

  const handleTagChanges = (e, index, type) => {
    const newTags = [
      ...(type === "jobBenefits"
        ? jobBenefits
        : type === "license"
          ? license
          : type === "certification"
            ? certification
            : immunization),
    ];

    newTags[index] = e.target.value;

    if (type === "jobBenefits") setJobBenefits(newTags);
    else if (type === "license") setLicense(newTags);
    else if (type === "certification") setCertification(newTags);
    else setImmunization(newTags);
  };

  const handleRemoveTags = (index, type) => {
    const newTags = [
      ...(type === "jobBenefits"
        ? jobBenefits
        : type === "license"
          ? license
          : type === "certification"
            ? certification
            : immunization),
    ];

    newTags.splice(index, 1);

    if (type === "jobBenefits") setJobBenefits(newTags);
    else if (type === "license") setLicense(newTags);
    else if (type === "certification") setCertification(newTags);
    else setImmunization(newTags);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
    ],
  };

  useEffect(() => {
    const fetchStates = async () => {
      const states = await GetState(233);
      const options = states?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));
      setStateOptions(options);
    };
    fetchStates();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchCities = async () => {
      if (!formData?.state) {
        setCityOptions([]);
        return;
      }
      const cities = await GetCity(233, formData?.state);
      const options = cities?.map((item) => ({
        label: item?.name,
        value: item?.name,
      }));
      setCityOptions(options);
    };
    fetchCities();
  }, [formData?.state]);

  const handleFieldChange = (key, e) => {
    if (key === 'state') {
      form.setFieldsValue({ cities: null });
      setFormData({ ...formData, [key]: e, cities: [] });
    } else if (key === 'jobCategory' || key === 'jobType' || key === 'jobPostedDate' || key === 'jobStartDate' || key === 'jobStatus' || key === 'shiftType' || key === 'shiftHours' || key === 'timeZones' || key === 'cities' || key === 'jobDesc' || key === 'associatedClient') {
      if (key === 'state') {
        form.setFieldsValue({ city: null });
        setFormData({ ...formData, [key]: e, cities: [] });
      } else {
        setFormData({ ...formData, [key]: e });
      }
    } else {
      setFormData({ ...formData, [key]: e.target.value });
    }
  };

  const isFormValid = () => {
    try {
      if (!formData?.title || formData?.title?.trim() === '') {
        showErrorMessage("Job Title is required!");
        return false;
      }
      if (tagsKeyword?.length !== 0) {
        for (let i = 0; i < tagsKeyword?.length; i++) {
          if (!tagsKeyword[i] || tagsKeyword[i]?.trim() === '') {
            showErrorMessage(`Tags/Keywords is required at index ${i + 1}!`);
            return false;
          }
        }
      }
      if (!formData?.jobCategory) {
        showErrorMessage("Job Category is required!");
        return false;
      }
      if (!formData?.weeklyEarnings && formData?.weeklyEarnings !== 0) {
        showErrorMessage("Weekly Earnings is required");
        return false;
      }
      if (!formData?.experience && formData?.experience !== 0) {
        showErrorMessage("Minimum experience is required");
        return false;
      }
      if (!formData?.jobType || formData?.jobType?.length < 1) {
        showErrorMessage("Job Type is required");
        return false;
      }
      if (!formData?.vacancies) {
        showErrorMessage("No of postions is required");
        return false;
      }
      if (!formData?.jobPostedDate) {
        showErrorMessage("Job Posted Date is required!");
        return false;
      }
      if (!formData?.jobStatus) {
        showErrorMessage("Job Status is required!");
        return false;
      }
      if (!formData?.shiftType) {
        showErrorMessage("Shift Type is required!");
        return false;
      }
      if (!formData?.shiftHours) {
        showErrorMessage("Shift Hours is required!");
        return false;
      }
      if (jobBenefits?.length !== 0) {
        for (let i = 0; i < jobBenefits?.length; i++) {
          if (!jobBenefits[i] || jobBenefits[i]?.trim() === '') {
            showErrorMessage(`Job Benefits is required at index ${i + 1}!`);
            return false;
          }
        }
      }
      if (license?.length !== 0) {
        for (let i = 0; i < license?.length; i++) {
          if (!license[i] || license[i]?.trim() === '') {
            showErrorMessage(`License is required at index ${i + 1}!`);
            return false;
          }
        }
      }
      if (certification?.length !== 0) {
        for (let i = 0; i < certification?.length; i++) {
          if (!certification[i] || certification[i]?.trim() === '') {
            showErrorMessage(`Certification is required at index ${i + 1}!`);
            return false;
          }
        }
      }
      if (immunization?.length !== 0) {
        for (let i = 0; i < immunization?.length; i++) {
          if (!immunization[i] || immunization[i]?.trim() === '') {
            showErrorMessage(`Immunization is required at index ${i + 1}!`);
            return false;
          }
        }
      }
      if (!formData?.associatedClient) {
        showErrorMessage("Associated Client is required!");
        return false;
      }
      if (!formData?.jobDesc || formData?.jobDesc?.trim() === '') {
        showErrorMessage("Job Description is required!");
        return false;
      }
      return true;
    } catch (error) {
      console.error(error);
      showErrorMessage('Failed to add job! Please try again');
      return false;
    }
  }

  const handleFormSubmit = async () => {
    try {
      if (!isFormValid()) return;
      setIsLoading(true);
      let reqData = { ...formData, tagsKeyword, jobBenefits, license, certification, immunization };
      let s = await GetState(233);
      s = s?.find((item) => item?.id === formData?.state);
      if(s){
        reqData.state = s?.name;
        reqData.stateCode = s?.state_code;
      }
      reqData['_id'] = null;
      reqData['updatedBy'] = null;
      reqData['jobId'] = null;

      const response = await Instance.post('/addJob', reqData, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        },
      });
      if (response.status === 201) {
        showSuccessMessage('Job Added Successfully!');
        navigate('/admin/create-job');
      }
    } catch (error) {
      console.error(error);
      showErrorMessage('Failed to add job! Please try again');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSetConfirm = () => {
    setIsConfirm(false);
  }

  const clientOptions = clients?.map((item) => ({
    label: `${item?.hospitalName} ${item?.clientId}`,
    value: item?._id,
  }));

  const recruitersOptions = recruitersList?.map((item) => ({
    label: `${item?.firstName} ${item?.lastName}`,
    value: item?._id,
  }));

  return (
    <>
      {isLoading && <Loader />}
      <div className="create-job-section">
        {!isConfirm && <div className="container">
          <div className="row create-job-banner mt-2">
            <div className="col-lg-3">
              <img src={image1} className="create-job-image" alt="" />
            </div>
            <div className="col-lg-6 create-job-heading">
              <h3>Review</h3>
              <div className="create-job-underline"></div>
              <p>
                Join our compassionate healthcare team as a dedicated nurse, making a difference in patient care every day
              </p>
            </div>
            <div className="col-lg-3">
              <img src={image2} className="create-job-image" alt="" />
            </div>
          </div>
          <div className="row previous-job-template mt-4">
            <div className='d-flex gap-4'>
              <CheckCircleFilled className='check-icon' />
              <div>
                <h4>You are using previously added job as a template </h4>
                <p>Edit the information to reflect changes needed for you job post.</p>
              </div>
            </div>
          </div>
          <h2 className='review-job-details-heading'>Job details</h2>
          <Form layout="vertical" onFinish={handleFormSubmit} form={form}>
            <div className="add-job-section">
              <div className="row">
                <div className="row">
                  <div className="col-lg-12 my-3">
                    <Form.Item name={"title"}>
                      <Input
                        placeholder="Job Title *"
                        value={formData?.title || ""}
                        onChange={(e) => handleFieldChange('title', e)}
                      />
                    </Form.Item>
                  </div>
                </div>
                <h4>Tags/Specialities</h4>
                <div className="row">
                  <div className="d-flex flex-column gap-2 mt-3">
                    <button
                      className="add-tag-button d-flex gap-2 align-items-center"
                      onClick={handleAddTagsKeyword}
                      type='button'
                    >
                      Add <PlusOutlined />
                    </button>
                    {tagsKeyword?.map((tag, index) => (
                      <div key={index} className="d-flex align-items-center">
                        <input
                          className="add-tag-input me-2"
                          type="text"
                          value={tag}
                          onChange={(e) => handleTagsKeywordChange(e, index)}
                          placeholder="Add tag"
                        />
                        <FaTrash
                          className="icon-red"
                          onClick={() => handleRemoveTagsKeyword(index)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-lg-6 my-4">
                  <Form.Item name={"jobCategory"}>
                    <Select
                      placeholder="Job Category *"
                      className="select-job-categories"
                      onChange={(e) => handleFieldChange("jobCategory", e)}
                      options={JobCategoryOptions}
                      value={formData?.jobCategory}
                    />
                  </Form.Item>
                </div>
                <h4>Salary</h4>
                <div className="row">
                  <div className="col-lg-4 my-3">
                    <Form.Item name={"weeklyEarnings"}>
                      <Input
                        placeholder="Weekly Earnings *"
                        value={formData?.weeklyEarnings || ""}
                        onChange={(e) => handleFieldChange('weeklyEarnings', e)}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-4 my-3">
                    <Form.Item name={"travelPerDiems"}>
                      <Input
                        placeholder="Travel per Diems | Week"
                        value={formData?.travelPerDiems || ""}
                        onChange={(e) => handleFieldChange('travelPerDiems', e)}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-4 my-3">
                    <Form.Item name={"mealsPay"}>
                      <Input
                        placeholder="Meals per Day"
                        value={formData?.mealsPay || ""}
                        onChange={(e) => handleFieldChange('mealsPay', e)}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-4 my-3">
                    <Form.Item name={"housingPay"}>
                      <Input
                        placeholder="Housing Pay | Day"
                        value={formData?.housingPay || ""}
                        onChange={(e) => handleFieldChange('housingPay', e)}
                      />
                    </Form.Item>
                  </div>
                </div>
                <h4>Advance Info</h4>
                <div className="row">
                  <div className="col-lg-4 my-3">
                    <Form.Item name={"experience"}>
                      <Input
                        placeholder="Minimum Experience in Years *"
                        value={formData?.experience || ""}
                        onChange={(e) => {
                          handleFieldChange('experience', e)
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-4 my-3">
                    <Form.Item name={"jobType"}>
                      <Select
                        style={{ width: '100%' }}
                        placeholder="Job Type *"
                        className="select-job-categories"
                        onChange={(e) => handleFieldChange("jobType", e)}
                        value={formData?.jobType}
                        options={JobTypeOptions}
                        mode="multiple"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-4 my-3">
                    <Form.Item name={"vacancies"}>
                      <Input
                        placeholder="No of Positions *"
                        value={formData?.vacancies || ""}
                        onChange={(e) => handleFieldChange('vacancies', e)}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-4 my-3">
                    <Form.Item name={"jobPostedDate"}>
                      <DatePicker
                        format="DD/MM/YYYY"
                        placeholder="Job Posted Date *"
                        onChange={(e) => handleFieldChange('jobPostedDate', e)}
                        className="form-control"
                        value={dayjs(formData?.jobPostedDate)}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-4 my-3">
                    <Form.Item name={"jobStartDate"}>
                      <DatePicker
                        format="DD/MM/YYYY"
                        placeholder="Job Start Date"
                        onChange={(e) => handleFieldChange('jobStartDate', e)}
                        className="form-control"
                        value={dayjs(formData?.jobStartDate)}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-4 my-3">
                    <Form.Item name={"shiftType"}>
                      <Select
                        placeholder="Shift Type*"
                        className="select-job-categories"
                        value={formData?.shiftType}
                        onChange={(e) => handleFieldChange("shiftType", e)}
                        options={ShiftTypeOptions}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-4 my-3">
                    <Form.Item name={"shiftHours"}>
                      <Select
                        placeholder="Shift Hours*"
                        className="select-job-categories"
                        onChange={(e) => handleFieldChange("shiftHours", e)}
                        value={formData?.shiftHours}
                        options={ShiftHoursOptions}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-4 my-3">
                    <Form.Item name={"jobStatus"}>
                      <Select
                        placeholder="Job Status *"
                        className="select-job-categories"
                        onChange={(e) => handleFieldChange("jobStatus", e)}
                        value={formData?.jobStatus}
                        options={JobStatusOptions}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-4 my-3">
                    <Form.Item name={"duration"}>
                      <Input
                        placeholder="Duration"
                        value={formData?.duration || ""}
                        onChange={(e) => handleFieldChange('duration', e)}
                      />
                    </Form.Item>
                  </div>
                </div>
                <h4>Location</h4>
                <div className="row">
                  <div className="col-lg-4 my-3">
                    <Form.Item name={"state"}>
                      <Select
                        placeholder="State *"
                        className="select-job-categories"
                        onChange={(e) => handleFieldChange("state", e)}
                        options={stateOptions}
                        value={formData?.state}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-4 my-3">
                    <Form.Item name={"cities"}>
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Cities"
                        className="select-job-categories"
                        onChange={(e) => handleFieldChange("cities", e)}
                        options={cityOptions}
                        value={formData?.cities}
                        mode="multiple"
                        disabled={!formData?.state}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-4 my-3">
                    <Form.Item name={"pinCode"}>
                      <Input
                        name="pinCode"
                        placeholder="Zip Code"
                        value={formData?.pinCode || ""}
                        onChange={(e) => handleFieldChange('pinCode', e)}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-4 my-3">
                    <Form.Item name={"timeZones"}>
                      <Select
                        style={{ width: "100%" }}
                        placeholder="TimeZones"
                        className="select-job-categories"
                        onChange={(e) => handleFieldChange("timeZones", e)}
                        options={TimeZoneOptions}
                        value={formData?.timeZones || []}
                        mode="multiple"
                      />
                    </Form.Item>
                  </div>
                </div>
                <h4>Client & Recruiter Information</h4>
                <div className="row">
                  <div className="col-lg-4 my-3">
                    <Form.Item name={"associatedClient"}>
                      <Select
                        placeholder="Select Client *"
                        className="select-job-categories"
                        onChange={(e) => handleFieldChange("associatedClient", e)}
                        options={clientOptions}
                        name="associatedClient"
                        value={formData?.associatedClient}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-4 my-3">
                    <Form.Item name={"assignedRecruiter"}>
                      <Select
                        placeholder="Select Recruiter"
                        className="select-job-categories"
                        onChange={(e) => handleFieldChange("assignedRecruiter", e)}
                        options={recruitersOptions}
                        value={formData?.assignedRecruiter}
                      />
                    </Form.Item>
                  </div>
                  <div className="d-flex gap-5 mb-4 mt-4">
                    {[
                      {
                        title: "Job Benefits",
                        tags: jobBenefits,
                        type: "jobBenefits",
                        placeholder: "Add Job Benefit"
                      },
                      {
                        title: "License",
                        tags: license,
                        type: "license",
                        placeholder: "Add License"
                      },
                      {
                        title: "Certification",
                        tags: certification,
                        type: "certification",
                        placeholder: "Add Certification"
                      },
                      {
                        title: "Immunization",
                        tags: immunization,
                        type: "immunization",
                        placeholder: "Add Immunization"
                      },
                    ]?.map((category, idx) => (
                      <div key={idx}>
                        <h5>{category.title}</h5>
                        <div className="d-flex flex-column gap-2 mt-3">
                          <button
                            type='button'
                            className="add-tag-button d-flex gap-2 align-items-center"
                            onClick={() => handleAddTags(category.type)}
                          >
                            Add <PlusOutlined />
                          </button>
                          {category?.tags?.map((tag, index) => (
                            <div key={index} className="d-flex align-items-center">
                              <input
                                className="add-tag-input me-2"
                                type="text"
                                value={tag}
                                onChange={(e) =>
                                  handleTagChanges(e, index, category.type)
                                }
                                placeholder={category.placeholder}
                              />
                              <FaTrash
                                className="icon-red"
                                onClick={() => handleRemoveTags(index, category.type)}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <h4>Job Description*</h4>
                <div className="row">
                  <div className="col-lg-12">
                    <Form.Item name={"jobDesc"}>
                      <ReactQuill
                        theme="snow"
                        onChange={(e) => handleFieldChange("jobDesc", e)}
                        modules={modules}
                        value={formData?.jobDesc || ""}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-4">
                  <button className="cancel-btn" onClick={() => navigate("/admin/create-job")} type='button'>
                    <IoIosArrowBack />
                    &nbsp; Cancel
                  </button>
                  <button
                    className="create-btn"
                    type='button'
                    onClick={() => {
                      if (!isFormValid()) {
                        return;
                      }
                      setIsConfirm(true)
                    }}
                  >
                    Continue &nbsp;
                    <IoIosArrowForward />
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>}
        {isConfirm &&
          <div className='contianer'>
            <ReviewTemplateJob formData={{ ...formData, tagsKeyword, jobBenefits, certification, immunization, license }} handleSetConfirm={handleSetConfirm} handleFormSubmit={handleFormSubmit} />
          </div>
        }
      </div>
    </>
  )
}
