import React, { useState } from "react";
import { CgProfile } from "react-icons/cg";
import { FaKey } from "react-icons/fa";
import { PersonalInformation } from "./PersonalInformation";
import { SettingsPassword } from "./SettingsPassword";

export const Settings = () => {
  const [selectedOption, setSelectedOption] = useState("profile");

  const renderContent = () => {
    switch (selectedOption) {
      case "profile":
        return <PersonalInformation />;
      case "password":
        return <SettingsPassword />;
      default:
        return <PersonalInformation />;
    }
  };

  return (
    <div className="recruiter-lead-settings-section">
      <div>
        <div className="row mt-4">
          <h2>Settings</h2>
          <div className="col-lg-4">
            <div className="recruiter-lead-settings-options">
              <div
                className={`recruiter-lead-settings-items ${selectedOption === "profile" ? "active" : ""}`}
                onClick={() => setSelectedOption("profile")}
              >
                <CgProfile /> &nbsp; Personal Information
              </div>
              <div
                className={`recruiter-lead-settings-items mt-3 ${selectedOption === "password" ? "active" : ""}`}
                onClick={() => setSelectedOption("password")}
              >
                <FaKey /> &nbsp; Password
              </div>
            </div>
          </div>

          <div className="col-lg-8">
            {renderContent()}
          </div>
        </div>
      </div>
    </div>
  );
};
