import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select } from "antd";
import Instance from "../../../Instance";
import { useDispatch } from "react-redux";
import { addNewTeam } from "../../features/teamSlice";
import { showErrorMessage, showSuccessMessage } from "../../../globalConstant";
import Loader from "../../../Loader";

export const CreateTeam = ({ isModalVisible, setIsModalVisible }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [members, setMembers] = useState([]);
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));

  const fetchMembers = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get(`/getAllMembers`, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`
        }
      });
      if (response.status === 200) {
        let dataSource = response?.data?.members?.filter((item) => !item?.associatedTeam);
        setMembers(dataSource);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMembers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFormSubmit = async () => {
    try {
      setIsLoading(true);
      let teamLead = JSON.parse(formData?.teamLead);
      for (let i = 0; i < formData?.teamMembers?.length; i++) {
        const member = JSON.parse(formData.teamMembers[i]);
        formData.teamMembers[i] = {
          memberId: member?._id,
          role: member?.role
        }
      }
      formData.teamLead = teamLead?._id;
      formData.teamLeadRole = teamLead?.role;
      const response = await Instance.post("/createNewTeam", { ...formData }, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`
        }
      });
      if (response.status === 201) {
        dispatch(addNewTeam(response?.data?.newTeam));
        showSuccessMessage('Team created successfully');
      }
    } catch (error) {
      console.error(error);
      showErrorMessage('Failed to create team');
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  const handleClose = () => {
    setIsModalVisible(false);
    form.resetFields();
    setFormData({});
  };

  const leadOptions = members?.filter(item => item?.isLead)?.map((item) => ({
    label: `${item?.firstName} ${item?.lastName}`,
    value: JSON.stringify(item)
  }));

  const memberOptions = members?.map((item) => ({
    label: `${item?.firstName} ${item?.lastName}`,
    value: JSON.stringify(item)
  }));

  return (
    <>
      {isLoading && <Loader />}
      <Modal
        title="Create Team"
        open={isModalVisible}
        onCancel={handleClose}
        footer={null}
      >
        <Form layout="vertical" form={form} className="mt-4" onFinish={handleFormSubmit}>
          <div className="row">
            <div className="col-lg-6">
              <Form.Item name="teamName" label="Team Name" rules={[{ required: true, message: "Please enter team name" }]}>
                <Input placeholder="Enter Team name" onChange={(e) => setFormData({ ...formData, teamName: e.target.value })} />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item name="departmentDivision" label="Department/Division" rules={[{ required: true, message: "Please enter department" }]}>
                <Input placeholder="Enter Department" onChange={(e) => setFormData({ ...formData, departmentDivision: e.target.value })} />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Form.Item name="teamLead" label="Team Lead" rules={[{ required: true, message: "Please select team lead" }]}>
                <Select placeholder="Select Team Lead" options={leadOptions} onChange={(e) => setFormData({ ...formData, teamLead: e })} />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item name="teamMembers" label="Team Members" rules={[{ required: true, message: "Please select members" }]}>
                <Select placeholder="Select Members" options={memberOptions} mode="multiple" onChange={(e) => setFormData({ ...formData, teamMembers: e })} />
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-12 ">
            <Form.Item label="Description" name="description">
              <Input.TextArea rows={4} placeholder="Write here..." onChange={(e) => setFormData({ ...formData, description: e.target.value })} />
            </Form.Item>
          </div>
          <div className="form-actions" style={{ display: "flex", justifyContent: "flex-end" }} >
            <button type="button" className="cancel-btn" style={{ marginRight: "20px" }} onClick={handleClose}>
              Cancel
            </button>
            <button className="create-btn" type="submit">
              Save
            </button>
          </div>
        </Form>
      </Modal>
    </>
  );
};
