import React, { useState } from "react";
import { Form, Input } from "antd";
import { showErrorMessage, showSuccessMessage } from '../../../globalConstant';
import Instance from "../../../Instance";
import Loader from '../../../Loader';

export const SettingsPassword = () => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));

  const handleSubmitForm = async () => {
    try {
      const { oldPassword, newPassword, confirmPassword } = formData;
      if (!oldPassword || !newPassword || !confirmPassword) {
        showErrorMessage("All fields are required");
        return;
      }
      if (newPassword !== confirmPassword) {
        showErrorMessage("Passwords do not match");
        return;
      }
      setIsLoading(true);
      const response = await Instance.put(`/change-password`, {
        oldPassword,
        newPassword: newPassword?.trim()
      }, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        }
      });
      if (response.status === 200) {
        setFormData({});
        form.resetFields();
        showSuccessMessage("Password changed successfully");
      }
    } catch (error) {
      console.error(error);
      showErrorMessage("Something went wrong. Please try again!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="personal-information-section">
        <div className="container">
          <h4>Password</h4>
          <Form layout="vertical" onFinish={handleSubmitForm} form={form}>
            <div className="row mt-4">
              <div className="col-md-12 mb-1">
                <Form.Item
                  label="Current Password"
                  name="oldPassword"
                >
                  <Input.Password placeholder="Enter Current Password" value={formData?.oldPassword} onChange={(e) => setFormData({ ...formData, oldPassword: e.target.value })} />
                </Form.Item>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-6 mb-1">
                <Form.Item
                  label="New Password"
                  name="newPassword"
                >
                  <Input.Password placeholder="Enter New Password" value={formData?.newPassword} onChange={(e) => setFormData({ ...formData, newPassword: e.target.value })} />
                </Form.Item>
              </div>
              <div className="col-md-6 mb-1">
                <Form.Item
                  label="Confirm Password"
                  name="confirmPassword"
                >
                  <Input.Password placeholder="Confirm New Password" value={formData?.confirmPassword} onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })} />
                </Form.Item>
              </div>

            </div>

            <div className="row mt-4">
              <div className="col-md-12 text-end">
                <button className="cancel-btn" type="button">Discard</button>
                <button className="create-btn" type="submit">
                  Save Changes
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
