import { Avatar } from "antd";
import React,{useState} from "react";
import image1 from "../../../Admin/Assets/Image/Recriuiter/recruiter1.png"
import { CiFaceSmile } from "react-icons/ci";
import { MdSend } from "react-icons/md";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { scaleLinear } from "d3-scale";
import WorldGeoJson from "../../Assets/jsons/ne_10m_admin_0_countries.json"; 


const data = [
    { state: "Virginia", value: 50, color: "#ff4d4d" }, // Red
    { state: "Florida", value: 50, color: "#00e6e6" },  // Cyan
  ];
  const colorScale = scaleLinear()
  .domain([0, 100])
  .range(["#ffedea", "#ff5233"]); // Adjust color range as needed


export const DashboardTeamNotification = () => {
    const [tooltipContent, setTooltipContent] = useState("");
    const stateColors = {
      Virginia: "#ff4d4d",
      Florida: "#00e6e6",
    };
  return (
    <div className="row g-4 mb-4">
      <div className="col-lg-4">
        <div className="recruiter-dashboard-card p-4">
          <div className="recruiter-dashboard-title d-flex justify-content-between">
            <h2 className="card-title mb-4">Team Notifications</h2>
            <a href="" >See All</a>
          </div>
          <div className="recruiter-team-notification d-flex justify-content-between">
            <div className="d-flex gap-2">
            <Avatar src={image1}/>
            <div>
                <h2>Cluada</h2>
                <div className="d-flex gap-1">
                    <CiFaceSmile/>
                    <span>Task Update</span>
                    <div className="recruiter-horizontal-line"></div>
                    <span>2h Ago</span>
                </div>
            </div>
            </div>
            <div>
                <button className="recruiter-notification-send"><MdSend/></button>
            </div>
        </div>
        <div className="recruiter-team-notification d-flex justify-content-between mt-2">
            <div className="d-flex gap-2">
            <Avatar src={image1}/>
            <div>
                <h2>Cluada</h2>
                <div className="d-flex gap-1">
                    <CiFaceSmile/>
                    <span>Task Update</span>
                    <div className="recruiter-horizontal-line"></div>
                    <span>2h Ago</span>
                </div>
            </div>
            </div>
            <div>
                <button className="recruiter-notification-send"><MdSend/></button>
            </div>
        </div>
        <div className="recruiter-team-notification d-flex justify-content-between mt-2">
            <div className="d-flex gap-2">
            <Avatar src={image1}/>
            <div>
                <h2>Cluada</h2>
                <div className="d-flex gap-1">
                    <CiFaceSmile/>
                    <span>Task Update</span>
                    <div className="recruiter-horizontal-line"></div>
                    <span>2h Ago</span>
                </div>
            </div>
            </div>
            <div>
                <button className="recruiter-notification-send"><MdSend/></button>
            </div>
        </div>
        <div className="recruiter-team-notification d-flex justify-content-between mt-2">
            <div className="d-flex gap-2">
            <Avatar src={image1}/>
            <div>
                <h2>Cluada</h2>
                <div className="d-flex gap-1">
                    <CiFaceSmile/>
                    <span>Task Update</span>
                    <div className="recruiter-horizontal-line"></div>
                    <span>2h Ago</span>
                </div>
            </div>
            </div>
            <div>
                <button className="recruiter-notification-send"><MdSend/></button>
            </div>
        </div>
        </div>
        
      </div>
      <div className="col-lg-8">
      <div className="recruiter-dashboard-card p-4">
          <div className="recruiter-dashboard-title d-flex justify-content-between">
            <h2 className="card-title mb-4">Jobs by Region (US)</h2>
          </div>
      <ComposableMap projection="geoAlbersUsa" width={1300} height={500}>
        <Geographies geography={WorldGeoJson}>
          {({ geographies }) =>
            geographies
              // Filter only the United States
              .filter((geo) => geo.properties.NAME === "United States of America")
              .map((geo) => {
                const countryName = geo.properties.NAME;
                const countryData = data.find((d) => d.country === countryName);
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={countryData ? countryData.color : "#ce1b28"}
                    onMouseEnter={() => {
                      setTooltipContent(`${countryName} - ${countryData ? countryData.value : "0"}%`);
                    }}
                    onMouseLeave={() => {
                      setTooltipContent("");
                    }}
                  />
                );
              })
          }
        </Geographies>
      </ComposableMap>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "10px",gap:"10px",alignItems:"center" }}>
        <div
          style={{ backgroundColor: "#ff4d4d", padding: "5px", borderRadius: "50%",width:"15px",height:"15px" }}>
        </div>
       <h6 className="mb-0">USA 50%</h6> 
      </div>
      </div>
    </div>
    </div>
  );
};
