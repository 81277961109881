import React, { useState } from 'react';
import { DatePicker } from 'antd';
import DashboardCards from './DashboardCards';
import { useNavigate } from 'react-router-dom';
import DashboardGraphs from './DashboardGraphs';
import { DashboardTeamNotification } from './DashboardTeamNotification';
import DashboardApplicantsStatus from './DashboardApplicantStatus';
import DashboardHiringStatus from "./DashboardHiringStatus"
const DashboardOverview = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="recruiter-dashboard-section">
        <div className="container">
          <DashboardCards />
          <DashboardGraphs />
          <DashboardApplicantsStatus/>
          < DashboardHiringStatus/>
        </div>
      </div>
    </>
  );
};

export default DashboardOverview;