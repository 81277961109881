import React,{useState} from 'react';
import SidebarRecruiterLead from '../../Layout/Sidebar/Index';
import HeaderRecruiterLead from '../../Layout/Header/Index';
import TeamChats from '../../Components/Teams/TeamChats';
import "./teams.css"
const RecruiterLeadTeamChatsPage = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const handleCollapseToggle = (collapsedState) => {
      setIsCollapsed(collapsedState);
    };
  
    return (
        <>
             <SidebarRecruiterLead onCollapseToggle={handleCollapseToggle}/>
             <HeaderRecruiterLead onCollapseToggle={handleCollapseToggle} isCollapsed={isCollapsed}/>
            <div className={`main-wrapper ${isCollapsed ? "collapsed" : ""}`}>
                <TeamChats/>
            </div>
        </>
    )
}

export default RecruiterLeadTeamChatsPage;