import React,{useEffect,useState} from "react";
// eslint-disable-next-line
import { Form, Input, DatePicker, Select } from "antd";
// eslint-disable-next-line
import { TbEdit } from "react-icons/tb";
import DefaultUser from '../../Assets/Image/Recriuiter/DefaultUser.png';
import "react-international-phone/style.css";
import { PhoneInput } from "react-international-phone";
import Instance from "../../../Instance";
import { useParams } from "react-router-dom";
import { showErrorMessage } from "../../../globalConstant";
import { useNavigate } from "react-router-dom";

export const ViewClient = () => {
  const {id}=useParams();
  const [clientData, setClientData] = useState({});
  const [form] = Form.useForm();
  const navigate=useNavigate();
  const fetchClientDetails = async () => {
    const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));
    try {
      const response = await Instance.get(`/getClientByClientId/${id}`, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`
        }
      });
      if (response.status === 200) {
        setClientData(response.data);
        form.setFieldsValue({
          hospitalName: response.data?.client?.hospitalName,
          clientId: response.data?.client?.clientId,
          email: response.data?.client?.hospitalEmail,
          mobileNumber: response.data?.client?.mobileNumber,
          contactName: response.data?.client?.contactPersonName,
          contactMail: response.data?.client?.contactPersonEmail,
          hospitalEstablishedYear: response.data?.client?.hospitalEstablishedYear,
          website: response.data?.client?.website,
          preferredJobCategories: response.data?.client?.preferredJobCategories||[],
          preferredShifts: response.data?.client?.preferredShifts || [],
          taxPercentage: response.data?.client?.taxPercentage,
          benefits: response.data?.client?.benefits || [],
          state: response.data?.client?.state,
          city: response.data?.client?.city,
          zipCode: response.data?.client?.zipCode,
          certificates:response.data?.client?.certificatesList || [],
        });
      }
    } catch (error) {
      console.error(error);
      showErrorMessage('Something went wrong');
    }
  };

  useEffect(() => {
    fetchClientDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="personal-information-section">
        <div className="container">
          <h4>Client Info</h4>
          <Form layout="vertical" form={form}>
            <div className="row mt-4">
              <div className="profile-icon-section">
                <img src={DefaultUser} alt="Profile" className="profile-image" />
              
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6 mb-1">
                <Form.Item
                  label="Hospital Name"
                  name="hospitalName"
                  
                >
                  <Input placeholder="Enter Hospital Name" readOnly />
                </Form.Item>
              </div>
              <div className="col-md-6 mb-1">
                <Form.Item label="Client Id" name="clientId" >
                  <Input placeholder="Enter Client Id" readOnly/>
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-1">
                <Form.Item label="Hospital Email Id" name="email" readOnly>
                  <Input placeholder="Hospital Email"  />
                </Form.Item>
              </div>
              <div className="col-md-6 mb-1">
                <label htmlFor="inputPhone" className="form-label" name="mobileNumber" style={{
                  display: "flex",
                  marginBottom: "0",
                }}>
                  <span style={{ color: "#ff4d4f", marginRight: "4px", marginBottom: "8px" }}>*</span> Mobile Number
                </label>
                <PhoneInput
                  defaultCountry="us"
                 value={clientData?.client?.mobileNumber}
                  inputStyle={{
                    width: "100%",
                  }}
                  disabled
                  
                />
              </div>
            </div>
            
            <div className="row">
              <div className="col-md-6 mb-1">
                <Form.Item label="Contact Person Name" name="contactName">
                <Input placeholder="Enter Contact Person Name" readOnly/>
                </Form.Item>
              </div>
              <div className="col-md-6 mb-1">
                <Form.Item label="Contact Person Email" name="contactMail">
                <Input placeholder="Enter Contact Person Mail" readOnly/>
                </Form.Item>
              </div>
            
            </div>
         
            
            <div className="row">
              <div className="col-md-6 mb-1">
                <Form.Item label="Hospital Established Year"  name="hospitalEstablishedYear">
                  <Input placeholder="Enter Established Year" readOnly />
                </Form.Item>
              </div>
              <div className="col-md-6 mb-1">
                <Form.Item label="Website" name="website">
                  <Input placeholder="Enter Website URL" readOnly/>
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <Form.Item label="Preferred Job Categories" name="preferredJobCategories">
                  <Select placeholder="Select Job Categories" mode="multiple"  open={false} disabled />
                </Form.Item>
              </div>
              <div className="col-md-6 mb-3">
                <Form.Item label="Preferred Shifts"  name="preferredShifts">
                  <Select placeholder="Select Preffered Shifts" mode="multiple"  open={false} disabled/>
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <Form.Item label="Tax percentage"  name="taxPercentage">
                  <Input placeholder="Add Tax percentage %" type="number" readOnly/>
                </Form.Item>
              </div>
              <div className="col-md-6 mb-3">
                <Form.Item label="Benefits">
                  <Select placeholder="Select Benefits" mode="multiple" open={false} disabled />
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <Form.Item label="State" name="state">
                  <Select placeholder="Select State" open={false} disabled />
                </Form.Item>
              </div>
              <div className="col-md-6 mb-3">
                <Form.Item label="City"  name="city">
                  <Select placeholder="Select City" open={false} disabled/>
                </Form.Item>
              </div>
              <div className="col-md-6 mb-3">
                <Form.Item label="Zip Code" name="zipCode">
                  <Input placeholder="Enter Zip Code" readOnly/>
                </Form.Item>
              </div>
              <div className="col-md-6 mb-3">
                <Form.Item label="NDA & Other Certificate" name="certificates">
                  <Input placeholder=" NDA & Other Certificate" readOnly/>
                </Form.Item>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-12 text-start">
                
                <button className="create-btn" type="submit" onClick={()=>navigate('/admin/top-clients')}>
                 Back
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
  )
}
