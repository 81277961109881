import React, { useEffect, useMemo, useState } from "react";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import { Input, Pagination, Form, Modal, DatePicker, Button, Select } from "antd";
import { TbArrowsSort } from "react-icons/tb";
import { showErrorMessage } from "../../../globalConstant";
import Instance from "../../../Instance";
import Loader from "../../../Loader";
import RecruiterCard from "./RecruiterCard";

export const AllRecruiter = () => {
  const [form] = Form.useForm();
  const [teamList, setTeamList] = useState([]);
  const [jobIdList, setJobIdList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [recruitersList, setRecruitersList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAscendingSort, setIsAcendingSort] = useState(true);
  const [filterParameters, setFilterParameters] = useState({});
  const [dataSource, setDataSource] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6;
  const startEntry = (currentPage - 1) * pageSize + 1;
  const endEntry = Math.min(currentPage * pageSize, dataSource.length);
  const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));

  const paginatedRecruiters = useMemo(() => {
    if (searchText?.trim() === '') return dataSource?.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    const filteredRecruiters = dataSource?.filter((recruiter) => {
      return `${recruiter?.firstName}${recruiter?.lastName}${recruiter?.email}`.toLowerCase().includes(searchText?.toLowerCase())
    })
    return filteredRecruiters?.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  }, [dataSource, currentPage, searchText]);

  const fetchRecruiterData = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get("/getAllRecruiters", {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        },
      });
      if (response?.status === 200) {
        setRecruitersList(response?.data?.recruiters);
        setDataSource(response?.data?.recruiters);
      }
    } catch (error) {
      console.error(error);
      showErrorMessage(error?.response?.data?.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  }

  const fetchTeams = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get('/getAllTeams', {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`
        }
      });
      if (response.status === 200) {
        setTeamList(response?.data?.teams);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const fetchAllJobIds = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get('/getAllJobIds', {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`
        }
      });
      if (response.status === 200) {
        setJobIdList(response.data.jobIds);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchTeams();
    fetchAllJobIds();
    fetchRecruiterData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSorting = () => {
    try {
      let sortedData;
      if (isAscendingSort) {
        sortedData = [...dataSource]?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      } else {
        sortedData = [...dataSource]?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      }
      setDataSource(sortedData);
      setIsAcendingSort(!isAscendingSort);
    } catch (error) {
      console.error(error);
    }
  }

  const handleApplyFilters = () => {
    try {
      let dataSource = [...recruitersList];
      if (filterParameters?.associatedTeam && filterParameters?.associatedTeam?.length > 0) {
        dataSource = dataSource?.filter((item) => filterParameters?.associatedTeam?.includes(item?.associatedTeam));
      }
      if (filterParameters?.assignedJobIds && filterParameters?.assignedJobIds?.length > 0) {
        dataSource = dataSource?.filter((item) =>
          item?.assignedJobIds?.some((id) => filterParameters?.assignedJobIds?.includes(id.toString()))
        );
      }
      if (filterParameters?.joiningDate) {
        let date = new Date(filterParameters?.joiningDate);
        dataSource = dataSource?.filter(item => {
          let curDate = new Date(item?.joiningDate);
          return curDate.getDate() === date.getDate() && curDate.getMonth() === date.getMonth() && curDate.getFullYear() === date.getFullYear(

          )
        })
      }
      setDataSource(dataSource);
    } catch (error) {
      console.error(error);
    } finally {
      setIsModalVisible(false);
    }
  }

  const handleModalClose = () => {
    form.resetFields();
    setFilterParameters({});
    setIsModalVisible(false);
    setDataSource(recruitersList);
  }

  const teamOptions = teamList.map((team) => ({
    label: team?.teamName,
    value: team?._id
  }));

  const jobIdsOption = jobIdList?.map((item) => ({
    label: item?.jobId,
    value: item?._id
  }));

  return (
    <>
      {isLoading && <Loader />}
      <div className="all-recruiter-section">
        <div>
          <div className="row">
            <div className="d-flex justify-content-between align-items-center align-content-center">
              <div className="d-flex gap-4 align-items-center">
                <h2 className="">All Recruiter</h2>
                <Input
                  placeholder="Search..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  className="search-input-table me-2"
                  prefix={<SearchOutlined />}
                />
              </div>
              <div className="search-table-container gap-2">
                <button onClick={handleSorting}>
                  <span>Sort</span>{" "}
                  <TbArrowsSort style={{ fontWeight: "bold", fontSize: "16px" }} />
                </button>
                <button onClick={() => setIsModalVisible(true)}>
                  <FilterOutlined style={{ fontWeight: "bold", fontSize: "16px" }} />{" "}
                  <span>Filter</span>
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-4 all-recruiter-row">
            {paginatedRecruiters.map((recruiter, index) => (
              <RecruiterCard key={index} {...recruiter} />
            ))}
          </div>
          <div className="d-flex justify-content-between mt-4 pagination-div">
            <h5>Showing {startEntry} to {endEntry} of {dataSource?.length} entries</h5>
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={dataSource?.length}
              onChange={(page) => setCurrentPage(page)}
              showSizeChanger={false}
            />
          </div>
        </div>
      </div>
      <Modal
        title="Filter"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        className="filter-modal"
        footer={null}
      >
        <div className="filter-tabs">
          <Button>Associated Team</Button>
          <Button>Assigned Job Ids</Button>
          <Button>Joining Date</Button>
        </div>

        <div className="filter-form mt-4">
          <Form layout="vertical " className="filter-form" form={form}>
            <div className="col-md-12">
              <Form.Item
                name={"associatedTeam"}
                label="Associated Team"
              >
                <Select placeholder="Select Associated Team" options={teamOptions} onChange={(e) => setFilterParameters({ ...filterParameters, associatedTeam: e })} mode="multiple" />
              </Form.Item>
            </div>
            <div className="col-md-12" >
              <Form.Item
                label="Assinged Job Ids"
                name={"assignedJobIds"}
              >
                <Select placeholder="Select Job Ids" options={jobIdsOption} onChange={(e) => setFilterParameters({ ...filterParameters, assignedJobIds: e })} mode="multiple" />
              </Form.Item>
            </div>
            <div className="col-md-12">
              <Form.Item
                label="Joining Date"
                name={"joiningDate"}
              >
                <DatePicker
                  placeholder="Select Joining Date"
                  format="DD/MM/YYYY"
                  className="form-control"
                  onChange={(e) => setFilterParameters({ ...filterParameters, joiningDate: e })}
                />
              </Form.Item>
            </div>
          </Form>
        </div>

        <div className="modal-actions d-flex justify-content-between">
          <button onClick={handleModalClose} className="cancel-btn" type="button">
            Clear All
          </button>
          <button className="create-btn" onClick={handleApplyFilters} type="button">
            Apply Filters
          </button>
        </div>
      </Modal>
    </>
  );
};
