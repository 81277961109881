import React from "react";
import { Modal, Form, Input } from "antd";

const ViewReferralModal = ({ visible, onCancel }) => {
    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title="Referal Details"
            footer={null}
        >
            <Form layout="vertical">
            <Form.Item label="User Name">
                    <Input  readOnly placeholder="user name" />
                </Form.Item>
                <Form.Item label="Email">
                    <Input  readOnly placeholder="email"/>
                </Form.Item>
                <Form.Item label="Admin Comment">
                    <Input.TextArea  readOnly placeholder="Admin Comment" />
                </Form.Item>
                <Form.Item label="Amount">
                    <Input  readOnly placeholder="amount"/>
                </Form.Item>
                <Form.Item label="Details Submission">
                    <Input  readOnly placeholder="detials"/>
                </Form.Item>
                <Form.Item label="Status">
                    <Input  readOnly placeholder="status" />
                </Form.Item>
                <Form.Item label="Date">
                    <Input  readOnly placeholder="date"/>
                </Form.Item>
               
            </Form>
        </Modal>
    );
};

export default ViewReferralModal;
