import React, { useEffect, useMemo, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Table } from "antd";
import { TbArrowsSort } from "react-icons/tb";
import Instance from "../../../Instance";
import { IMG_URL, showErrorMessage } from "../../../globalConstant";
import HospitalImg from '../../../Admin/Assets/Image/Recriuiter/Hospital.png';
import { useNavigate } from "react-router-dom";
export const TopClients = () => {
  const [clients, setClients] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isAscendingSort, setIsAcendingSort] = useState(false);
  const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));
const navigate=useNavigate();
  const dataSource = useMemo(() => {
    if (searchText.trim() === '') return clients;
    return clients.filter((client) =>
      `${client.hospitalName}{}${client.hospitalEmail}${client.contactPersonName}`.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [searchText, clients]);

  const fetchTopClients = async () => {
    try {
      const response = await Instance.get('/getTopClients', {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`
        }
      });
      if (response.status === 200) {
        setClients(response.data.clients);
      }
    } catch (error) {
      console.error(error);
      showErrorMessage('Something went wrong');
    }
  }

  useEffect(() => {
    fetchTopClients();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      title: "Profile",
      render: (text, record) => (
        <img src={record?.hospitalImage ? `${IMG_URL}/${record?.hospitalImage}` : HospitalImg} style={{ height: "50px", width: "50px", borderRadius: "50%" }} className="me-2" />
      ),
    },
    {
      title: "Client Name",
      render: (text, record) => (
        <div>
          {record?.hospitalName}
        </div>
      )
    },
    {
      title: "Client Id",
      render: (text, record) => (
        <div>
          {record?.clientId}
        </div>
      )
    },
    {
      title: "Hospital Email",
      render: (text, record) => (
        <div>
          {record?.hospitalEmail}
        </div>
      )
    },
    {
      title: "Phone Number",
      render: (text, record) => (
        <div>
          {record?.mobileNumber}
        </div>
      )
    },
    {
      title: "Contact Person Name",
      render: (text, record) => (
        <div>
          {record?.contactPersonName}
        </div>
      )
    },
    {
      title: "Contact Person Email",
      render: (text, record) => (
        <div>
          {record?.contactPersonEmail}
        </div>
      )
    },
    {
      title: "Total Jobs",
      render: (text, record) => (
        <div>
          {record?.totalJobs || 'N/A'}
        </div>
      ),
    },
    {
      title: "Hired Employees",
      render: (text, record) => (
        <div>
          {record?.totalHired || 'N/A'}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text,record) => (
        <button
          className="d-flex align-items-center justify-content-end cancel-btn"
          onClick={() => navigate(`/recruiter/view-client/${record.clientId}`)}
        >
          View
        </button>
      ),
    },
  ];

  const handleSorting = () => {
    try {
      let sortedData;
      if (isAscendingSort) {
        sortedData = [...clients]?.sort((a, b) => b.totalJobs - a.totalJobs);
      } else {
        sortedData = [...clients]?.sort((a, b) => a.totalJobs - b.totalJobs);
      }
      setClients(sortedData);
      setIsAcendingSort(!isAscendingSort);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="all-recruiter-section">
      <div>
        <div className="row">
          <div className="d-flex justify-content-between align-items-center align-content-center mb-4">
            <div className="d-flex gap-4 align-items-center">
              <h2>Top Client</h2>
              <Input
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="search-input-table"
                prefix={<SearchOutlined />}
              />
            </div>
            <div className="search-table-container gap-2">
              <button className="table-action-btn" onClick={handleSorting}>
                <span>Sort</span>{" "}
                <TbArrowsSort style={{ fontWeight: "bold", fontSize: "16px" }} />
              </button>
            </div>
          </div>
        </div>

        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          className="applied-applicants-table"
          rowKey={(row) => row._id}
        />
      </div>
    </div>
  );
};
