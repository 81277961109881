import React, { useState, useEffect } from 'react';
import { Modal, Button, Input, DatePicker, Form, Select } from 'antd';
import Instance from '../../../Instance';
import { showErrorMessage, showSuccessMessage } from '../../../globalConstant';
import Loader from '../../../Loader';
import { useDispatch } from 'react-redux';
import { updateTask } from '../../../Admin/features/taskSlice';
import dayjs from 'dayjs';

const EditTaskPopup = ({ isVisible, onClose, data }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [clients, setClients] = useState([]);
    const [members, setMembers] = useState([]);
    const [formData, setFormData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const loggedInUserInfo = JSON.parse(localStorage.getItem('loggedInUserInfo'));

    const fetchMembers = async () => {
        try {
            setIsLoading(true);
            const response = await Instance.get(`/getAllMembers`, {
                headers: {
                    Authorization: `Bearer ${loggedInUserInfo?.token}`
                }
            });
            if (response.status === 200) {
                setMembers(response?.data?.members);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    const fetchTotalClients = async () => {
        try {
            setIsLoading(true);
            const response = await Instance.get(`/getAllClients`, {
                headers: {
                    Authorization: `Bearer ${loggedInUserInfo?.token}`,
                }
            });
            if (response.status === 200) {
                const dataSource = response?.data?.clients?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setClients(dataSource);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchMembers();
        fetchTotalClients();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!data) return;
        setFormData({ ...data, associatedClient: data?.associatedClient?._id });
        form.setFieldsValue({
            taskName: data?.taskName,
            taskPriority: data?.taskPriority,
            taskDesc: data?.taskDesc,
            projectName: data?.projectName,
            associatedClient: { label: data?.associatedClient?.hospitalName, value: data?.associatedClient?._id },
            startDate: dayjs(data?.startDate),
            endDate: dayjs(data?.endDate)
        });
    }, [data]);

    useEffect(() => {
        if (!data || members.length === 0) return;
        let dataSource = members;
        let reqMembers = data?.members?.map(item => item?.memberId?._id?.toString());
        dataSource = dataSource?.filter(item => reqMembers?.includes(item?._id?.toString()));
        dataSource = dataSource?.map(item => ({
            label: `${item?.firstName} ${item?.lastName}`,
            value: JSON.stringify(item)
        }));
        form.setFieldsValue({ members: dataSource });
        setFormData((prevVal)=>({
            ...prevVal,
            members: dataSource?.map(item=> item?.value)
        }));
    }, [data, members]);

    const HandleFormSubmit = async () => {
        try {
            setIsLoading(true);
            let members = [];
            for (let i = 0; i < formData?.members?.length; i++) {
                const member = JSON.parse(formData?.members[i]);
                members.push({
                    memberId: member?._id,
                    role: member?.role === 'ADMIN' ? 'Admin' : 'Recruiter'
                });
            }

            formData['members'] = members;
            const response = await Instance.put(`/editTaskById/${data?._id}`, formData, {
                headers: {
                    Authorization: `Bearer ${loggedInUserInfo?.token}`
                }
            });
            if (response.status === 200) {
                dispatch(updateTask(response?.data?.updatedTask));
                showSuccessMessage('Task updated successfully');
                onClose();
            }
        } catch (error) {
            console.error(error);
            showErrorMessage('Failed to update task');
            onClose();
        } finally {
            setIsLoading(false);
        }
    };

    const clientOptions = clients?.map((item) => ({
        label: `${item?.hospitalName}`,
        value: item?._id,
    }));

    const memberOptions = members?.map((item) => ({
        label: `${item?.firstName} ${item?.lastName}`,
        value: JSON.stringify(item)
    }));

    return (
        <>
            {isLoading && <Loader />}
            <Modal
                title="Edit Task"
                open={isVisible}
                onCancel={() => {
                    setFormData({});
                    form.setFieldsValue({});
                    onClose();
                }}
                footer={null}
                width={700}
            >
                <hr />
                <Form layout="vertical" onFinish={HandleFormSubmit} form={form}>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <Form.Item name="taskName" label="Task Name" style={{ flex: 1 }} rules={[{ required: true, message: 'Please Enter Task Name' }]}>
                            <Input placeholder="Enter Task Name" onChange={(e) => setFormData({ ...formData, taskName: e.target.value })} />
                        </Form.Item>
                        <Form.Item name="taskPriority" label="Task Priority" style={{ flex: 1 }} rules={[{ required: true, message: 'Please Select Task Priority' }]}>
                            <Select placeholder="Select Task Priority" options={[{ value: "Low", label: "Low" }, { value: "Medium", label: "Medium" }, { value: "High", label: "High" }]} onChange={(e) => setFormData({ ...formData, taskPriority: e })} />
                        </Form.Item>
                    </div>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <Form.Item name="taskDesc" label="Task Description" style={{ flex: 1 }}>
                            <Input placeholder="Enter Task Description" onChange={(e) => setFormData({ ...formData, taskDesc: e.target.value })} />
                        </Form.Item>
                        <Form.Item name="projectName" label="Project Name" style={{ flex: 1 }} rules={[{ required: true, message: 'Please Enter Project Name' }]}>
                            <Input placeholder="Enter Project Name" onChange={(e) => setFormData({ ...formData, projectName: e.target.value })} />
                        </Form.Item>
                    </div>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <Form.Item name="associatedClient" label="Associated Client" style={{ flex: 1 }}>
                            <Select placeholder="Select Client" options={clientOptions} onChange={(e) => setFormData({ ...formData, associatedClient: e })} />
                        </Form.Item>
                        <Form.Item name="members" label="Task Members" style={{ flex: 1 }} rules={[{ required: true, message: 'Please Select Members' }]}>
                            <Select placeholder="Select Members" options={memberOptions} onChange={(e) => setFormData({ ...formData, members: e })} mode='multiple' value={formData?.members || []} />
                        </Form.Item>
                    </div>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <Form.Item name="startDate" label="Starts At" style={{ flex: 1 }} rules={[{ required: true, message: 'Please Select Start Date' }]}>
                            <DatePicker style={{ width: '100%' }} format="MM/DD/YYYY" onChange={(e) => setFormData({ ...formData, startDate: e })} />
                        </Form.Item>
                        <Form.Item name="endDate" label="Ends At" style={{ flex: 1 }} rules={[{ required: true, message: 'Please Select End Date' }]}>
                            <DatePicker style={{ width: '100%' }} format="MM/DD/YYYY" onChange={(e) => setFormData({ ...formData, endDate: e })} />
                        </Form.Item>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                        <Button onClick={onClose} htmlType="button" style={{ borderColor: "var(--maroon-color)", color: "var(--maroon-color)" }}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" style={{ backgroundColor: "var(--maroon-color)", borderColor: "var(--maroon-color)", color: "var(--white-color)" }}>
                            Edit
                        </Button>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

export default EditTaskPopup;
