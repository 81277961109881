// Layout.js
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Loader from './Loader';

const Routing = () => {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1000);
        return () => clearTimeout(timer);
    }, [location]);

    return (
        <>
            {isLoading && <Loader />}
            <Outlet />
        </>
    );
};

export default Routing;
