import React from "react";
import Routing from "./Routing";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Login from "./Admin/Components/Auth/Login";

// Admin Protected Routes
import ProtectAdmin from "./Admin/Components/Auth/ProtectAdmin";
import AdminDashboard from "./Admin/Pages/Dashboard/AdminDashboard";
import AdminAddRecruiter from "./Admin/Pages/Recruiter/AddRecruiterPage";
import AdminEditRecruiter from "./Admin/Pages/Recruiter/EditRecruiterPage";
import AdminAllRecruiters from "./Admin/Pages/Recruiter/AllRecruitersPage";
import AdminRecruiterProfile from "./Admin/Pages/Recruiter/RecruiterProfilePage";

import AdminCreateJob from "./Admin/Pages/Jobs/CreateJobPage";
import AdminAddNewJob from "./Admin/Pages/Jobs/AddNewJobPage";
import AdminCreateTemplateJob from "./Admin/Pages/Jobs/CreateTemplateJobPage";
import AdminOnGoingJobs from "./Admin/Pages/Jobs/OnGoingJobsPage";
import AdminPausedJobsListPage from "./Admin/Pages/Jobs/PausedJobsPage";
import AdminClosedJobs from "./Admin/Pages/Jobs/ClosedJobsPage";
import AdminMyJobs from "./Admin/Pages/Jobs/MyJobsPage";
import AdminEditJobPage from "./Admin/Pages/Jobs/EditJobPage";
import AdminJobDetailPage from "./Admin/Pages/Jobs/JobDetailPage";

import AdminApplicantsPage from "./Admin/Pages/Applicants/ApplicantsPage";
import AdminViewApplicantsPage from "./Admin/Pages/Applicants/ViewApplicantsPage";

import AdminAddNewClient from "./Admin/Pages/Clientss/AddNewClientPage";
import AdminTopClients from "./Admin/Pages/Clientss/TopClientsPage";
import AdminEditClient from "./Admin/Pages/Clientss/EditClientPage";

import AdminTotalJobs from "./Admin/Pages/DataBase/TotalJobsPage";
import AdminTotalApplicants from "./Admin/Pages/DataBase/TotalApplicantsPage";
import AdminTotalClients from "./Admin/Pages/DataBase/TotalClientsPage";
import AdminViewClientsPage from "./Admin/Pages/Clientss/ViewClientPage";
import AdminTasks from "./Admin/Pages/Task/TasksPage";

import AdminAllTeams from "./Admin/Pages/Teams/AllTeamsPage";
import AdminTeamDetail from "./Admin/Pages/Teams/TeamDetailPage";

import AdminSetting from "./Admin/Pages/Settings/SettingsPage";
import AdminHelpAndSupport from "./Admin/Pages/HelpSupport/HelpAndSupportPage";
import AdminViewTicket from "./Admin/Pages/HelpSupport/ViewTicketsPage";

import AdminTeamChat from "./Admin/Pages/Teams/TeamChat";
import AdminReferralProgramPage from "./Admin/Pages/Referral/ReferralProgramPage";

// Recruiter Protected Routes
import ProtectRecruiter from "./Recruiter/Components/Auth/ProtectRecruiter";
import RecruiterDashboard from "./Recruiter/Pages/Dashboard/Dashboard";

import RecruiterCreateJobPage from "./Recruiter/Pages/Jobs/CreateJobPage";
import RecruiterCreateTemplateJobPage from "./Recruiter/Pages/Jobs/CreateTemplateJobPage";
import RecruiterAddNewJobPage from "./Recruiter/Pages/Jobs/AddNewJob";
import RecruiterOnGoingJobListPage from "./Recruiter/Pages/Jobs/OnGoingJobListPage";
import RecruiterPausedJobsListPage from "./Recruiter/Pages/Jobs/PauseJobsListPage";
import RecruiterClosedJobsListPage from "./Recruiter/Pages/Jobs/ClosedJobsListPage";
import RecruiterMyJobListPage from "./Recruiter/Pages/Jobs/MyJobsListPage";
import RecruiterEditJobPage from "./Recruiter/Pages/Jobs/EditJobPage";
import RecruiterJobDetailPage from "./Recruiter/Pages/Jobs/JobDetailPage";

import RecruiterApplicantsPage from "./Recruiter/Pages/Applicants/ApplicantsPage";
import RecruiterTopClientsPage from "./Recruiter/Pages/Clients/TopClientsPage";

import RecruiterTotalJobsPage from "./Recruiter/Pages/Database/TotalJobs";
import RecruiterTotalApplicantsPage from "./Recruiter/Pages/Database/TotalApplicants";
import RecruiterTotalClientsPage from "./Recruiter/Pages/Database/TotalClients";

import RecruiterTaskPage from "./Recruiter/Pages/Tasks/TaskPage";
import RecruiterMyTeamPage from "./Recruiter/Pages/Teams/MyTeamPage";
import RecruiterTeamChatsPage from "./Recruiter/Pages/Teams/TeamChatPage";

import RecruiterSettingsPage from "./Recruiter/Pages/Settings/SettingsPage";
import RecruiterHelpSupportPage from "./Recruiter/Pages/HelpAndSupport/HelpAndSupportPage";
import RecruiterViewTicketPage from "./Recruiter/Pages/HelpAndSupport/ViewTicketsPage";

// Recruiter Lead Protected Routes
import ProtectRecruiterLead from "./RecruiterLead/Components/Auth/ProtectRecruiterLead";
import RecruiterLeadDashboard from "./RecruiterLead/Pages/Dashboard/Dashboard";

import RecruiterLeadCreateJobPage from "./RecruiterLead/Pages/Jobs/CreateJobPage";
import RecruiterLeadCreateTemplateJobPage from "./RecruiterLead/Pages/Jobs/CreateTemplateJobPage";
import RecruiterLeadAddNewJobPage from "./RecruiterLead/Pages/Jobs/AddNewJob";
import RecruiterLeadOnGoingJobListPage from "./RecruiterLead/Pages/Jobs/OnGoingJobListPage";
import RecruiterLeadClosedJobsListPage from "./RecruiterLead/Pages/Jobs/ClosedJobsListPage";
import RecruiterLeadJobListPage from "./RecruiterLead/Pages/Jobs/MyJobsList";

import RecruiterLeadApplicantsPage from "./RecruiterLead/Pages/Applicants/Applicants";

import RecruiterLeadTopClientsPage from "./RecruiterLead/Pages/Clients/TopClients";
import RecruiterLeadTotalJobsPage from "./RecruiterLead/Pages/Database/TotalJobs";
import RecruiterLeadTotalApplicantsPage from "./RecruiterLead/Pages/Database/TotalApplicants";
import RecruiterLeadTotalClientsPage from "./RecruiterLead/Pages/Database/TotalClients";

import RecruiterLeadTaskPage from "./RecruiterLead/Pages/Tasks/RecruiterTask";
import RecruiterLeadMyTeamPage from "./RecruiterLead/Pages/Teams/MyTeamPage";
import RecruiterLeadTeamChatsPage from "./RecruiterLead/Pages/Teams/TeamChatPage";

import RecruiterLeadSettingsPage from "./RecruiterLead/Pages/Settings/SettingsPage";
import RecruiterLeadHelpSupportPage from "./RecruiterLead/Pages/HelpAndSupport/HelpAndSupportPage";
import RecruiterLeadViewTicketPage from "./RecruiterLead/Pages/HelpAndSupport/ViewTicketsPage";
import RecruiterLeadEditJobPage from "./RecruiterLead/Pages/Jobs/EditJobPage";

import RecruiterLeadJobDetailPage from "./RecruiterLead/Pages/Jobs/JobDetailPage";
import RecruiterLeadViewClientPage from "./RecruiterLead/Pages/Clients/ViewClientPage";
import RecruiterLeadViewApplicantsPage from "./RecruiterLead/Pages/Applicants/ViewApplicantsPage";
import RecruiterViewClientsPage from "./Recruiter/Pages/Clients/ViewClientsPage";
import RecruiterViewApplicantsPage from "./Recruiter/Pages/Applicants/ViewApplicationPage";
import AdminNotificationPage from "./Admin/Pages/Notifiactions/NotificationPage";
import RecruiterNotificationPage from "./Recruiter/Pages/Notifications/NotificationPage";
import RecruiterLeadNotificationPage from "./RecruiterLead/Pages/Notification/NotificationPage";

// Seo User Protected Routes
import ProtectSeoUser from "./SeoUser/Components/Auth/ProtectSeoUser";
import GeneralApplicantPage from "./SeoUser/Pages/GeneralApplicantsPortal";
import PostPage from "./SeoUser/Pages/PostPortal";
import SeoSettingsPage from "./SeoUser/Pages/SettingsPortal";
import HelpPortalPage from "./SeoUser/Pages/HelpPortal";
import SeoNotificationPage from "./SeoUser/Pages/Notification/NotificationPage";
import AddPostPage from "./SeoUser/Components/Post/AddPost";
import RecruiterLeadPausedJobListPage from "./RecruiterLead/Pages/Jobs/PauseJobListPage";
import EditPost from "./SeoUser/Components/Post/EditPost";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Routing />}>
          {/* Admin-Auth Routes */}
          <Route path="/" element={<Login />} />

          {/* Admin-Protected Routes */}
          <Route path="/admin/dashboard" element={<ProtectAdmin Component={AdminDashboard} />} />
          <Route path="/admin/add-recruiter" element={<ProtectAdmin Component={AdminAddRecruiter} />} />
          <Route path="/admin/edit-recruiter-profile/:id" element={<ProtectAdmin Component={AdminEditRecruiter} />} />
          <Route path="/admin/all-recruiters" element={<ProtectAdmin Component={AdminAllRecruiters} />} />
          <Route path="/admin/recruiter-profile/:id" element={<ProtectAdmin Component={AdminRecruiterProfile} />} />

          {/* Admin-Job-Routes */}
          <Route path="/admin/create-job" element={<ProtectAdmin Component={AdminCreateJob} />} />
          <Route path="/admin/create-new-job" element={<ProtectAdmin Component={AdminAddNewJob} />} />
          <Route path="/admin/create-template-job" element={<ProtectAdmin Component={AdminCreateTemplateJob} />} />
          <Route path="/admin/ongoing-jobs" element={<ProtectAdmin Component={AdminOnGoingJobs} />} />
          <Route path="/admin/paused-jobs" element={<ProtectAdmin Component={AdminPausedJobsListPage} />} />
          <Route path="/admin/closed-jobs" element={<ProtectAdmin Component={AdminClosedJobs} />} />
          <Route path="/admin/my-jobs" element={<ProtectAdmin Component={AdminMyJobs} />} />
          <Route path="/admin/edit-job/:id" element={<ProtectAdmin Component={AdminEditJobPage} />} />
          <Route path="/admin/view-job-details/:id" element={<ProtectAdmin Component={AdminJobDetailPage} />} />

          {/* Admin-Applicant-Routes */}
          <Route path='/admin/applicants' element={<ProtectAdmin Component={AdminApplicantsPage} />} />
          <Route path='/admin/view-applicants/:id' element={<ProtectAdmin Component={AdminViewApplicantsPage} />} />


          {/* Admin-Client-Routes */}
          <Route path="/admin/add-new-client" element={<ProtectAdmin Component={AdminAddNewClient} />} />
          <Route path="/admin/top-clients" element={<ProtectAdmin Component={AdminTopClients} />} />
          <Route path='/admin/edit-client/:id' element={<ProtectAdmin Component={AdminEditClient} />} />
          <Route path="/admin/view-client/:id" element={<ProtectAdmin Component={AdminViewClientsPage} />} />

          {/*Admin-Database-Routes */}
          <Route path="/admin/total-jobs" element={<ProtectAdmin Component={AdminTotalJobs} />} />
          <Route path="/admin/total-applicants" element={<ProtectAdmin Component={AdminTotalApplicants} />} />
          <Route path="/admin/total-clients" element={<ProtectAdmin Component={AdminTotalClients} />} />
          <Route path="/admin/tasks" element={<ProtectAdmin Component={AdminTasks} />} />

          {/*Admin-settings-Routes */}
          <Route path="/admin/settings" element={<ProtectAdmin Component={AdminSetting} />} />

          {/*Admin-help-support-Routes */}
          <Route path="/admin/help-support" element={<ProtectAdmin Component={AdminHelpAndSupport} />} />
          <Route path="/admin/view-ticket/:id" element={<ProtectAdmin Component={AdminViewTicket} />} />

          {/*Admin-Team-Routes */}
          <Route path="/admin/all-teams" element={<ProtectAdmin Component={AdminAllTeams} />} />
          <Route path="/admin/team-detail/:id" element={<ProtectAdmin Component={AdminTeamDetail} />} />
          <Route path="/team-chat" element={<ProtectAdmin Component={AdminTeamChat} />} />

          <Route path="/admin/referal-program" element={<ProtectAdmin Component={AdminReferralProgramPage} />} />


          {/*Admin-notifications */}
          <Route path="/admin/notifications" element={<ProtectAdmin Component={AdminNotificationPage} />} />

          {/*RECRUITER ROUTES*/}
          <Route path="/recruiter/dashboard" element={<ProtectRecruiter Component={RecruiterDashboard} />} />

          {/* Recruiter-Job-Routes */}
          <Route path="/recruiter/create-job" element={<ProtectRecruiter Component={RecruiterCreateJobPage} />} />
          <Route path="/recruiter/create-template-job" element={<ProtectRecruiter Component={RecruiterCreateTemplateJobPage} />} />
          <Route path="/recruiter/create-new-job" element={<ProtectRecruiter Component={RecruiterAddNewJobPage} />} />
          <Route path="/recruiter/ongoing-jobs" element={<ProtectRecruiter Component={RecruiterOnGoingJobListPage} />} />
          <Route path="/recruiter/paused-jobs" element={<ProtectRecruiter Component={RecruiterPausedJobsListPage} />} />
          <Route path="/recruiter/closed-jobs" element={<ProtectRecruiter Component={RecruiterClosedJobsListPage} />} />
          <Route path="/recruiter/my-jobs" element={<ProtectRecruiter Component={RecruiterMyJobListPage} />} />
          <Route path="/recruiter/edit-job/:id" element={<ProtectRecruiter Component={RecruiterEditJobPage} />} />
          <Route path="/recruiter/view-job-details/:id" element={<ProtectRecruiter Component={RecruiterJobDetailPage} />} />

          {/* Recruiter-Applicant-Routes */}
          <Route path='/recruiter/applicants' element={<ProtectRecruiter Component={RecruiterApplicantsPage} />} />
          <Route path='/recruiter/view-applicants/:id' element={<ProtectRecruiter Component={RecruiterViewApplicantsPage} />} />

          {/* Recruiter-Client-Routes */}
          <Route path="/recruiter/top-clients" element={<ProtectRecruiter Component={RecruiterTopClientsPage} />} />
          <Route path="/recruiter/view-client/:id" element={<ProtectRecruiter Component={RecruiterViewClientsPage} />} />

          {/*Recruiter-Database-Routes */}
          <Route path="/recruiter/total-jobs" element={<ProtectRecruiter Component={RecruiterTotalJobsPage} />} />
          <Route path="/recruiter/total-applicants" element={<ProtectRecruiter Component={RecruiterTotalApplicantsPage} />} />
          <Route path="/recruiter/total-clients" element={<ProtectRecruiter Component={RecruiterTotalClientsPage} />} />

          {/*Recruiter-task-Routes */}
          <Route path="/recruiter/tasks" element={<ProtectRecruiter Component={RecruiterTaskPage} />} />

          {/*Recruiter-team-Routes */}
          <Route path="/recruiter/my-team" element={<ProtectRecruiter Component={RecruiterMyTeamPage} />} />
          <Route path="/recruiter/team-chat" element={<ProtectRecruiter Component={RecruiterTeamChatsPage} />} />

          {/*Recruiter-settings-Routes */}
          <Route path="/recruiter/settings" element={<ProtectRecruiter Component={RecruiterSettingsPage} />} />

          {/*Recruiter-help-support-Routes */}
          <Route path="/recruiter/help-support" element={<ProtectRecruiter Component={RecruiterHelpSupportPage} />} />
          <Route path="/recruiter/view-ticket/:id" element={<ProtectRecruiter Component={RecruiterViewTicketPage} />} />

          {/*Recruiter-notifications */}
          <Route path="/recruiter/notifications" element={<ProtectRecruiter Component={RecruiterNotificationPage} />} />

          {/*RECRUITER LEAD ROUTES*/}
          <Route path="/recruiter-lead/dashboard" element={<ProtectRecruiterLead Component={RecruiterLeadDashboard} />} />

          {/* Recruiter-lead-Job-Routes */}
          <Route path="/recruiter-lead/create-job" element={<ProtectRecruiterLead Component={RecruiterLeadCreateJobPage} />} />
          <Route path="/recruiter-lead/create-template-job" element={<ProtectRecruiterLead Component={RecruiterLeadCreateTemplateJobPage} />} />
          <Route path="/recruiter-lead/create-new-job" element={<ProtectRecruiterLead Component={RecruiterLeadAddNewJobPage} />} />
          <Route path="/recruiter-lead/ongoing-jobs" element={<ProtectRecruiterLead Component={RecruiterLeadOnGoingJobListPage} />} />
          <Route path="/recruiter-lead/closed-jobs" element={<ProtectRecruiterLead Component={RecruiterLeadClosedJobsListPage} />} />
          <Route path="/recruiter-lead/my-jobs" element={<ProtectRecruiterLead Component={RecruiterLeadJobListPage} />} />
          <Route path="/recruiter-lead/edit-job/:id" element={<ProtectRecruiterLead Component={RecruiterLeadEditJobPage} />} />
          <Route path="/recruiter-lead/view-job-details/:id" element={<ProtectRecruiterLead Component={RecruiterLeadJobDetailPage} />} />
          <Route path="/recruiter-lead/paused-jobs" element={<ProtectRecruiterLead Component={RecruiterLeadPausedJobListPage} />} />

          {/* Recruiter-lead-Applicant-Routes */}
          <Route path='/recruiter-lead/applicants' element={<ProtectRecruiterLead Component={RecruiterLeadApplicantsPage} />} />
          <Route path='/recruiter-lead/view-applicants/:id' element={<ProtectRecruiterLead Component={RecruiterLeadViewApplicantsPage} />} />

          {/* Recruiter-lead-Client-Routes */}
          <Route path="/recruiter-lead/top-clients" element={<ProtectRecruiterLead Component={RecruiterLeadTopClientsPage} />} />
          <Route path="/recruiter-lead/view-client/:id" element={<ProtectRecruiterLead Component={RecruiterLeadViewClientPage} />} />

          {/*Recruiter-lead-Database-Routes */}
          <Route path="/recruiter-lead/total-jobs" element={<ProtectRecruiterLead Component={RecruiterLeadTotalJobsPage} />} />
          <Route path="/recruiter-lead/total-applicants" element={<ProtectRecruiterLead Component={RecruiterLeadTotalApplicantsPage} />} />
          <Route path="/recruiter-lead/total-clients" element={<ProtectRecruiterLead Component={RecruiterLeadTotalClientsPage} />} />

          {/*Recruiter-lead-task-Routes */}
          <Route path="/recruiter-lead/tasks" element={<ProtectRecruiterLead Component={RecruiterLeadTaskPage} />} />

          {/*Recruiter-lead-team-Routes */}
          <Route path="/recruiter-lead/my-team" element={<ProtectRecruiterLead Component={RecruiterLeadMyTeamPage} />} />

          <Route path="/recruiter-lead/team-chat" element={<ProtectRecruiterLead Component={RecruiterLeadTeamChatsPage} />} />

          {/*Recruiter-lead-settings-Routes */}
          <Route path="/recruiter-lead/settings" element={<ProtectRecruiterLead Component={RecruiterLeadSettingsPage} />} />

          {/*Recruiter-lead-help-support-Routes */}
          <Route path="/recruiter-lead/help-support" element={<ProtectRecruiterLead Component={RecruiterLeadHelpSupportPage} />} />
          <Route path="/recruiter-lead/view-ticket/:id" element={<ProtectRecruiterLead Component={RecruiterLeadViewTicketPage} />} />

          {/*Recruiter-notifications */}
          <Route path="/recruiter-lead/notifications" element={<ProtectRecruiterLead Component={RecruiterLeadNotificationPage} />} />


          {/* seo user routes */}
          <Route path="/seo-user/posts" element={<ProtectSeoUser Component={PostPage} />} />
          <Route path="/seo-user/add-post" element={<ProtectSeoUser Component={AddPostPage} />} />
          <Route path="/seo-user/edit-post/:id" element={<ProtectSeoUser Component={EditPost} />} />

          <Route path="/seo-user/general-applicants" element={<ProtectSeoUser Component={GeneralApplicantPage} />} />
          <Route path="/seo-user/settings" element={<ProtectSeoUser Component={SeoSettingsPage} />} />
          <Route path="/seo-user/help" element={<ProtectSeoUser Component={HelpPortalPage} />} />
          <Route path="/seo-user/notifications" element={<ProtectSeoUser Component={SeoNotificationPage} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
