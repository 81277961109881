import { useState, useEffect } from "react";
import "../layout.css";
// eslint-disable-next-line
import { FaRegBell, FaRegMessage } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { FaMoon, FaSun } from "react-icons/fa";
import Swal from "sweetalert2";
import logoutimg from "../../Assets/Logo/logo.png";
import { useNavigate } from "react-router-dom";
import { IMG_URL } from "../../../globalConstant";
import Instance from "../../../Instance";
import DefaultUser from "../../Assets/Image/Recriuiter/DefaultUser.png";
import { useSelector, useDispatch } from "react-redux";
import { setProfile } from "../../features/profileSlice";

const HeaderAdmin = ({isCollapsed}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const admin = useSelector(state => state?.profile?.profile);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));
  const notificationCount = useSelector((state) => state.notifications.unReadCount);

  useEffect(() => {
    const savedDarkMode = localStorage.getItem("darkMode");
    if (savedDarkMode === "true") {
      setIsDarkMode(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNotificationClick = () => {
    navigate('/admin/notifications');
  };

  const fetchAdminDetail = async () => {
    try {
      const response = await Instance.get(`/getAdminById/${loggedInUserInfo?.userId}`, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        }
      });

      if (response.status === 200) {
        let dataSource = response?.data?.admin;
        dispatch(setProfile(dataSource));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAdminDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add("dark-mode");
      localStorage.setItem("darkMode", "true");
    } else {
      document.documentElement.classList.remove("dark-mode");
      localStorage.setItem("darkMode", "false");
    }
  }, [isDarkMode]);

  const handlelogout = () => {
    Swal.fire({
      imageUrl: logoutimg,
      imageWidth: "160px",
      title: "Are you sure",
      text: "You want to Logout?",
      showCancelButton: true,
      confirmButtonColor: "#555",
      cancelButtonColor: "#ce1b28",
      confirmButtonText: "Yes, logout me!"
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("loggedInUserInfo")
        navigate("/")
      }
    })
  }

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleToggleChange = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  return (
    <div style={{ position: "sticky", top: "0", zIndex: "999" }}>
      <nav className={`navbar-header ${isCollapsed ? "collapsed" : ""}`}>
        {/*<div className="search-container">
          <FiSearch className="search-icon" />
          <input type="text" placeholder="Search..." className="search-input" />
        </div>*/}
        <div className="d-flex w-100 justify-content-end">
          <div className="d-flex align-items-center gap-2">
            <div className="toggle-container">
              <input
                type="checkbox"
                id="toggle"
                className="toggle-input"
                checked={isDarkMode}
                onChange={handleToggleChange}
              />
              <label htmlFor="toggle" className="toggle-label">
                <span className="toggle-button">
                  {isDarkMode ? <FaMoon className="toggle-icons" /> : <FaSun className="toggle-icons" />}
                </span>
              </label>
            </div>
            <button
              type="button"
              aria-controls="navbar-notification"
              aria-expanded="false"
              className="notification-button"
              onClick={handleNotificationClick}
            >
              <FaRegBell className="notification-icon" />
              <span className="notification-badge">{notificationCount}</span>
            </button>

            {/* <button
              type="button"
              aria-controls="navbar-notification"
              aria-expanded="false"
              className="notification-button"
            >
              <FaRegMessage className="notification-icon" />
              <span className="notification-badge">3</span>
            </button> */}

            <div className="d-flex align-items-center gap-2">
              <button
                className="user-image"
                type="button"
                aria-controls="user-menu"
                onClick={toggleDropdown}
                aria-expanded={isDropdownOpen}
              >
                <img
                  className="profile--icon"
                  src={admin?.profileImage ? `${IMG_URL}/${admin?.profileImage}` : DefaultUser}
                  alt={`${admin?.firstName} ${admin?.lastName}`}
                />
              </button>
              <div className="user-info">
                <span
                  style={{
                    color: "#ce1b28",
                    fontSize: "16px",
                    fontWeight: "600",
                    letterSpacing: "0.5px",
                  }}
                >
                  {`${admin?.firstName} ${admin?.lastName}`}
                </span>
                <br />
                <span className="xl-2">{'Admin'}</span>
              </div>

              {isDropdownOpen && (
                <div
                  className="dropdown-menu"
                  role="menu"
                  aria-labelledby="user-menu"
                >
                  <div className="dropdown-menu-items" style={{ cursor: "pointer" }}>
                    <Link to="/admin/settings" className="dropdown-item" role="menuitem" tabIndex="0">
                      Edit Profile
                    </Link>
                    <div className="dropdown-item" role="menuitem" tabIndex="0" onClick={handlelogout}>
                      Log out
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default HeaderAdmin;
