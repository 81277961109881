import React, { useEffect, useMemo, useState } from "react";
import ApplicantCards from "./ApplicantsCards";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import { TbArrowsSort } from "react-icons/tb";
import { ApplicantsList } from "./ApplicantsList";
import { Modal, Form, Select, Input, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addApplications } from '../../features/applicationSlice';
import Loader from "../../../Loader";
import Instance from "../../../Instance";

export const AdminApplicants = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('Draft');
  const [recruitersList, setRecruitersList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filterParameters, setFilterParameters] = useState({});
  const [isAscendingSort, setIsAcendingSort] = useState(false);
  const applications = useSelector((state) => state.application.applications);
  const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));

  const tableData = useMemo(() => {
    if (searchText?.trim() === '') return dataSource;
    return dataSource?.filter((application) => {
      return `${application?.basicInfo?.firstName?.toLowerCase()}${application?.basicInfo?.lastName?.toLowerCase()}${application?.basicInfo?.email}`.includes(searchText?.toLowerCase());
    })
  }, [dataSource, searchText]);

  const fetchAllAplications = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get("/getAllApplications", {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        },
      });
      if (response.status === 200) {
        dispatch(addApplications(response?.data?.applications));
        console.log(response.data)
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRecruitersData = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get("/getAllRecruiters", {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        },
      });
      if (response?.status === 200) {
        setRecruitersList(response?.data?.recruiters);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchRecruitersData();
    fetchAllAplications();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDataSource(applications);
  }, [applications]);

  const renderTasks = () => {
    switch (activeTab) {
      case 'Draft':
        return <ApplicantsList data={tableData} status={'Draft'} />;
      case 'Applied':
        return <ApplicantsList data={tableData} status={'Applied'} />;
      case 'Review':
        return <ApplicantsList data={tableData} status={'Review'} />;
      case 'Submitted':
        return <ApplicantsList data={tableData} status={'Submitted'} />;
      case 'Approved':
        return <ApplicantsList data={tableData} status={'Approved'} />;
      case 'Schedule':
        return <ApplicantsList data={tableData} status={'Schedule'} />;
      case 'Offer':
        return <ApplicantsList data={tableData} status={'Offer'} />;
      case 'Onboarding':
        return <ApplicantsList data={tableData} status={'Onboard'} />;
      case 'Hired':
        return <ApplicantsList data={tableData} status={'Hired'} />;
      case 'Closed':
        return <ApplicantsList data={tableData} status={'Closed'} />;
      default:
        return null;
    }
  };

  const handleSorting = () => {
    try {
      let sortedData;
      if (isAscendingSort) {
        sortedData = [...dataSource]?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      } else {
        sortedData = [...dataSource]?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      }
      setDataSource(sortedData);
      setIsAcendingSort(!isAscendingSort);
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalClose = () => {
    form.resetFields();
    setFilterParameters({});
    setIsModalVisible(false);
    setDataSource(applications);
  }

  const handleApplyFilters = () => {
    try {
      let filterData = applications;
      if (filterParameters.recruiter && filterParameters.recruiter.length > 0) {
        let reqJobIds = [];
        filterParameters?.recruiter?.forEach((recruiter) => {
          let jobsId = JSON.parse(recruiter).assignedJobs?.map(item => item.jobId);
          reqJobIds = [...reqJobIds, ...jobsId];
        });
        filterData = filterData?.filter((item) => reqJobIds?.includes(item?.manualJobId));
      }
      if (filterParameters?.startDate) {
        let date = new Date(filterParameters?.startDate);
        filterData = filterData?.filter(item => {
          let curDate = new Date(item?.createdAt);
          if (curDate.getFullYear() > date.getFullYear()) return true;
          else if (curDate.getFullYear() === date.getFullYear()) {
            if (curDate.getMonth() > date.getMonth()) return true;
            else if (curDate.getMonth() === date.getMonth()) {
              if (curDate.getDate() >= date.getDate()) return true;
            }
          }
          return false;
        });
      }
      if (filterParameters?.endDate) {
        let date = new Date(filterParameters?.endDate);
        filterData = filterData?.filter(item => {
          let curDate = new Date(item?.createdAt);
          if (curDate.getFullYear() < date.getFullYear()) return true;
          else if (curDate.getFullYear() === date.getFullYear()) {
            if (curDate.getMonth() < date.getMonth()) return true;
            else if (curDate.getMonth() === date.getMonth()) {
              if (curDate.getDate() <= date.getDate()) return true;
            }
          }
          return false;
        });
      }
      setDataSource(filterData);
    } catch (error) {
      console.error(error);
    } finally {
      setIsModalVisible(false);
    }
  }

  const recruiterOptions = recruitersList?.map((recruiter) => ({
    label: `${recruiter?.firstName} ${recruiter?.lastName}`,
    value: JSON.stringify(recruiter)
  }));

  return (
    <>
      {isLoading && <Loader />}
      <ApplicantCards data={applications} />
      <div className="row applicants-page-overview">
        <div className="d-flex justify-content-between align-items-center align-content-center mb-4">
          <div className="d-flex gap-4 align-items-center">
            <h2>Applicants</h2>
            <Input
              placeholder="Search..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className="search-input-table"
              prefix={<SearchOutlined />}
            />
          </div>
          <div className="search-table-container gap-2">
            <button className="table-action-btn" onClick={handleSorting}>
              <span>Sort</span>{" "}
              <TbArrowsSort
                style={{ fontWeight: "bold", fontSize: "16px" }}
              />
            </button>
            <button className="table-action-btn" onClick={() => setIsModalVisible(true)}>
              <FilterOutlined
                style={{ fontWeight: "bold", fontSize: "16px" }}
              />{" "}
              <span>Filter</span>
            </button>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="tab-group">
          {['Draft', 'Applied', 'Review', 'Submitted', 'Approved', 'Schedule', 'Offer', 'Onboarding', 'Hired', 'Closed'].map((tab) => (
            <button
              key={tab}
              className={`tab ${activeTab === tab ? 'active' : ''}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
        <div className="col-lg-12">{renderTasks()}</div>
      </div>
      <Modal
        title="Filter"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        className="filter-modal"
        footer={null}
      >
        <div className="filter-form mt-4">
          <Form layout="vertical " className="filter-form" form={form}>
            <div className="col-md-12">
              <Form.Item
                label="Recruiter"
                name={"recruiter"}
              >
                <Select placeholder="Select Recruiter" options={recruiterOptions} value={filterParameters.recruiter} onChange={(e) => setFilterParameters({ ...filterParameters, recruiter: e })} mode="multiple" />
              </Form.Item>
            </div>
            <div className="row col-md-12" >
              <div className="col-lg-6">
                <Form.Item
                  label="From"
                  name={"startDate"}
                >
                  <DatePicker
                    placeholder="Select Date"
                    format="DD/MM/YYYY"
                    className="form-control"
                    onChange={(e) => setFilterParameters({ ...filterParameters, startDate: e })}
                  />
                </Form.Item>
              </div>
              <div className="col-lg-6">
                <Form.Item
                  label="To"
                  name={"endDate"}
                >
                  <DatePicker
                    placeholder="Select Date"
                    format="DD/MM/YYYY"
                    className="form-control"
                    onChange={(e) => setFilterParameters({ ...filterParameters, endDate: e })}
                  />
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
        <div className="modal-actions d-flex justify-content-between">
          <button onClick={handleModalClose} className="cancel-btn" type="button">
            Clear All
          </button>
          <button className="create-btn" onClick={handleApplyFilters} type="button">
            Apply Filters
          </button>
        </div>
      </Modal>
    </>
  );
};
