import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Instance from "../../../Instance";

const ProtectRecruiterLead = ({ Component }) => {
    const navigate = useNavigate();
    const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));
    const isLogin = async () => {
        try {
            const response = await Instance.get("/validateToken", {
                headers: {
                    Authorization: `Bearer ${loggedInUserInfo?.token}`
                }
            });
            if (response?.status === 200 && response?.data?.role === 'RECRUITERLEAD') {
            } else {
                navigate('/');
            }
        } catch (error) {
            navigate('/');
        }
    }

    useEffect(() => {
        isLogin();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <div>
        <Component />
    </div>;
};

export default ProtectRecruiterLead;