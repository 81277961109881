import React, { useEffect, useState, useMemo } from 'react';
import { Filter, ArrowUpDown, Plus } from 'lucide-react';
import AssignedTask from './AssignedTask';
import OngoingTask from './OngoingTask';
import CompletedTask from './CompletedTask';
import CreateTaskPopup from './CreateTaskPopup';
import EditTaskPopup from './EditTaskPopup';
import Instance from '../../../Instance';
import { useSelector, useDispatch } from 'react-redux';
import { addAllTasks, changeTaskStatus, deleteTask } from '../../features/taskSlice';
import Loader from '../../../Loader';
import { Form, Select, Modal, Input } from 'antd';
import { showErrorMessage, showSuccessMessage } from '../../../globalConstant';
import { SearchOutlined } from "@ant-design/icons";

const AllTasks = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [clients, setClients] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const tasks = useSelector((state) => state.task.tasks);
  const [selectedTask, setSelectedTask] = useState(null);
  const [activeTab, setActiveTab] = useState('Assigned');
  const [isAscendingSort, setIsAcendingSort] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filterParameters, setFilterParameters] = useState({});
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const loggedInUserInfo = JSON.parse(localStorage.getItem('loggedInUserInfo'));
  const assignedTask = useMemo(() => {
    if (searchText?.trim() === '') return dataSource?.filter((task) => task.taskStatus === 'CREATED');
    else {
      return dataSource?.filter((task) => task.taskStatus === 'CREATED')?.filter((task) => `${task?.taskName?.toLowerCase()}${task?.projectName?.toLowerCase()}`?.includes(searchText?.toLowerCase()));
    }
  }, [dataSource, searchText]);

  const completedTask = useMemo(()=>{
    if (searchText?.trim() === '') return dataSource?.filter((task) => task.taskStatus === 'COMPLETED'); 
    else return  dataSource?.filter((task) => task.taskStatus === 'COMPLETED')?.filter((task) => `${task?.taskName?.toLowerCase()}${task?.projectName?.toLowerCase()}`?.includes(searchText?.toLowerCase()));
  },[dataSource, searchText]);

  const ongoingTask = useMemo(() => {
    if (searchText?.trim() === '') return dataSource?.filter((task) => task.taskStatus === 'IN-PROGRESS'); 
    else return dataSource?.filter((task) => task.taskStatus === 'IN-PROGRESS')?.filter((task) => `${task?.taskName?.toLowerCase()}${task?.projectName?.toLowerCase()}`?.includes(searchText?.toLowerCase()));
  }, [dataSource, searchText]);


  useEffect(() => {
    setDataSource(tasks);
  }, [tasks]);

  const fetchTasks = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get('/getAllTasks', {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        },
      });
      if (response.status === 200) {
        const dataSource = response?.data?.tasks?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        dispatch(addAllTasks(dataSource));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchClientsDetail = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get("/getAllClients", {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        },
      });

      if (response.status === 200) {
        setClients(response?.data?.clients);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks();
    fetchClientsDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDeleteTask = async (taskId) => {
    try {
      setIsLoading(true);
      const response = await Instance.delete(`/deleteTask/${taskId}`, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`
        }
      });
      if (response?.status === 200) {
        dispatch(deleteTask(taskId));
        showSuccessMessage('Task deleted successfully');
      }
    } catch (error) {
      console.error(error);
      showErrorMessage('Failed to delete task');
    } finally{
      setIsLoading(false);
    }
  }

  const handleSelectedTask = (task) => {
    setSelectedTask(task);
    setIsEditModalVisible(true);
  }

  const handleChangeTaskStatus = async (taskId, taskStatus) => {
    try {
      setIsLoading(true);
      const response = await Instance.patch(`/changeTaskStatus/${taskId}`, {
        taskStatus
      }, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`
        }
      });
      if (response?.status === 200) {
        dispatch(changeTaskStatus({ _id: taskId, taskStatus }));
        showSuccessMessage('Status changed successfully');
      }
    } catch (error) {
      console.error(error);
      showErrorMessage('Failed to change status');
    } finally {
      setIsLoading(false);
    }
  }

  const renderTasks = () => {
    switch (activeTab) {
      case 'Assigned':
        return assignedTask.map((task) => <AssignedTask key={task._id} detail={task} handleSelectedTask={handleSelectedTask} handleDeleteTask={handleDeleteTask} handleChangeTaskStatus={handleChangeTaskStatus} />);
      case 'Ongoing':
        return ongoingTask.map((task) => <OngoingTask key={task._id} detail={task} handleSelectedTask={handleSelectedTask} handleDeleteTask={handleDeleteTask} handleChangeTaskStatus={handleChangeTaskStatus} />);
      case 'Completed':
        return completedTask.map((task) => <CompletedTask key={task._id} detail={task} handleSelectedTask={handleSelectedTask} handleDeleteTask={handleDeleteTask} handleChangeTaskStatus={handleChangeTaskStatus} />);
      default:
        return null;
    }
  };

  const handleSorting = () => {
    try {
      let sortedData;
      if (isAscendingSort) {
        sortedData = [...dataSource]?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      } else {
        sortedData = [...dataSource]?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      }
      setDataSource(sortedData);
      setIsAcendingSort(!isAscendingSort);
    } catch (error) {
      console.error(error);
    }
  }

  const handleApplyFilters = () => {
    try {
      setIsLoading(true);
      let filteredTasks = tasks;
      if (filterParameters?.client) {
        filteredTasks = filteredTasks?.filter((item) => item?.associatedClient?._id?.toString() === filterParameters?.client?.toString());
      }
      setDataSource(filteredTasks);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsFilterModalVisible(false);
    }
  }

  const handleModalClose = () => {
    form.resetFields();
    setFilterParameters({});
    setIsFilterModalVisible(false);
    setDataSource(tasks);
  }

  const clientOptions = clients?.map((item) => ({
    label: `${item?.hospitalName} ${item?.clientId}`,
    value: item?._id,
  }));

  return (
    <>
      {isLoading && <Loader />}
      <div >
        <div className="task-header">
          <div >
            <Input
              placeholder="Search for a Task"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className="search-input-table me-2"
              prefix={<SearchOutlined />}
            />
          </div>
          <div className="button-group">
            <button className="task-btn" onClick={handleSorting}>
              <ArrowUpDown size={14} className="btn-icon" />
              Sort
            </button>
            <button className="task-btn" onClick={() => setIsFilterModalVisible(true)}>
              <Filter size={14} className="btn-icon" />
              Filter
            </button>
            <button className="task-btn" onClick={() => setIsModalVisible(true)}>
              <Plus size={14} /> Add Task
            </button>
          </div>
        </div>
        <div className='mt-4'>
          <h1 className="task-title">Tasks</h1>
        </div>
        <div className="tab-group">
          {['Assigned', 'Ongoing', 'Completed'].map((tab) => (
            <button
              key={tab}
              className={`tab ${activeTab === tab ? 'active' : ''}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab} Task
            </button>
          ))}
        </div>
        <div className="task-grid">{renderTasks()}</div>
        <CreateTaskPopup isVisible={isModalVisible} onClose={() => setIsModalVisible(false)} />
        <EditTaskPopup isVisible={isEditModalVisible} onClose={() => {
          setSelectedTask(null);
          setIsEditModalVisible(false);
        }} data={selectedTask} />
        <Modal
          title="Filter"
          open={isFilterModalVisible}
          onCancel={() => setIsFilterModalVisible(false)}
          className="filter-modal"
          footer={null}
        >
          <div className="filter-form mt-4">
            <Form layout="vertical " className="filter-form" form={form}>
              <div className="col-md-12">
                <Form.Item
                  label="Client"
                  name={"client"}
                >
                  <Select placeholder="Select Client" options={clientOptions} onChange={(e) => setFilterParameters({ ...filterParameters, client: e })} />
                </Form.Item>
              </div>
            </Form>
          </div>

          <div className="modal-actions d-flex justify-content-between">
            <button onClick={handleModalClose} className="cancel-btn" type="button">
              Clear All
            </button>
            <button className="create-btn" onClick={handleApplyFilters} type="button">
              Apply Filters
            </button>
          </div>
        </Modal>
      </div>

    </>
  );
};

export default AllTasks;
