import React, { useState, useEffect } from "react";
import { FaFileAlt, FaEnvelope, FaSearch, FaClipboardCheck, FaUserCheck, FaClock, FaCalendarAlt, FaUserPlus, FaThumbsUp, FaTimes } from "react-icons/fa";
import Instance from "../../../Instance";
import Loader from "../../../Loader";
import { GetState } from "react-country-state-city";
import { Select } from "antd";

const DashboardApplicantsStatus = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStateData, setSelectedStateData] = useState([]);
  const [applicationData, setApplicationData] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const loggedInUserInfo = JSON.parse(localStorage.getItem('loggedInUserInfo'));

  const fetchApplicationsStateWise = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get(`/getApplicationStateWise`, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`
        }
      });
      if (response.status === 200) {
        let data = response?.data?.applications;
        setApplicationData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const fetchStates = async () => {
    const states = await GetState(233);
    const options = states?.map((item) => ({
      label: item?.name,
      value: item?.state_code,
    }));
    setStateOptions(options);
  };

  useEffect(() => {
    fetchStates();
    fetchApplicationsStateWise();
  }, []);

  const statusData = [
    {
      label: "Draft",
      count: selectedStateData[0] || 0,
      icon: <FaFileAlt />,
      bgColor: "#ABA9FF",
      change: "8.5% Up from yesterday",
      changeColor: "green",
    },
    {
      label: "Applied",
      count: selectedStateData[1] || 0,
      icon: <FaEnvelope />,
      bgColor: "#FFE8B0",
      change: "1.3% Up from past week",
      changeColor: "green",
    },
    {
      label: "Review",
      count: selectedStateData[2] || 0,
      icon: <FaSearch />,
      bgColor: "#A9E4C7",
      change: "4.3% Down from yesterday",
      changeColor: "red",
    },
    {
      label: "Submitted",
      count: selectedStateData[3] || 0,
      icon: <FaClipboardCheck />,
      bgColor: "#FFCAB6",
      change: "1.8% Up from yesterday",
      changeColor: "green",
    },
    {
      label: "Approved",
      count: selectedStateData[4] || 0,
      icon: <FaUserCheck />,
      bgColor: "#C2FDF4",
      change: "1.8% Up from yesterday",
      changeColor: "green",
    },
    {
      label: "Schedule",
      count: selectedStateData[5] || 0,
      icon: <FaClock />,
      bgColor: "#FFC6F9",
      change: "8.5% Up from yesterday",
      changeColor: "green",
    },
    {
      label: "Offer",
      count: selectedStateData[6] || 0,
      icon: <FaCalendarAlt />,
      bgColor: "#D3FEBE",
      change: "1.3% Up from past week",
      changeColor: "green",
    },
    {
      label: "Onboarding",
      count: selectedStateData[7] || 0,
      icon: <FaUserPlus />,
      bgColor: "#C7D3FE",
      change: "4.3% Down from yesterday",
      changeColor: "red",
    },
    {
      label: "Hired",
      count: selectedStateData[8] || 0,
      icon: <FaThumbsUp />,
      bgColor: "#FFFA8C",
      change: "1.8% Up from yesterday",
      changeColor: "green",
    },
    {
      label: "Closed",
      count: selectedStateData[9] || 0,
      icon: <FaTimes />,
      bgColor: "#FEB8BD",
      change: "1.8% Up from yesterday",
      changeColor: "green",
    },
  ];

  const getStateData = (stateCode) => {
    try {
      const data = applicationData?.find((item) => item?.stateCode === stateCode);
      let draft = data?.applications?.filter((item)=> item?.status === "Draft").length;
      let applied = data?.applications?.filter((item)=> item?.status === "Applied").length;
      let review = data?.applications?.filter((item)=> item?.status === "Review").length;
      let submitted = data?.applications?.filter((item)=> item?.status === "Submitted").length;
      let approved = data?.applications?.filter((item)=> item?.status === "Approved").length;
      let schedule = data?.applications?.filter((item)=> item?.status === "Schedule").length;
      let offer = data?.applications?.filter((item)=> item?.status === "Offer").length;
      let onboard = data?.applications?.filter((item)=> item?.status === "Onboard").length;
      let hired = data?.applications?.filter((item)=> item?.status === "Hired").length;
      let closed = data?.applications?.filter((item)=> item?.status === "Closed").length;
      setSelectedStateData([draft, applied, review, submitted, approved, schedule, offer, onboard, hired, closed]);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className="row mb-4">
        <div className="col-lg-12">
          <div className="admin-dashboard-card">
            <div className="admin-dashboard-content">
              <div className="admin-dashboard-title">
                <h2>Application Status</h2>
              </div>
              <div className="dashboard-toggle-button-group" style={{ width: '200px' }}>
                <Select placeholder="Select State" style={{ width: '100%' }} options={stateOptions} onChange={(e) => getStateData(e)} />
              </div>
            </div>
            <div className="applicant-status-grid">
              {statusData.map((item, index) => (
                <div key={index} className="applicant-status-card">
                  <div className="d-flex justify-content-between">
                    <div className="applicant-status-info">
                      <h4>{item.label}</h4>
                      <p>{item.count}</p>
                    </div>
                    <div
                      className="applicant-status-icon"
                      style={{ backgroundColor: item.bgColor }}
                    >
                      {item.icon}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardApplicantsStatus;
