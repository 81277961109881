import React, { useState, useRef, useEffect } from "react";
import { Form, Input, DatePicker, Select } from "antd";
import Instance from "../../../Instance";
import { TbEdit } from "react-icons/tb";
import { showErrorMessage, showSuccessMessage, IMG_URL, handleFileUpload, createUniqueS3FilenameWithDate } from "../../../globalConstant";
import DefaultUser from '../../../Admin/Assets/Image/Recriuiter/DefaultUser.png';
import "react-international-phone/style.css";
import { PhoneInput } from "react-international-phone";
import { isValidPhoneNumber } from 'libphonenumber-js'
import Loader from '../../../Loader';
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { setProfile } from "../../../Admin/features/profileSlice";
import { GetCountries, GetState, GetCity } from "react-country-state-city";

export const PersonalInformation = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const recruiter = useSelector((state) => state.profile.profile);
  const [formData, setFormData] = useState(null);
  const fileInputRef = useRef(null);
  const [previewImage, setPreviewImage] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));

  const fetchRecruiterDetail = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get(`/getRecruiterById/${loggedInUserInfo?.userId}`, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`
        }
      });
      setIsLoading(false);
      if (response.status === 200) {
        dispatch(setProfile(response.data?.recruiter));
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchRecruiterDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!recruiter) return;
    setInitialValue();
  }, [recruiter]);

  const setInitialValue = async () => {
    if (!recruiter) return;

    let c;
    try {
      const countries = await GetCountries();
      c = countries.find((item) => item?.name === recruiter?.country)?.id;
      const states = await GetState(c);
      let s = states.find((item) => item?.name === recruiter?.state)?.id;

      let data = {
        firstName: recruiter?.firstName,
        lastName: recruiter?.lastName,
        email: recruiter?.email,
        mobileNumber: recruiter?.mobileNumber,
        dateOfBirth: recruiter?.dateOfBirth ? dayjs(recruiter?.dateOfBirth) : null,
        linkedInUrl: recruiter?.linkedInUrl,
        country: c,
        state: s,
        city: recruiter?.city,
        zipCode: recruiter?.zipCode,
        street: recruiter?.street,
        profileImage: recruiter?.profileImage,
      };

      setFormData(data);
      form.setFieldsValue(data);
    } catch (error) {
      console.error('Error fetching country/state:', error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'image/svg+xml'];

    // Check file type
    if (!allowedTypes.includes(file.type)) {
      return { valid: false, message: 'Only JPG, JPEG, PNG, WEBP, and SVG images are allowed.' };
    }

    // Check file size (less than 2MB)
    const maxSizeInMB = 2;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      return { valid: false, message: 'File size should be less than 2MB.' };
    }

    if (file) {
      setProfileImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEditClick = () => {
    fileInputRef.current.click();
  };

  const handleSubmitForm = async () => {
    try {
      setIsLoading(true);
      if (formData?.mobileNumber && !isValidPhoneNumber(formData?.mobileNumber)) {
        showErrorMessage("Invalid Mobile Number!");
        return;
      }
      let reqData = { ...formData };
      let c = await GetCountries();
      reqData.country = c?.find((item) => item?.id === formData?.country)?.name;
      let s = await GetState(formData?.country);
      reqData.state = s?.find((item) => item?.id === formData?.state)?.name;
      if (profileImage) {
        const fileName = createUniqueS3FilenameWithDate(profileImage?.name);
        await handleFileUpload(profileImage, fileName, profileImage?.type);
        reqData.profileImage = fileName;
      }

      const response = await Instance.put(`/editProfileInformation`, { ...reqData }, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        }
      });
      if (response.status === 200) {
        setProfileImage(null);
        dispatch(setProfile(response?.data?.updatedRecruiter));
        showSuccessMessage('Information Updated Successfully!');
      }
    } catch (error) {
      console.error(error);
      showErrorMessage('Something went wrong. Please try again!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      let countries = await GetCountries();
      let options = countries?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));
      setCountryOptions(options);
    };
    fetchCountries();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchStates = async () => {
      if (!formData?.country) {
        setStateOptions([]);
        return;
      }
      const states = await GetState(formData?.country);
      const options = states?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));
      setStateOptions(options);
    };
    fetchStates();
  }, [formData?.country]);

  useEffect(() => {
    const fetchCities = async () => {
      if (!formData?.state) {
        setCityOptions([]);
        return;
      }
      const cities = await GetCity(formData?.country, formData?.state);
      const options = cities?.map((item) => ({
        label: item?.name,
        value: item?.name,
      }));
      setCityOptions(options);
    };
    fetchCities();
  }, [formData?.state]);

  return (
    <>
      {isLoading && <Loader />}
      <div className="recruiter-personal-information-section">
        <div className="container">
          {/* <h4>Personal Information</h4> */}
          <Form layout="vertical" form={form} onFinish={handleSubmitForm}>
            <div className="row mt-4">
              <div className="recruiter-profile-icon-section">
                <img src={previewImage ? previewImage : recruiter?.profileImage ? `${IMG_URL}/${recruiter?.profileImage}` : DefaultUser} alt="Profile" className="profile-image" />
                <button
                  type="button"
                  className="recruiter-edit-icon-button"
                  onClick={handleEditClick}
                >
                  <TbEdit className="recruiter-edit-icon" />
                </button>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  accept="image/*"
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6 mb-1">
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[{ required: true, message: "Please enter first name!" }]}
                >
                  <Input placeholder="Enter First Name" onChange={(e) => setFormData({ ...formData, firstName: e.target.value })} />
                </Form.Item>
              </div>
              <div className="col-md-6 mb-1">
                <Form.Item label="Last Name" name="lastName" rules={[{ required: true, message: "Please enter last name!" }]}>
                  <Input placeholder="Enter Last Name" onChange={(e) => setFormData({ ...formData, lastName: e.target.value })} />
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-1">
                <Form.Item label="Email" name="email" rules={[{ required: true, message: "Please enter email!" }]}>
                  <Input placeholder="Enter Email" onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                </Form.Item>
              </div>
              <div className="col-md-6 mb-1">
                <label htmlFor="inputPhone" className="form-label" style={{
                  display: "flex",
                  marginBottom: "0",
                }}>
                  <span style={{ color: "#ff4d4f", marginRight: "4px", marginBottom: "8px" }}>*</span> Mobile Number
                </label>
                <PhoneInput
                  defaultCountry="us"
                  value={recruiter?.mobileNumber || ""}
                  onChange={(phone) => {
                    setFormData((values) => ({
                      ...values,
                      ["mobileNumber"]: phone,
                    }));
                  }}
                  inputStyle={{
                    width: "100%",
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-1">
                <Form.Item label="Date of Birth (DD/MM/YYYY)" name="dateOfBirth">
                  <DatePicker format="DD/MM/YYYY" placeholder="Select DOB" className="form-control" onChange={(date) => setFormData({ ...formData, dateOfBirth: date })} />
                </Form.Item>
              </div>
              <div className="col-md-6 mb-3">
                <Form.Item
                  label="LinkedIn Url"
                  name="linkedInUrl"
                >
                  <Input placeholder="Enter LinkedIn Url" value={recruiter?.linkedInUrl || ""} onChange={(e) => setFormData({ ...formData, linkedInUrl: e.target.value })} />
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 mb-3">
                <Form.Item
                  label="Country"
                  name="country"
                  rules={[{ required: true, message: '' }]}
                >
                  <Select
                    placeholder="Select Country"
                    className="recruiter-lead-select-job-categories"
                    onChange={(e) => {
                      form.setFieldValue("state", null);
                      form.setFieldValue("city", null);
                      setFormData({ ...formData, country: e, state: null, city: null })
                    }}
                    options={countryOptions}
                    value={formData?.country}
                  />
                </Form.Item>
              </div>
              <div className="col-lg-6 mb-3">
                <Form.Item
                  label="State"
                  name="state"
                  rules={[{ required: true, message: '' }]}
                >
                  <Select
                    placeholder="Select State"
                    className="recruiter-select-job-categories"
                    onChange={(e) => {
                      form.setFieldValue("city", null);
                      setFormData({ ...formData, state: e, city: null })
                    }}
                    options={stateOptions}
                    value={formData?.state}
                  />
                </Form.Item>
              </div>
              <div className="col-lg-6 mb-3">
                <Form.Item
                  label="City"
                  name="city"
                  rules={[{ required: true, message: '' }]}
                >
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Select City"
                    className="recruiter-select-job-categories"
                    onChange={(e) => {
                      setFormData({ ...formData, city: e })
                    }}
                    options={cityOptions}
                    value={formData?.city}
                    disabled={formData?.state ? false : true}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6 mb-3">
                <Form.Item
                  label="Street"
                  name="street"
                  rules={[{ required: true, message: '' }]}
                >
                  <Input placeholder="Enter Street" value={formData?.street || ""} onChange={(e) => setFormData({ ...formData, street: e.target.value })} />
                </Form.Item>
              </div>
              <div className="col-md-6 mb-3">
                <Form.Item
                  label="Zip Code"
                  name="zipCode"
                  rules={[{ required: true, message: '' }]}
                >
                  <Input placeholder="Enter Zip Code" value={formData?.zipCode || ""} onChange={(e) => setFormData({ ...formData, zipCode: e.target.value })} />
                </Form.Item>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12 text-end">
                <button className="recruiter-cancel-btn" type="button" onClick={() => {
                  setInitialValue();
                  setProfileImage(null);
                  setPreviewImage("");
                  setFormData(recruiter);
                }}>Discard</button>
                <button className="recruiter-create-btn" type="submit">
                  Save Changes
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
