import { Select } from 'antd';
import React, { useState, useEffect } from "react";
import { XAxis, YAxis, ResponsiveContainer, AreaChart, Area } from 'recharts';
import task from "../../../Admin/Assets/Image/Recriuiter/task.png"
import Instance from "../../../Instance";
import Loader from "../../../Loader";

const DashboardHiringStatus = () => {
    const [clientData, setClientData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedYear, setSelectedYear] = useState(null);
    const loggedInUserInfo = JSON.parse(localStorage.getItem('loggedInUserInfo'));

    useEffect(() => {
        setSelectedYear(new Date().getFullYear());
    }, []);

    const fetchClientData = async () => {
        try {
            setIsLoading(true);
            const response = await Instance.get(`/getClientsByYear?year=${selectedYear}`, {
                headers: {
                    Authorization: `Bearer ${loggedInUserInfo?.token}`
                }
            });
            if (response.status === 200) {
                let data = response?.data?.clients;
                const clientOverviewData = [
                    { month: 'Jan', value: 200 },
                    { month: 'Feb', value: 300 },
                    { month: 'Mar', value: 333 },
                    { month: 'Apr', value: 300 },
                    { month: 'May', value: 120 },
                    { month: 'Jun', value: 300 },
                    { month: 'Jul', value: 120 },
                    { month: 'Aug', value: 300 },
                    { month: 'Sep', value: 120 },
                    { month: 'Oct', value: 300 },
                    { month: 'Nov', value: 120 },
                    { month: 'Dec', value: 300 },
                ];
                for (let i = 0; i < 12; i++) {
                    clientOverviewData[i]['value'] = data[i];
                }
                setClientData(clientOverviewData);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (!selectedYear) return;
        fetchClientData();
    }, [selectedYear]);

    const yearOptions = () => {
        const currentYear = new Date().getFullYear();
        let arr = [];
        for (let i = 2024; i <= currentYear; i++) {
            arr.push({ label: i, value: i });
        }
        return arr;
    }
    const options = yearOptions();

    return (
        <>
            {isLoading && <Loader />}
            <div className="row g-4">
                <div className="col-lg-7">
                    <div className="admin-dashboard-card">
                        <div className="admin-dashboard-content">
                            <div className="admin-dashboard-title">
                                <h2>Client Information</h2>
                            </div>
                            <div className="admin-dashboard-filter">
                                <Select options={options} value={selectedYear} onChange={(e) => {
                                    setSelectedYear(e);
                                }} />
                            </div>
                        </div>

                        <div className="card-body">
                            <ResponsiveContainer width="100%" height={250}>
                                <AreaChart data={clientData}>
                                    <XAxis dataKey="month" />
                                    <YAxis />

                                    <Area
                                        type="monotone"
                                        dataKey="value"
                                        stroke="#D92222"
                                        fill="#FF0000"
                                        strokeOpacity={1}
                                        fillOpacity={0.1}
                                    />
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>

                <div className="col-lg-5">
                    <div className="admin-dashboard-card">
                        <div className="admin-dashboard-content">
                            <div className="admin-dashboard-title">
                                <h2>Latest Task Activity</h2>
                            </div>

                            <div className="admin-dashboard-filter">
                                {/* <Dropdown overlay={filter} placement="bottomLeft">
                                    <Button>
                                        <Space>
                                            Select Year
                                        </Space>
                                    </Button>
                                </Dropdown> */}
                            </div>
                        </div>

                        <div className="latest-activity-card-body d-flex gap-2">
                            <div>
                                <h4>Average Time to fill Jobs for year 2024</h4>
                                <h5>2 months,10days</h5>
                                <h5>Total Vacancies Left : 45</h5>
                            </div>
                            <div>
                                <img src={task} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DashboardHiringStatus;
