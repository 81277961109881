import React, { useEffect, useState } from "react";
import { Collapse, Space, Modal, Form, Input, Button, Dropdown, Menu } from "antd";
import { PlusCircleIcon, MinusCircleIcon } from "lucide-react";
import { showErrorMessage, showSuccessMessage } from "../../../globalConstant";
import Loader from "../../../Loader";
import { useSelector, useDispatch } from "react-redux";
import { setFaqs, addNewFaq, editFaq, deleteFaq } from "../../features/faqSlice";
import Instance from "../../../Instance";
import { FaEdit, FaTrash } from "react-icons/fa";
import { IoEllipsisVertical } from "react-icons/io5";

export const Faqs = () => {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [activeKey, setActiveKey] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const faqs = useSelector((state) => state.faqs.faqs);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));

  const fetchFaqs = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get("/getAllWebFQAs", {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        },
      });
      if (response.status === 200) {
        dispatch(setFaqs(response.data.webFQAs));
      }
    } catch (error) {
      console.error(error);
      showErrorMessage("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchFaqs();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onCollapseChange = (key) => {
    setActiveKey(key);
  };

  const menu = (faq) => (
    <Menu>
      <Menu.Item key="edit" onClick={() => {
          setIsEditModalVisible(true);
          setFormData(faq);
          form2.setFieldsValue(faq);
        }}><FaEdit /> Edit
      </Menu.Item>
      <Menu.Item key="delete" onClick={() => deleteFaqFun(faq._id)}>
        <FaTrash /> Delete
      </Menu.Item>
    </Menu>
  );
  const genExtra = (key, faq) => (
    <>
      {activeKey.includes(key)
        ? <MinusCircleIcon className="faq-plus-icon" />
        : <PlusCircleIcon className="faq-plus-icon" />
      }
      <Dropdown overlay={menu(faq)} trigger={['click']}>
        <IoEllipsisVertical size={20} className="faq-more-icon" style={{ cursor: 'pointer', marginLeft: '8px' }} />
      </Dropdown>
    </>
  );
  

  const renderHeaderWithNumber = (faq, index) => {
    const isExpanded = activeKey.includes(faq._id);
    return (
      <div className="faq-header">
        <span className={isExpanded ? "faq-number expanded" : "faq-number"}>{index + 1}.</span>
        <span className={isExpanded ? "faq-question expanded" : "faq-question"}>
          {faq.question}
        </span>
        
      </div>
    );
  };

  const handleFormSubmit = async () => {
    try {
      let reqData = { ...formData, createdBy: loggedInUserInfo?.userId, createdByRole: 'admin' };
      const response = await Instance.post("/addWebFAQ", reqData, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        },
      });
      if (response.status === 201) {
        dispatch(addNewFaq(response.data.newWebFQA));
        showSuccessMessage("FAQ added successfully");
        handleAddModalCancel();
      }
    } catch (error) {
      console.error(error);
      showErrorMessage("Something went wrong");
    }
  };

  const editFaqFun = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.put(`/updateWebFAQ/${formData?._id}`, formData, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        },
      });
      if (response.status === 200) {
        dispatch(editFaq(response.data.updatedWebFQA));
        showSuccessMessage("FAQ updated successfully");
        handleEditModalCancel();
      }
    } catch (error) {
      console.error(error);
      showErrorMessage("Failed to update FAQ");
    } finally {
      setIsLoading(false);
    }
  };

  const deleteFaqFun = async (id) => {
    try {
      setIsLoading(true);
      const response = await Instance.delete(`/deleteWebFAQ/${id}`, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        },
      });
      if (response.status === 200) {
        dispatch(deleteFaq(id));
        showSuccessMessage("FAQ deleted successfully");
      }
    } catch (error) {
      console.error(error);
      showErrorMessage("Failed to delete FAQ");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddModalCancel = () => {
    setIsAddModalVisible(false);
    form.resetFields();
    setFormData({});
  };

  const handleEditModalCancel = () => {
    setIsEditModalVisible(false);
    form2.resetFields();
    setFormData({});
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="faq-section">
      <div className="d-flex justify-content-between">
          <h3>Frequently Asked Questions</h3>
          <button onClick={() => setIsAddModalVisible(true)} className="create-btn">Add FAQ</button>
        </div>
        <div className="mt-4">
          <Space direction="vertical" className="faq-collapse" size={20}>
            <Collapse
              onChange={onCollapseChange}
              activeKey={activeKey}
              accordion={false}
              bordered={false}
              expandIconPosition={null}
            >
              {faqs?.map((faq, index) => (
                <Collapse.Panel
                  key={faq._id}
                  header={renderHeaderWithNumber(faq, index)}
                  extra={genExtra(faq._id,faq)}
                  className="faq-panel"
                  style={{ border: "1px solid #ddd", borderRadius: "5px", marginBottom: "10px" }}
                  showArrow={false}
                >
                  <p>{faq.answer}</p>
                </Collapse.Panel>
              ))}
            </Collapse>
          </Space>
        </div>
      </div>
      <Modal visible={isAddModalVisible} title="Add FAQ" onCancel={handleAddModalCancel} footer={null}>
        <Form form={form} onFinish={handleFormSubmit} layout="vertical">
          <Form.Item label="Question" rules={[{ required: true }]} name={"question"}>
            <Input placeholder="Enter your question" value={formData.question} onChange={(e) => setFormData({ ...formData, question: e.target.value })} />
          </Form.Item>
          <Form.Item label="Answer" rules={[{ required: true }]} name={'answer'}>
            <Input placeholder="Enter your answer" value={formData.answer} onChange={(e) => setFormData({ ...formData, answer: e.target.value })} />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" className="bg-danger mx-2 text-white">Submit</Button>
            <Button>Cancel</Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal visible={isEditModalVisible} title="Edit FAQ" onCancel={handleEditModalCancel} footer={null}>
        <Form form={form2} onFinish={editFaqFun} layout="vertical">
          <Form.Item label="Question" rules={[{ required: true }]} name={"question"}>
            <Input placeholder="Enter your question" value={formData.question} onChange={(e) => setFormData({ ...formData, question: e.target.value })} />
          </Form.Item>
          <Form.Item label="Answer" rules={[{ required: true }]} name={'answer'}>
            <Input placeholder="Enter your answer" value={formData.answer} onChange={(e) => setFormData({ ...formData, answer: e.target.value })} />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" className="bg-danger mx-2 text-white">Update</Button>
            <Button>Cancel</Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
