import React from "react";
import { FaBriefcase, FaLocationPin, FaPhone } from "react-icons/fa6";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { IMG_URL, formatDate } from "../../../globalConstant";
import DefaultUser from '../../Assets/Image/Recriuiter/DefaultUser.png';

const RecruiterProfile = ({ details }) => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/admin/all-recruiters");
  };

  return (
    <div className="recruiter-profile-section">
      <div className="container">
        <div className="row">
          <h2 className="">Profile Information</h2>
        </div>
        <div className="row recruiter-profile-row mt-3">
          <div className="d-flex gap-2">
            <div>
              <img src={details?.profileImage ? `${IMG_URL}/${details?.profileImage}` : DefaultUser} className="recruiter-profile-image" alt="" />
            </div>
            <div className="recruiter-profile-description p-4">
              <h4>{`${details?.firstName} ${details?.lastName}`}</h4>
              <p>
                <FaBriefcase className="icon-red" />
                &nbsp; {details?.isLead ? `Recruiter Lead` : `Recruiter`}
              </p>

              <p>
                <FaPhone className="icon-red" />
                &nbsp; {details?.mobileNumber}
              </p>
              <p>
                <FaLocationPin className="icon-red" />
                &nbsp; {details?.city}, {details?.state}, {details?.zipCode}
              </p>
            </div>
          </div>
        </div>
        <div className="row recruiter-personal-information mt-3">
          <h5>Personal Information</h5>
          <div className="row mt-2">
            <div className="col-lg-4">
              <div>
                <h4>First Name</h4>
                <p>{details?.firstName} </p>
                <div className="recruiter-text-underline"></div>
              </div>
              <div className="mt-4">
                <h4>Last Name</h4>
                <p>{details?.lastName} </p>
                <div className="recruiter-text-underline"></div>
              </div>
            </div>
            <div className="col-lg-4">
              <div>
                <h4>Email</h4>
                <p>{details?.email} </p>
                <div className="recruiter-text-underline"></div>
              </div>
              <div className="mt-4">
                <h4>Mobile No.</h4>
                <p>{details?.mobileNumber} </p>
                <div className="recruiter-text-underline"></div>
              </div>
            </div>
            <div className="col-lg-4">
              <div>
                <h4>Position</h4>
                <p>{details?.isLead ? `Recruiter Lead` : `Recruiter`}</p>
                <div className="recruiter-text-underline"></div>
              </div>
              <div className="mt-4">
                <h4>Date of Birth</h4>
                <p> {details?.dateOfBirth?formatDate(details?.dateOfBirth)  : 'N/A'} </p>
                <div className="recruiter-text-underline"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="row recruiter-personal-information mt-3">
          <h5>Contact Information</h5>
          <div className="row mt-2">
            <div className="col-lg-4">
              <div>
                <h4>Country</h4>
                <p>{details?.country}</p>
                <div className="recruiter-text-underline"></div>
              </div>
              <div className="mt-4">
                <h4>LinkedIn</h4>
                <p onClick={() => window.open(details?.linkedinUrl)} style={{ cursor: "pointer" }}>{details?.linkedInUrl} </p>
                <div className="recruiter-text-underline"></div>
              </div>
            </div>
            <div className="col-lg-4">
              <div>
                <h4>City | State</h4>
                <p>{details?.city},{details?.state}</p>
                <div className="recruiter-text-underline"></div>
              </div>
            </div>
            <div className="col-lg-4">
              <div>
                <h4>Postal Code</h4>
                <p>{details?.zipCode}</p>
                <div className="recruiter-text-underline"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <button className="create-btn" onClick={handleBack}>
            <IoIosArrowBack />
            &nbsp; Back
          </button>
        </div>
      </div>

    </div>
  );
};
export default RecruiterProfile;
