import React,{useState} from 'react';
import SidebarRecruiterLead from '../../Layout/Sidebar/Index';
import HeaderRecruiterLead from '../../Layout/Header/Index';
import { Settings } from '../../Components/Settings/Settings';
import "./settings.css"
const RecruiterLeadSettingsPage = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const handleCollapseToggle = (collapsedState) => {
      setIsCollapsed(collapsedState);
    };
  
    return (
        <>
             <SidebarRecruiterLead onCollapseToggle={handleCollapseToggle}/>
             <HeaderRecruiterLead onCollapseToggle={handleCollapseToggle} isCollapsed={isCollapsed}/>
            <div className={`main-wrapper ${isCollapsed ? "collapsed" : ""}`}>
                <Settings/>
            </div>
        </>
    )
}

export default RecruiterLeadSettingsPage;