import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { FaCircle } from 'react-icons/fa6';
import Instance from '../../../Instance';
import Loader from '../../../Loader';

const DashboardGraphs = () => {
    const [jobData, setJobData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedYear, setSelectedYear] = useState(null);
    const [applicationData, setApplicationData] = useState([]);
    const loggedInUserInfo = JSON.parse(localStorage.getItem('loggedInUserInfo'));

    useEffect(() => {
        setSelectedYear(new Date().getFullYear());
    }, []);

    const fetchJobData = async () => {
        try {
            setIsLoading(true);
            const response = await Instance.get(`/getJobsCountByYear?year=${selectedYear}`, {
                headers: {
                    Authorization: `Bearer ${loggedInUserInfo?.token}`
                }
            });
            if (response.status === 200) {
                let data = response?.data?.jobsData;
                const jobOverviewData = [
                    { month: 'Jan', selectedYear: 200 },
                    { month: 'Feb', selectedYear: 300 },
                    { month: 'Mar', selectedYear: 333 },
                    { month: 'Apr', selectedYear: 300 },
                    { month: 'May', selectedYear: 120 },
                    { month: 'Jun', selectedYear: 300 },
                    { month: 'Jul', selectedYear: 120 },
                    { month: 'Aug', selectedYear: 300 },
                    { month: 'Sep', selectedYear: 120 },
                    { month: 'Oct', selectedYear: 300 },
                    { month: 'Nov', selectedYear: 120 },
                    { month: 'Dec', selectedYear: 300 },
                ];
                for (let i = 0; i < 12; i++) {
                    jobOverviewData[i][selectedYear] = data[i];
                }
                setJobData(jobOverviewData);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (!selectedYear) return;
        fetchJobData();
    }, [selectedYear]);

    const yearOptions = () => {
        const currentYear = new Date().getFullYear();
        let arr = [];
        for (let i = 2024; i <= currentYear; i++) {
            arr.push({ label: i, value: i });
        }
        return arr;
    }
    const options = yearOptions();

    const fetchApplicationsStateWise = async () => {
        try {
            setIsLoading(true);
            const response = await Instance.get(`/getApplicationStateWise`, {
                headers: {
                    Authorization: `Bearer ${loggedInUserInfo?.token}`
                }
            });
            if (response.status === 200) {
                let data = response?.data?.applications;
                setApplicationData(data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchApplicationsStateWise();
    }, []);

    return (
        <>
            {isLoading && <Loader />}
            <div className="row g-4 mb-4">
                <div className="col-lg-7">
                    <div className="admin-dashboard-card">
                        <div className="admin-dashboard-content">
                            <div className="admin-dashboard-title">
                                <h2>Job Overview</h2>
                            </div>
                            <div className="admin-dashboard-filter">
                                <Select options={options} value={selectedYear} onChange={(e) => {
                                    setSelectedYear(e);
                                }} />
                            </div>
                        </div>
                        <div className="card-body">
                            <ResponsiveContainer width="100%" height={200}>
                                <BarChart data={jobData}>
                                    <XAxis dataKey="month" />
                                    <YAxis />
                                    <Bar dataKey={selectedYear} stackId="a" fill="#D92222" radius={[2, 2, 0, 0]} />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>

                <div className="col-lg-5">
                    <div className="admin-dashboard-card">
                        <div className="admin-dashboard-content">
                            <div className="admin-dashboard-title">
                                <h2>Applications State-wise</h2>
                            </div>
                        </div>
                        <div className="card-body" style={{ height: '200px', overflowY: 'scroll' }}>
                            <ul className="list-unstyled">
                                {applicationData?.map((item, index) => (
                                    <li key={index} className="d-flex justify-content-between align-items-center mb-2">
                                        <span>
                                            <FaCircle style={{ color: 'green', marginRight: '8px', fontSize: '16px' }} />
                                            {item?.stateName},{item?.stateCode}
                                        </span>
                                        <span
                                            className={'text-success'}
                                            style={{
                                                border: `1px solid green`,
                                                padding: '4px 6px',
                                                borderRadius: '4px',
                                                display: 'inline-flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            {item?.applications?.length}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DashboardGraphs;
