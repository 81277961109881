import React, { useEffect, useState } from "react";
import { Form, Input, Select, Popconfirm } from "antd";
import { useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { HiMiniEllipsisVertical } from "react-icons/hi2";
import Instance from "../../../Instance";
import { showErrorMessage, showSuccessMessage, formatDate, IMG_URL } from "../../../globalConstant";
import DefaultUser from "../../../Admin/Assets/Image/Recriuiter/DefaultUser.png";
import Loader from "../../../Loader";
import { useSelector, useDispatch } from "react-redux";
import { addDetailTicket, addReplyToTicket, deleteReplyFromTicket, changeTicketStatus } from "../../../Admin/features/ticketSlice";
import { MdDeleteOutline } from "react-icons/md";
import { FaRegCommentDots } from "react-icons/fa6";

export const TicketDetail = ({ ticketId }) => {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [reply, setReply] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteMenu, setShowDeleteMenu] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [showStatusPopup, setShowStatusPopup] = useState(false);
  const ticket = useSelector((state) => state.ticket?.detailTicket);
  const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));
  
  useEffect(() => {
    let st = ticket?.status==="OPEN" ? "Open" : (ticket?.status==="INPROGRESS" ? "In Progress" : "Closed");
    setSelectedStatus({
      label: st,
      value: ticket?.status,
    })
  }, [ticket]);

  const toggleDeleteMenu = (replyId) => {
    setShowDeleteMenu(showDeleteMenu === replyId ? null : replyId);
  };

  const fetchTicketDetail = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get(`/getTicketById/${ticketId}`, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        },
      });
      if (response.status === 200) {
        dispatch(addDetailTicket(response?.data?.ticket));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTicketDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const visibleReplies = showMore
    ? ticket?.replies
    : ticket?.replies.slice(0, 3);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleSubmitReply = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.post(
        `/addReplyToTicket/${ticketId}`,
        { message: reply },
        {
          headers: {
            Authorization: `Bearer ${loggedInUserInfo?.token}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch(addReplyToTicket(response?.data?.newReply));
        showSuccessMessage('Reply added successfully');
        form.resetFields();
        setReply("");
      }
    } catch (error) {
      console.error(error);
      showErrorMessage('Failed to add reply. Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteReply = async (replyId) => {
    try {
      setIsLoading(true);
      const response = await Instance.delete(`/deleteReplyFromTicket/${ticketId}/${replyId}`,
        {
          headers: {
            Authorization: `Bearer ${loggedInUserInfo?.token}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch(deleteReplyFromTicket(replyId));
        showSuccessMessage('Reply deleted successfully');
      }
    } catch (error) {
      showErrorMessage('Something went wrong');
    } finally {
      setIsLoading(false);
      setShowDeleteMenu(null);
    }
  };

  const handleChangeStatus = async (ticketId, status) => {
    try {
      setIsLoading(true);
      const response = await Instance.put(`/changeTicketStatus/${ticketId}`, { status }, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        }
      });
      if (response.status === 200) {
        setShowStatusPopup(false);
        dispatch(changeTicketStatus(status));
        showSuccessMessage('Status changed successfully');
      }
    } catch (error) {
      console.error(error);
      showErrorMessage('Failed to change status. Something went wrong');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className="container">
        <div className="row recruiter-view-tickets-heading">
          <h3>Ticket Detail</h3>
          <div className="mt-4">
            <div className="recruiter-view-ticket-card">
              <div className="d-flex justify-content-between">
                <div>
                  <div className="d-flex gap-2 align-items-center">
                    <img
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                      }}
                      src={
                        ticket?.raisedBy?.profileImage
                          ? `${IMG_URL}/${ticket?.raisedBy?.profileImage}`
                          : DefaultUser
                      }
                      alt="profile"
                    />
                    <h4>{`${ticket?.raisedBy?.firstName} ${ticket?.raisedBy?.lastName}`}</h4>
                    <div className="recruiter-raise-ticket-vertical-line"></div>
                    <h4 style={{ fontWeight: "bold" }}>{ticket?.ticketId}</h4>
                  </div>
                  <p style={{ marginLeft: "2.2rem" }}>
                    {formatDate(ticket?.createdAt)}
                  </p>
                </div>
                <div className="d-flex gap-2">
                  <Form form={form2}>
                    <Form.Item name={"status"}>
                      <Popconfirm
                        title="Are you sure you want to change the status?"
                        onConfirm={() => handleChangeStatus(ticketId, selectedStatus)}
                        onCancel={() => {
                          setShowStatusPopup(false);
                          let st = ticket?.status === "OPEN" ? "Open" : (ticket?.status === "INPROGRESS" ? "In Progress" : "Closed");
                          setSelectedStatus({
                            label: st,
                            value: ticket?.status,
                          })
                        }}
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{
                          className: "popconfirm-yes-button",
                        }}
                        cancelButtonProps={{
                          className: "popconfirm-no-button",
                        }}
                        visible={showStatusPopup}
                      >
                        <Select
                        style={{ width: 120 }}
                          placeholder="Select Status"
                          value={selectedStatus}
                          options={[
                            { label: "Open", value: "OPEN" },
                            { label: "In Progress", value: "INPROGRESS" },
                            { label: "Closed", value: "CLOSED" },
                          ]}
                          onChange={(value) => {
                            setShowStatusPopup(true);
                            setSelectedStatus(value);
                          }}
                        />
                      </Popconfirm>
                    </Form.Item>
                  </Form>
                </div>
              </div>

              <div className="recruiter-raise-ticket-horizontal-line"></div>
              <h4 style={{ fontWeight: "bold" }}>{ticket?.title}</h4>
              <p className="mt-3 mb-1">{ticket?.description}</p>

              <h4 className="my-4" style={{ fontWeight: "bold" }}>
                Replies <FaRegCommentDots />
              </h4>
              {ticket?.replies?.length === 0 && <div>No Replies</div>}
              {visibleReplies?.map((reply) => (
                <div
                  key={reply._id}
                  className="recruiter-reply mb-3"
                  style={{ display: "flex", alignItems: "top" }}
                >
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                    }}
                    src={
                      reply?.user?.profileImage
                        ? `${IMG_URL}/${reply?.user?.profileImage}`
                        : DefaultUser
                    }
                    alt="profile"
                  />
                  <div
                    style={{ marginLeft: "10px" }}
                    className="recruiter-replied-tickets"
                  >
                    <h6>{`${reply?.user?.firstName} ${reply?.user?.lastName}`}</h6>
                    <p>{reply?.message}</p>
                    <span>{formatDate(reply?.createdAt)}</span>
                  </div>
                  <HiMiniEllipsisVertical
                    onClick={() => toggleDeleteMenu(reply._id)}
                    style={{ cursor: "pointer" }}
                  />
                  {showDeleteMenu === reply._id && reply?.user?._id?.toString() === loggedInUserInfo?.userId?.toString() && (
                    <div className="recruiter-delete-menu">
                      <Popconfirm
                        title="Are you sure you want to delete this reply?"
                        onConfirm={() =>
                          handleDeleteReply(reply?._id)
                        }
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{
                          className: "popconfirm-yes-button",
                        }}
                        cancelButtonProps={{
                          className: "popconfirm-no-button",
                        }}
                      >
                        <button className="recruiter-ticket-delete-button">
                          <MdDeleteOutline />
                        </button>
                      </Popconfirm>
                    </div>
                  )}
                </div>
              ))}

              {ticket?.replies?.length > 3 && (
                <button
                  className="recruiter-read-more-button mt-2 mb-4"
                  onClick={toggleShowMore}
                >
                  {showMore ? "- View Less" : "+ View More"}
                </button>
              )}
              {ticket?.status !== "CLOSED" && (
                <>
                  <h4 style={{ fontWeight: "bold" }}>Reply To Ticket</h4>
                  <Form layout="vertical" form={form}>
                    <div className="row">
                      <div className="col-lg-12 my-3">
                        <Form.Item name="reply">
                          <Input.TextArea
                            rows={4}
                            placeholder="Write here..."
                            onChange={(e) => setReply(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <button
                        className="recruiter-create-btn"
                        type="button"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate(-1)}
                      >
                        Back
                      </button>
                      <button
                        className="recruiter-create-btn"
                        type="button"
                        style={{
                          cursor:
                            reply?.trim() === "" ? "not-allowed" : "pointer",
                        }}
                        disabled={reply?.trim() === ""}
                        onClick={handleSubmitReply}
                      >
                        Submit Reply
                      </button>
                    </div>
                  </Form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
