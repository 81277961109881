import React, { useEffect, useState } from 'react';
import { Calendar, Users, FileText, Briefcase, Loader } from 'lucide-react';
import Instance from '../../../Instance';

const DashboardCards = () => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));

    const cardData = [
        { icon: Users, title: 'Total Recruiters', value: data?.totalRecruiters || 0 },
        { icon: FileText, title: 'Total Applicants', value: data?.totalApplicants || 0 },
        { icon: Briefcase, title: 'Total Clients', value: data?.totalClients || 0 },
        { icon: Calendar, title: 'Total No. of jobs', value: data?.totalJobs || 0  },
    ];

    const fetchCardData = async () => {
        try {
            setIsLoading(true);
            const response = await Instance.get('/getdashboardcardMetrics', {
                headers: {
                    Authorization: `Bearer ${loggedInUserInfo?.token}`
                }
            });
            if (response.status === 200) {
                setData(response.data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchCardData();
    }, []);

    return (
        <>
            {isLoading && <Loader />}
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 mb-4">
                {cardData.map(({ icon: Icon, title, value }, index) => (
                    <div key={index} className="col">
                        <div className="admin-dashboard-card">
                            <div className="card-body d-flex align-items-center">
                                <div className="icon-wrapper bg-danger bg-opacity-10 p-3 rounded-circle me-3">
                                    <Icon className="text-danger" size={24} />
                                </div>
                                <div>
                                    <h6 className="card-subtitle mb-1">{title}</h6>
                                    <h2 className="card-title mb-0">{value}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default DashboardCards;
