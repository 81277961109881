import React, { useEffect, useState } from "react";
import { Table, Input, DatePicker, Spin } from "antd";
import Instance from "../../../Instance";
import {
  SearchOutlined,
  FilterOutlined,
  EditOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import "./post.css"
import { useSelector, useDispatch } from "react-redux";
import { addAllPosts, deletePosts } from "../../../Admin/features/postsSlice";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { showSuccessMessage, showErrorMessage, FRONTEND_BASE_URL, formatDate } from "../../../globalConstant";
import Loader from "../../../Loader";


const PostList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const postData = useSelector((state) => state?.posts?.posts);
  const [fileterParameters, setFilterParameters] = useState({});
  const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));

  const handleOpenAddPostModal = () => {
    localStorage.setItem("addpost", true)
    navigate("/seo-user/add-post")
  };

  const fetchPostData = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get('/getAllPost', {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        }
      });
      if (response.status === 200) {
        let posts = response?.data?.posts?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        if (posts) {
          setAllData(posts);
          dispatch(addAllPosts(posts));
        }
      }
    } catch (error) {
      showErrorMessage(error?.response?.data?.message || 'An error occured');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPostData();
  }, []);

  const columns = [
    {
      title: "Title",
      render: (text, item) => (
        <div>
          {item?.title || 'N/A'}
        </div>
      ),
      sorter: (a, b) => a?.title.localeCompare(b?.title),
    },
    {
      title: "SEO Title",
      render: (text, item) => (
        <div>
          {item?.seoTitle || 'N/A'}
        </div>
      ),
      sorter: (a, b) => a?.seoTitle.localeCompare(b?.seoTitle),
    },
    {
      title: "Link",
      render: (text, item) => (

        <a target="_blank" rel="noreferrer" href={`${FRONTEND_BASE_URL}${item?.postUrl}`} style={{ color: 'blue' }}>

          {`${FRONTEND_BASE_URL}${item?.postUrl}`}
        </a>
      ),
      sorter: (a, b) => a?.seoTitle.localeCompare(b?.seoTitle),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (text, item) => {
        return new Date(item?.createdAt).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" });
      },
    },
    {
      title: "Edit",
      dataIndex: "edit",
      render: (_, record) => (
        <EditOutlined
          key={`edit-${record?._id}`}
          className="edit-button-table"
          onClick={() => navigate(`/seo-user/edit-post/${record._id}`)}
        />
      ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      render: (_, record) => (
        <DeleteOutlined
          key={`delete-${record._id}`}
          className="delete-button-table"
          onClick={() => {
            Swal.fire({
              title: "Are you sure",
              text: "You want to Delete?",
              showCancelButton: true,
              confirmButtonColor: "#555",
              cancelButtonColor: "#ce1b28",
              confirmButtonText: "Yes, Delete!"
            }).then((result) => {
              if (result.isConfirmed) {
                handleDelete(record._id)
              }
            })
          }}
        />
      ),
    },
  ];

  const handleDelete = async (id) => {
    try {
      setIsLoading(true);
      const url = `/deletePost/${id}`;
      const response = await Instance.delete(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.status === 200) {
        showSuccessMessage('Post Deleted Successfully!');
        let curData = allData?.filter((item) => item?._id?.toString() !== id?.toString());
        setAllData(curData);
        dispatch(deletePosts(id));
      }
    } catch (error) {
      showErrorMessage(error?.response?.data?.message || 'An error occured');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilter = () => {
    try {
      let filteredData = [...allData];
      if (fileterParameters?.date) {
        filteredData = filteredData?.filter((item) => {
          const itemDate = formatDate(item.createdAt);
          const filterDate = formatDate(fileterParameters?.date);
          return itemDate === filterDate;
        });
      }
      if (fileterParameters?.categories && fileterParameters?.categories.length !== 0) {
        filteredData = filteredData?.filter((item) => {
          let exist = fileterParameters?.categories?.findIndex((d) => d.value === item?.categoryTitle);
          if (exist !== -1) return 1;
          return 0;
        })
      }
      if (fileterParameters?.selectedRows && fileterParameters?.selectedRows.length !== 0) {
        filteredData = filteredData?.filter((item) => {
          let exist = fileterParameters?.selectedRows.findIndex((d) => d?.toString() === item?._id?.toString());
          if (exist !== -1) return 1;
          return 0;
        });
      }
      dispatch(addAllPosts(filteredData));
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (fileterParameters?.searchText === '') return;
    let filteredData = allData?.filter(item => JSON.stringify(item)?.toLowerCase().includes(fileterParameters?.searchText?.toLowerCase()));
    dispatch(addAllPosts(filteredData));
  }, [fileterParameters?.searchText]);

  return (
    <>
      {isLoading && <Loader />}
      <div className="main-title-all">
        <span>Posts</span>
        <button onClick={handleOpenAddPostModal}>Add Post</button>
      </div>
      <div className="post-list-section">
        <div className="filter-row">
          <div className="select-options">
            <DatePicker
              onChange={(e) => {
                let newData = { ...fileterParameters };
                newData['date'] = e;
                setFilterParameters(newData);
              }}
              className="custom-date-picker mx-2"
              format={'MM/DD/YYYY'}
            />
          </div>
          <div className="search-table-container">
            <Input
              placeholder="Search..."
              value={fileterParameters?.searchText}
              onChange={(e) => {
                let newData = { ...fileterParameters };
                newData['searchText'] = e.target.value;
                setFilterParameters(newData);
              }}
              className="search-input-table me-2"
              prefix={<SearchOutlined />}
            />
            <button onClick={handleFilter}>
              <FilterOutlined /> <span>Filter</span>
            </button>
          </div>
        </div>

        <div className="table-list">
          <Table
            columns={columns}
            dataSource={postData}
            rowKey={(data) => data._id}
            rowSelection={{
              type: 'checkbox',
              onChange: (selectedRowKeys, selectedRows) => {
                setFilterParameters(prevData => ({
                  ...prevData,
                  ['selectedRows']: selectedRowKeys
                }));
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default PostList;
