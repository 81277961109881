import React, { useState, useMemo, useRef, useEffect } from "react";
import { Input, Pagination, Form, Modal, Select, Button, DatePicker, Slider, Dropdown, Menu } from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import { TbArrowsSort } from "react-icons/tb";
import { SlLocationPin } from "react-icons/sl";
import Instance from "../../../Instance";
import { getRelativeTime } from "../Jobs/constant";
import Loader from "../../../Loader";
import { JobCategoryOptions, JobTypeOptions, ShiftTypeOptions, ExperienceLevelOptions } from "../Jobs/constant";
import { IoEllipsisVertical, IoEye } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export const TotalJobsList = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [jobsData, setJobsData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [recruitersList, setRecruitersList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filterParameters, setFilterParameters] = useState({});
  const [isAscendingSort, setIsAcendingSort] = useState(true);
  const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));

  const pageSize = 9;
  const [currentPage, setCurrentPage] = useState(1);
  const startEntry = useMemo(
    () => (currentPage - 1) * pageSize + 1,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, dataSource]
  );
  const endEntry = useMemo(
    () => Math.min(currentPage * pageSize, dataSource.length),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, dataSource]
  );

  const jobCategoryRef = useRef(null);
  const jobTypeRef = useRef(null);
  const payRateRef = useRef(null);
  const shiftTypeRef = useRef(null);
  const experienceLevelRef = useRef(null);
  const jobPostedDateRef = useRef(null);
  const recruiterRef = useRef(null);

  const scrollToField = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const paginatedJobs = useMemo(() => {
    if (searchText?.trim() === "") {
      return dataSource?.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
      );
    }
    const filteredJobs = dataSource?.filter((job) => {
      return `${job?.title}${job?.jobCategory}${job?.shiftType}`
        ?.toLowerCase()
        .includes(searchText?.toLowerCase());
    });
    return filteredJobs.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );
  }, [searchText, dataSource, currentPage]);

  const fetchJobDetails = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get("/getAllJobs", {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        },
      });
      if (response?.status === 200) {
        setJobsData(response?.data?.jobs);
        setDataSource(response?.data?.jobs);
      }
    } catch (error) {
      console.error("Error fetching job details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRecruiterData = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get("/getAllRecruiters", {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        },
      });
      if (response?.status === 200) {
        setRecruitersList(response?.data?.recruiters);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchJobDetails();
    fetchRecruiterData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSorting = () => {
    try {
      let sortedData;
      if (isAscendingSort) {
        sortedData = [...dataSource]?.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
      } else {
        sortedData = [...dataSource]?.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
      }
      setDataSource(sortedData);
      setIsAcendingSort(!isAscendingSort);
    } catch (error) {
      console.error(error);
    }
  };

  const handleApplyFilters = () => {
    try {
      setIsLoading(true);
      let filteredJobs = jobsData;
      if (
        filterParameters?.jobCategory &&
        filterParameters?.jobCategory?.length > 0
      ) {
        filteredJobs = filteredJobs?.filter((job) =>
          filterParameters?.jobCategory?.includes(job?.jobCategory)
        );
      }
      if (filterParameters?.jobType && filterParameters?.jobType?.length > 0) {
        filteredJobs = filteredJobs?.filter((job) =>
          filterParameters?.jobType?.includes(job?.jobType)
        );
      }
      if (filterParameters?.payRate) {
        filteredJobs = filteredJobs?.filter(
          (job) => job?.weeklyEarnings <= filterParameters?.payRate
        );
      }
      if (
        filterParameters?.shiftType &&
        filterParameters?.shiftType?.length > 0
      ) {
        filteredJobs = filteredJobs?.filter((job) =>
          filterParameters?.shiftType?.includes(job?.shiftType)
        );
      }
      if (filterParameters?.experienceLevel) {
        switch (filterParameters?.experienceLevel) {
          case "Entry Level":
            filteredJobs?.filter(
              (job) => job?.experience >= 0 && job?.experience <= 2
            );
            break;
          case "Mid-Level":
            filteredJobs?.filter(
              (job) => job?.experience >= 3 && job?.experience <= 5
            );
            break;
          case "Senior Level":
            filteredJobs?.filter(
              (job) => job?.experience >= 6 && job?.experience <= 10
            );
            break;
          default: break;
        }
        filteredJobs = filteredJobs?.filter((job) =>
          filterParameters?.experienceLevel?.includes(job?.experienceLevel)
        );
      }
      if (filterParameters?.jobPostedDate) {
        filteredJobs = filteredJobs?.filter(
          (job) =>
            new Date(job?.jobPostedDate) <=
            new Date(filterParameters?.jobPostedDate)
        );
      }
      if (filterParameters?.recruiter) {
        filteredJobs = filteredJobs?.filter(
          (job) => job?.assignedRecruiter === filterParameters?.recruiter
        );
      }
      setDataSource(filteredJobs);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsModalVisible(false);
    }
  };

  const handleModalClose = () => {
    form.resetFields();
    setFilterParameters({});
    setIsModalVisible(false);
    setDataSource(jobsData);
  };

  const recruiterOptions = recruitersList?.map((recruiter) => ({
    label: `${recruiter?.firstName} ${recruiter?.lastName}`,
    value: recruiter?._id,
  }));

  return (
    <>
      {isLoading && <Loader />}
      <div className="recruiter-lead-ongoing-jobs-section container">
        <div>
          <div className="row">
            <div className="d-flex justify-content-between align-items-center align-content-center">
              <div className="d-flex gap-4 align-items-center">
                <h2 className="">Total Jobs</h2>
                <Input
                  placeholder="Search..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  className="recruiter-lead-search-input-table me-2"
                  prefix={<SearchOutlined />}
                />
              </div>
              <div className="recruiter-lead-search-table-container gap-2">
                <button onClick={handleSorting}>
                  <span>Sort</span>{" "}
                  <TbArrowsSort
                    style={{ fontWeight: "bold", fontSize: "16px" }}
                  />
                </button>
                <button onClick={() => setIsModalVisible(true)}>
                  <FilterOutlined
                    style={{ fontWeight: "bold", fontSize: "16px" }}
                  />{" "}
                  <span>Filter</span>
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            {paginatedJobs?.map((job) => (
              <div className="col-lg-4 my-2" key={job._id}>
                <div className="recruiter-lead-ongoing-job-card">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <p style={{ marginBottom: "0" }}>
                      {job?.vacancies} Vacancies
                    </p>
                    <div className="d-flex gap-2">
                      <span className="ongoing-job-button">
                        {getRelativeTime(job?.jobPostedDate)}
                      </span>
                      <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item
                              key="edit"
                              className="horizontal-action-dropdown"
                              onClick={() =>
                                navigate(`/recruiter-lead/edit-job/${job?._id}`)
                              }
                            >
                              <MdEdit /> Edit
                            </Menu.Item>
                            <Menu.Item
                              key="view"
                              className="horizontal-action-dropdown"
                              onClick={() =>
                                navigate(
                                  `/recruiter-lead/view-job-details/${job?._id}`
                                )
                              }
                            >
                              <IoEye /> View
                            </Menu.Item>
                          </Menu>
                        }
                        trigger={["click"]}
                      >
                        <IoEllipsisVertical
                          size={20}
                          style={{ cursor: "pointer" }}
                        />
                      </Dropdown>
                    </div>
                  </div>
                  <div className="d-flex gap-2">
                    <h3>${job.weeklyEarnings}</h3>
                    <div className="recruiter-lead-ongoing-vertical-line"></div>
                    <h5>
                      Per <br /> Week
                    </h5>
                  </div>
                  <h5 className="mt-3">{job?.title}</h5>
                  <p>{`${job?.shiftType} Shfit, ${job?.shiftHours}`}</p>
                  <div className="recruiters-text-underline my-2"></div>
                  <h4>
                    <SlLocationPin className="icon-red" /> &nbsp;
                    {job?.cities?.join(", ")}
                    {job?.cities?.length > 0 ? ", " : ""}
                    {`${job?.stateCode}`}
                  </h4>
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-between mt-4 pagination-div">
            <h5>
              Showing {startEntry} to {endEntry} of {dataSource?.length} entries
            </h5>
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={dataSource?.length}
              onChange={(page) => setCurrentPage(page)}
              showSizeChanger={false}
            />
          </div>
        </div>
      </div>
      <Modal
        title="Filter"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        className="filter-modal"
        footer={null}
      >
        <div className="filter-tabs">
          <Button onClick={() => scrollToField(jobCategoryRef)}>
            Job Category
          </Button>
          <Button onClick={() => scrollToField(jobTypeRef)}>Job Type</Button>
          <Button onClick={() => scrollToField(payRateRef)}>Pay Rate</Button>
          <Button onClick={() => scrollToField(shiftTypeRef)}>
            Shift Type
          </Button>
          <Button onClick={() => scrollToField(experienceLevelRef)}>
            Experience Level
          </Button>
          <Button onClick={() => scrollToField(jobPostedDateRef)}>
            Job Posted Date
          </Button>
          <Button onClick={() => scrollToField(recruiterRef)}>Recruiter</Button>
        </div>
        <div className="filter-form mt-4">
          <Form layout="vertical " className="filter-form" form={form}>
            <div className="col-md-12" ref={jobCategoryRef}>
              <Form.Item label="Job Category" name={"jobCategory"}>
                <Select
                  placeholder="Select Job Category"
                  options={JobCategoryOptions}
                  onChange={(e) =>
                    setFilterParameters({ ...filterParameters, jobCategory: e })
                  }
                  mode="multiple"
                />
              </Form.Item>
            </div>
            <div className="col-md-12" ref={jobTypeRef}>
              <Form.Item label="Job Type" name={"jobType"}>
                <Select
                  placeholder="Select Job Type"
                  options={JobTypeOptions}
                  onChange={(e) =>
                    setFilterParameters({ ...filterParameters, jobType: e })
                  }
                  mode="multiple"
                />
              </Form.Item>
            </div>
            <div className="col-md-12" ref={payRateRef}>
              <Form.Item label="Pay Rate" name={"payRate"}>
                <Slider
                  name="payRate"
                  min={0}
                  max={5000}
                  style={{ margin: "0 10px" }}
                  className="filter-slider"
                  onChange={(e) =>
                    setFilterParameters({ ...filterParameters, payRate: e })
                  }
                />
              </Form.Item>
            </div>
            <div className="col-md-12" ref={shiftTypeRef}>
              <Form.Item label="Shift Type" name={"shiftType"}>
                <Select
                  placeholder="Select Shift Type"
                  options={ShiftTypeOptions}
                  mode="multiple"
                  onChange={(e) =>
                    setFilterParameters({ ...filterParameters, shiftType: e })
                  }
                />
              </Form.Item>
            </div>
            <div className="col-md-12" ref={experienceLevelRef}>
              <Form.Item label="Experience Level" name={"experienceLevel"}>
                <Select
                  placeholder="Select Experience Level"
                  name="experienceLevel"
                  options={ExperienceLevelOptions}
                  onChange={(e) =>
                    setFilterParameters({
                      ...filterParameters,
                      experienceLevel: e,
                    })
                  }
                />
              </Form.Item>
            </div>
            <div className="col-md-12" ref={jobPostedDateRef}>
              <Form.Item label="Job Posted Date" name={"jobPostedDate"}>
                <DatePicker
                  placeholder="Select Date"
                  format="DD/MM/YYYY"
                  className="form-control"
                  onChange={(e) =>
                    setFilterParameters({
                      ...filterParameters,
                      jobPostedDate: e,
                    })
                  }
                />
              </Form.Item>
            </div>
            <div className="col-md-12" ref={recruiterRef}>
              <Form.Item label="Recruiter" name={"recruiter"}>
                <Select
                  placeholder="Select Recruiter"
                  options={recruiterOptions}
                  onChange={(e) =>
                    setFilterParameters({ ...filterParameters, recruiter: e })
                  }
                />
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className="modal-actions d-flex justify-content-between">
          <button
            onClick={handleModalClose}
            className="cancel-btn"
            type="button"
          >
            Clear All
          </button>
          <button
            className="create-btn"
            onClick={handleApplyFilters}
            type="button"
          >
            Apply Filters
          </button>
        </div>
      </Modal>
    </>
  );
};
