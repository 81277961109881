import React, { useState } from "react";
import { Dropdown, Menu, Table, Button, Tag, Popconfirm } from "antd";
import { formatDate, showErrorMessage, showSuccessMessage } from "../../../globalConstant";
import { changeStatus } from "../../features/applicationSlice";
import { useDispatch } from "react-redux";
import { IoEllipsisHorizontalSharp } from "react-icons/io5";
import Instance from "../../../Instance";
import Loader from "../../../Loader";
import { useNavigate } from "react-router-dom";
const { SubMenu } = Menu;

const statusMenu = {
  Applied: ['Review', 'Closed'],
  Review: ['Applied', 'Submitted', 'Closed'],
  Submitted: ['Review', 'Approved', 'Closed'],
  Approved: ['Submitted', 'Schedule', 'Closed'],
  Schedule: ['Approved', 'Offer', 'Closed'],
  Offer: ['Schedule', 'Onboard', 'Closed'],
  Onboard: ['Offer', 'Hired', 'Closed'],
  Hired: ['Onboard', 'Closed'],
  Closed: ['Hired']
};

export const ApplicantsList = ({ data, status }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [applicationId, setApplicationId] = useState(null);
  const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));

  const changeStatusFun = async (status) => {
    try {
      setIsLoading(true);
      const response = await Instance.put(`/editApplicationStatus/${applicationId}`, { status: status }, {
        headers: {
          Authorization: `Bearer ${loggedInUserInfo?.token}`,
        },
      });
      if (response.status === 200) {
        dispatch(changeStatus({ _id: applicationId, status }));
        showSuccessMessage('Status changed successfully');
      }
    } catch (error) {
      console.error(error);
      showErrorMessage('Failed to change status');
    } finally {
      setIsLoading(false);
      setApplicationId(null);
    }
  };

  const dataSource = data?.filter((item) => item?.status === status);

  const actionMenu = (
    <Menu>
      <Menu.Item
        key="view"
        onClick={() => navigate(`/admin/view-applicants/${applicationId}`)}
      >
        View Details
      </Menu.Item>
      <SubMenu key="changeStatus" title={<span>Change Status</span>}>
        {statusMenu[status]?.map((stats) => (
          <Menu.Item key={stats}>
            <Popconfirm
              title={`Are you sure you want to change the status to "${stats}"?`}
              onConfirm={() => changeStatusFun(stats)}
              okText="Yes"
              cancelText="No"
            >
              <span style={{ cursor: "pointer" }}>{stats}</span>
            </Popconfirm>
          </Menu.Item>
        ))}
      </SubMenu>
    </Menu>
  );

  const columns = [
    {
      title: "Applicant Name",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          {record?.basicInfo?.firstName} {record?.basicInfo?.lastName}
        </div>
      ),
    },
    {
      title: "Email",
      render: (text, record) => (
        <div>{record?.basicInfo?.email}</div>
      )
    },
    {
      title: "Mobile Number",
      render: (text, record) => (
        <div>{record?.basicInfo?.mobileNumber}</div>
      )
    },
    {
      title: "Applied Date",
      render: (text, record) => (
        <div>{formatDate(record?.createdAt)}</div>
      )
    },
    {
      title: "Job Id",
      render: (text, record) => (
        <div>{record?.manualJobId || 'N/A'}</div>
      )
    },
    {
      title: "Status",
      dataIndex: "status",
      render: () => (
        <Tag className="applicant-status-tag">
          {status}
        </Tag>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <Dropdown overlay={actionMenu} trigger={['click']} onClick={() => setApplicationId(record?._id)}>
          <Button type="text" icon={<IoEllipsisHorizontalSharp />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      {isLoading && <Loader />}
      <div className="all-recruiter-section">
        <div >
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            className="applied-applicants-table"
            rowKey={(row) => row._id}
          />
        </div>
      </div>
    </>
  );
};
