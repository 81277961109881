import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDate, IMG_URL } from '../../../globalConstant';
import DefaultUser from '../../Assets/Image/Recriuiter/DefaultUser.png';
import noTicket  from "../../Assets/Image/Recriuiter/no-ticket.png"


export const ClosedTickets = ({ ticketsData }) => {
    const navigate = useNavigate();
    const data = ticketsData?.filter(ticket => ticket.status === "CLOSED");

    const renderTickets = () => {
        if (data?.length === 0) {
            return (
                <div className='d-flex flex-column justify-content-center align-items-center no-ticket-found'>
                    <img src={noTicket}  alt=""/>
                    <h4>No Tickets Found</h4>
                </div>
            )
        }
        return data?.map(ticket => (
            <div className='col-lg-4 mb-4' key={ticket?._id}>
                <div className='all-tickets-card'>
                    <div className='d-flex gap-2 align-items-center'>
                    <img style={{ width: '30px', height: '30px', borderRadius: '50%' }} src={ticket?.raisedBy?.profileImage ? `${IMG_URL}/${ticket?.raisedBy?.profileImage}` : DefaultUser} alt="profile" />
                        <h4>{`${ticket?.raisedBy?.firstName} ${ticket?.raisedBy?.lastName}`}</h4>
                        <div className='raise-ticket-vertical-line'></div>
                        <h4 style={{ fontWeight: 'bold' }}>{ticket?.ticketId}</h4>
                    </div>
                    <p style={{ textAlign: 'end' }}>Posted At {formatDate(ticket?.createdAt)}</p>
                    <div className='raise-ticket-horizontal-line'></div>
                    <h4>{ticket?.title}</h4>
                    <p className='mt-2'>{ticket?.description}</p>
                    <div className='d-flex justify-content-end'>
                        <button className='view-ticket-btn' onClick={() => navigate(`/admin/view-ticket/${ticket?._id}`)}>View Ticket</button>
                    </div>
                </div>
            </div>
        ));
    };

    return (
        <div className='container mt-4'>
            <div className='all-tickets-section'>
                <div className='row'>
                    {renderTickets()}
                </div>
            </div>
        </div>
    );
}
