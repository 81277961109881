import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select } from "antd";
import Instance from "../../../Instance";
import { useDispatch } from "react-redux";
import { updateTeam } from "../../features/teamSlice";
import Loader from "../../../Loader";
import { showErrorMessage, showSuccessMessage } from "../../../globalConstant";

export const EditTeam = ({ isModalVisible, onEditModalClose, team }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [members, setMembers] = useState([]);
    const [formData, setFormData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));
    const fetchMembers = async () => {
        try {
            setIsLoading(true);
            const response = await Instance.get(`/getAllMembers`, {
                headers: {
                    Authorization: `Bearer ${loggedInUserInfo?.token}`
                }
            });
            if (response.status === 200) {
                setMembers(response?.data?.members);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchMembers();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!team) return;
        setFormData({
            teamName: team?.teamName,
            description: team?.description,
            departmentDivision: team?.departmentDivision,
            teamLead: JSON.stringify(team.teamLead),
            teamLeadRole: team.teamLeadRole,
        });
        form.setFieldsValue({
            teamName: team.teamName,
            description: team.description,
            departmentDivision: team.departmentDivision,
            teamLead: {
                value: team.teamLead?._id,
                label: `${team?.teamLead?.firstName} ${team?.teamLead?.lastName}`
            }
        })
    }, [team]);


    const handleFormSubmit = async () => {
        try {
            setIsLoading(true);
            let teamLead = JSON.parse(formData?.teamLead);
            formData.teamLead = teamLead?._id;
            formData.teamLeadRole = teamLead.role?teamLead?.role==='ADMIN'?'Admin':'Recruiter':formData?.teamLeadRole;
            const response = await Instance.put(`/editTeamById/${team?._id}`,formData,{
                headers: {
                    Authorization: `Bearer ${loggedInUserInfo?.token}`
                }
            });
            if(response.status===200){
                dispatch(updateTeam(response.data.updatedTeam));
                showSuccessMessage('Team updated successfully');
            }
        } catch (error) {
            console.error(error);
            showErrorMessage('Failed to update team detail');
        } finally {
            setIsLoading(false);
            onEditModalClose();
        }
    };

    const memberOptions = members?.map((item) => ({
        label: `${item?.firstName} ${item?.lastName}`,
        value: JSON.stringify(item)
    }));

    return (
        <>
            {isLoading && <Loader />}
            <Modal
                title="Edit Team"
                open={isModalVisible}
                onCancel={onEditModalClose}
                footer={null}
            >
                <Form layout="vertical" form={form} className="mt-4" onFinish={handleFormSubmit}>
                    <div className="row">
                        <div className="col-lg-6">
                            <Form.Item name="teamName" label="Team Name" rules={[{ required: true, message: "Please enter team name" }]}>
                                <Input placeholder="Enter Team name" onChange={(e) => setFormData({ ...formData, teamName: e.target.value })} />
                            </Form.Item>
                        </div>
                        <div className="col-lg-6">
                            <Form.Item name="departmentDivision" label="Department/Division" rules={[{ required: true, message: "Please enter department" }]}>
                                <Input placeholder="Enter Department" onChange={(e) => setFormData({ ...formData, departmentDivision: e.target.value })} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <Form.Item name="teamLead" label="Team Lead" rules={[{ required: true, message: "Please select team lead" }]}>
                                <Select placeholder="Select Team Lead" options={memberOptions} onChange={(e) => setFormData({ ...formData, teamLead: e })} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-12 ">
                        <Form.Item label="Description" name="description">
                            <Input.TextArea rows={4} placeholder="Write here..." onChange={(e) => setFormData({ ...formData, description: e.target.value })} />
                        </Form.Item>
                    </div>
                    <div className="form-actions" style={{ display: "flex", justifyContent: "flex-end" }} >
                        <button type="button" className="cancel-btn" style={{ marginRight: "20px" }} onClick={onEditModalClose}>
                            Cancel
                        </button>
                        <button className="create-btn" type="submit">
                            Save
                        </button>
                    </div>
                </Form>
            </Modal>
        </>
    );
};
