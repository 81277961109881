import React, { useState, useEffect } from "react";
import "../../Pages/Recruiter/recruiter.css";
import { Form, Input, Select, Upload } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { IoCloudUploadOutline } from "react-icons/io5";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { JobCategoryOptions, ShiftTypeOptions } from "./constant";
import { useNavigate, useParams } from "react-router-dom";
import { createUniqueS3FilenameWithDate, handleFileUpload, showErrorMessage, showSuccessMessage } from "../../../globalConstant";
import Instance from "../../../Instance";
import Loader from "../../../Loader";
import { GetState, GetCity } from "react-country-state-city";

const modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
    ],
};

const normFile = (e) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e?.fileList;
};

const EditClient = () => {
    const { id } = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({});
    const [hospitalImage, setHospitalImage] = useState(null);
    const [certificates, setCertificates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [stateOptions, setStateOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [clientDetail, setClientDetail] = useState(null);
    const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));

    const fetchClientDetail = async () => {
        try {
            setIsLoading(true);
            const response = await Instance.get(`/getClientByMongoId/${id}`, {
                headers: {
                    Authorization: `Bearer ${loggedInUserInfo?.token}`,
                },
            });
            if (response.status === 200) {
                let data = JSON.parse(JSON.stringify(response?.data?.client));
                setFormData(data);
                setClientDetail(response?.data?.client);
            }
        } catch (error) {
            console.error(error);
            showErrorMessage("Failed to fetch client detail. Please try again later.");
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchClientDetail();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!clientDetail) return;
        form.setFieldsValue({
            ...clientDetail,
        });
    }, [clientDetail]);

const handleFieldChange = (key, e) => {
    if (key === "state") {
        setFormData({ ...formData, [key]: e, city: null });
        form.setFieldsValue({ city: null });
    } else if (key === "preferredJobCategories" || key === "preferredShifts" || key === 'benefits' || key === "city" || key === "description") {
        setFormData({ ...formData, [key]: e });
    } else setFormData({ ...formData, [key]: e.target.value });
};

useEffect(() => {
    const fetchStates = async () => {
        const states = await GetState(233);
        const options = states?.map((item) => ({
            label: item?.name,
            value: item?.id,
        }));
        setStateOptions(options);
    };
    fetchStates();
}, []); // eslint-disable-line react-hooks/exhaustive-deps

useEffect(() => {
    const fetchCities = async () => {
        if (!formData?.state) {
            setCityOptions([]);
            return;
        }
        const cities = await GetCity(233, formData?.state);
        const options = cities?.map((item) => ({
            label: item?.name,
            value: item?.name,
        }));
        setCityOptions(options);
    };
    fetchCities();
}, [formData?.state]);

const isFormValid = () => {
    try {
        console.log(formData);
        if (!formData?.hospitalName || formData?.hospitalName?.trim() === '') {
            showErrorMessage("Hospital Name is required!");
            return false;
        }
        if (!formData?.mobileNumber || formData?.mobileNumber?.trim() === '') {
            showErrorMessage("Mobile Number is required!");
            return false;
        }
        if (!formData?.hospitalEmail || formData?.hospitalEmail?.trim() === '') {
            showErrorMessage("Hospital Email is required!");
            return false;
        }
        if (!formData?.hospitalEstablishedYear) {
            showErrorMessage("Hospital Established Year is required!");
            return false;
        }
        if (!formData?.contactPersonName || formData?.contactPersonName?.trim() === '') {
            showErrorMessage("Contact Person Name is required!");
            return false;
        }
        if (!formData?.contactPersonEmail || formData?.contactPersonEmail?.trim() === '') {
            showErrorMessage("Contact Person Email is required!");
            return false;
        }
        if (!formData?.preferredJobCategories || formData?.preferredJobCategories?.length === 0) {
            showErrorMessage("Preferred Job Categories is required!");
            return false;
        }
        if (!formData?.preferredShifts || formData?.preferredShifts?.length === 0) {
            showErrorMessage("Preferred Shifts is required!");
            return false;
        }
        if (!formData?.taxPercentage) {
            showErrorMessage("Tax Percentage is required!");
            return false;
        }
        if (!formData?.state) {
            showErrorMessage("State is required!");
            return false;
        }
        if (!formData?.city) {
            showErrorMessage("City is required!");
            return false;
        }
        if (!formData?.zipCode || formData?.zipCode?.trim() === '') {
            showErrorMessage("Zip Code is required!");
            return false;
        }
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}

const HandleFormSubmit = async () => {
    try {
        if (!isFormValid()) return;
        setIsLoading(true);

        let s = await GetState(233);
        s = s?.find((item) => item?.id === formData?.state);
        if (s) formData.state = s?.name;

        if (hospitalImage) {
            try {
                const fileName = createUniqueS3FilenameWithDate(hospitalImage?.name);
                const file = await handleFileUpload(hospitalImage?.originFileObj, fileName, hospitalImage?.type);
                formData.hospitalImage = fileName;
            } catch (error) {
                showErrorMessage("Failed to upload image. Please try again!");
                return;
            }
        }

        if (certificates.length > 0) {
            try {
                for (let i = 0; i < certificates.length; i++) {
                    const fileName = createUniqueS3FilenameWithDate(certificates[i]?.name);
                    const file = await handleFileUpload(certificates[i]?.originFileObj, fileName, certificates[i]?.type);
                    certificates[i] = fileName
                }
                formData.certificatesList = certificates;
            } catch (error) {
                showErrorMessage("Failed to upload certificates. Please try again!");
                return;
            }
        }

        const response = await Instance.put(`/editClientById/${id}`, formData, {
            headers: {
                Authorization: `Bearer ${loggedInUserInfo?.token}`,
            }
        });
        if (response.status === 200) {
            showSuccessMessage("Client Updated Successfully!");
            navigate('/admin/total-clients');
        }
    } catch (error) {
        console.error(error);
        showErrorMessage("Something went wrong, Please try again!");
    } finally {
        setIsLoading(false);
    }
}

const beforeUpload = (file) => {
    const isValidType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
    if (!isValidType) {
        showErrorMessage('You can only upload JPG/PNG files!');
    }
    const isSingleFile = file.size / 1024 / 1024 < 2; // Optional size validation
    if (!isSingleFile) {
        showErrorMessage('Image must smaller than 2MB!');
    }
    return isValidType && isSingleFile;
};

const customRequest = ({ onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};

return (
    <>
        {isLoading && <Loader />}
        <div className="add-client-section">
            <div>
                <h2 className="mb-4">Edit Client Detail</h2>
                <Form layout="vertical" onFinish={HandleFormSubmit} form={form}>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <Form.Item label="Hospital Name" rules={[{ required: true, message: '' }]} name="hospitalName">
                                <Input placeholder="Enter Hospital name" onChange={(e) => handleFieldChange("hospitalName", e)} value={formData?.hospitalName || ""} />
                            </Form.Item>
                        </div>
                        <div className="col-md-6 mb-3">
                            <Form.Item label="Client ID" name="clientId">
                                <Input placeholder="Auto generated ID" disabled="disabled" />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="inputPhone" className="form-label" style={{
                                display: "flex",
                                marginBottom: "0",
                            }}>
                                <span style={{ color: "#ff4d4f", marginRight: "4px", marginBottom: "8px" }}>*</span> Mobile Number
                            </label>
                            <PhoneInput
                                defaultCountry="us"
                                value={formData?.mobileNumber || ""}
                                onChange={(phone) => {
                                    setFormData((values) => ({
                                        ...values,
                                        ["mobileNumber"]: phone,
                                    }));
                                }}
                                inputStyle={{
                                    width: "100%",
                                }}
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <Form.Item label="Hospital Email ID" rules={[{ required: true, message: '' }]} name="hospitalEmail">
                                <Input placeholder="Enter Hospital Email" onChange={(e) => handleFieldChange("hospitalEmail", e)} value={formData?.hospitalEmail || ""} />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <Form.Item label="Hospital Established Year" rules={[{ required: true, message: '' }]} name="hospitalEstablishedYear">
                                <Input type="number" placeholder="Enter Established Year" onChange={(e) => handleFieldChange("hospitalEstablishedYear", e)} value={formData?.hospitalEstablishedYear || ""} />
                            </Form.Item>
                        </div>
                        <div className="col-md-6 mb-3">
                            <Form.Item label="Website" name="website">
                                <Input placeholder="Enter Website URL" onChange={(e) => handleFieldChange("website", e)} value={formData?.website || ""} />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <Form.Item label="Contact Person Name" rules={[{ required: true, message: '' }]} name="contactPersonName">
                                <Input placeholder="Enter Contact Person Name" onChange={(e) => handleFieldChange("contactPersonName", e)} value={formData?.contactPersonName || ""} />
                            </Form.Item>
                        </div>
                        <div className="col-md-6 mb-3">
                            <Form.Item label="Contact Person Email" rules={[{ required: true, message: '' }]} name="contactPersonEmail">
                                <Input placeholder="Enter Contact Person Email" onChange={(e) => handleFieldChange("contactPersonEmail", e)} value={formData?.contactPersonEmail || ""} />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <Form.Item label="Preferred Job Categories" rules={[{ required: true, message: '' }]} name="preferredJobCategories">
                                <Select placeholder="Select Job Categories" mode="multiple" options={JobCategoryOptions} onChange={(e) => handleFieldChange("preferredJobCategories", e)} />
                            </Form.Item>
                        </div>
                        <div className="col-md-6 mb-3">
                            <Form.Item label="Preferred Shifts" rules={[{ required: true, message: '' }]} name="preferredShifts">
                                <Select placeholder="Select Preffered Shifts" options={ShiftTypeOptions} mode="multiple" onChange={(e) => handleFieldChange("preferredShifts", e)} />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <Form.Item label="Tax percentage" rules={[{ required: true, message: '' }]} name="taxPercentage">
                                <Input placeholder="Add Tax percentage %" onChange={(e) => handleFieldChange("taxPercentage", e)} type="number" />
                            </Form.Item>
                        </div>
                        <div className="col-md-6 mb-3">
                            <Form.Item label="Benefits">
                                <Select placeholder="Select Benefits" mode="multiple" onChange={(e) => handleFieldChange("benefits", e)} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <Form.Item label="State" rules={[{ required: true, message: '' }]} name="state">
                                <Select placeholder="Select State" options={stateOptions} onChange={(e) => handleFieldChange("state", e)} />
                            </Form.Item>
                        </div>
                        <div className="col-md-6 mb-3">
                            <Form.Item label="City" rules={[{ required: true, message: '' }]} name="city">
                                <Select placeholder="Select City" options={cityOptions} disabled={formData?.state ? false : true} onChange={(e) => handleFieldChange("city", e)} />
                            </Form.Item>
                        </div>
                        <div className="col-md-6 mb-3">
                            <Form.Item label="Zip Code" rules={[{ required: true, message: '' }]} name="zipCode">
                                <Input placeholder="Enter Zip Code"
                                    onChange={(e) => {
                                        const numericValue = e.target.value.replace(/[^0-9]/g, '');
                                        handleFieldChange('zipCode', { target: { value: numericValue } });
                                    }}
                                    maxLength={5}
                                    onKeyPress={(e) => {
                                        if (!/^[0-9]*$/.test(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}
                                    value={formData?.zipCode || ""} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <Form.Item label="Upload Hospital Image">
                                <Upload.Dragger
                                    name="hospitalImage"
                                    accept=".png,.jpg,.jpeg"
                                    beforeUpload={beforeUpload}
                                    fileList={hospitalImage ? [hospitalImage] : []}
                                    onChange={({ fileList }) => {
                                        if (fileList.length > 1) {
                                            showErrorMessage("You cannot add multiple images");
                                            return;
                                        }
                                        setHospitalImage(fileList[0]);
                                    }}
                                    className="client-upload-dragger"
                                    action="#"
                                    customRequest={customRequest}
                                >
                                    <button className="client-upload-drag-icon" type="button">
                                        <IoCloudUploadOutline />
                                    </button>
                                    <p className="ant-upload-text">Drop Image Here</p>
                                    <p className="ant-upload-hint">JPG/JPEG/PNG format</p>
                                </Upload.Dragger>
                            </Form.Item>
                        </div>
                        <div className="col-md-6 mb-3">
                            <Form.Item label="Add NDA & Other Certificate">
                                <Form.Item
                                    name="dragger"
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                    noStyle
                                >
                                    <Upload.Dragger
                                        name="certificates"
                                        action="#"
                                        className="client-upload-dragger"
                                        customRequest={customRequest}
                                        fileList={certificates}
                                        onChange={({ fileList }) => {
                                            setCertificates(fileList);
                                        }}
                                    >
                                        <button className="client-upload-drag-icon" type="text">
                                            <IoCloudUploadOutline />
                                        </button>
                                        <p className="ant-upload-text">
                                            Drop Certificate here
                                        </p>
                                        <p className="ant-upload-hint">
                                            PDF/DOC/Text format
                                        </p>
                                    </Upload.Dragger>
                                </Form.Item>
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <Form.Item label="Client Description">
                                <ReactQuill theme="snow" modules={modules} onChange={(e) => handleFieldChange("description", e)} value={formData?.description || ""} />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-12 text-end justify-content-between d-flex">
                            <button className="cancel-btn" type="button" onClick={() => navigate(-1)}>Cancel</button>
                            <button className="create-btn" type="submit">
                                Update Client
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </>
);
};

export default EditClient;
